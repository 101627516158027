import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import { FCUServiceConfig } from '../shared/types/fcu-service-config';
import { FetchCreateUpdateService } from '../shared/fetch-create-update.service';
import { LocaleService } from "../locale.service";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { L } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-tab-forms',
  templateUrl: './tab-forms.component.html',
  styleUrls: ['./tab-forms.component.css'],
})
export class TabFormsComponent implements OnInit {
  selectedProject: any | undefined;
  selectedProjectJSON: any | undefined;
  selectedProjectReleased: any | undefined;
  selectedYear: any | undefined;
  private fcuConfig: FCUServiceConfig = {
    dyName: 'project',
    endpoint: 'project',
  };

  constructor(private http: HttpClient,
              private dataService: DataService,
              private localeService: LocaleService) {
    this.dataService.selectedProject.subscribe((project) => {
      this.selectedProject = project;
    });

    this.dataService.selectedYear.subscribe((year) => {
      this.selectedYear = year; // J: this is the relevant data-year object, not just the date
      console.log("selectedYear", this.selectedYear)
      this.selectedProjectJSON = this.selectedYear && this.selectedYear.released;
    });

  };

  ngOnInit(): void {
    this.localeService.updateLocale()
  }

  updateData(data: { [key: string]: any }) {
    delete data.operating_company;
    const url = this.buildIdUrl("data-years", data.id);    
    console.log(url);
    console.log(data);

    return this.http
      .put(url, {id: data.id, released: data.released}) // J: needs to put in data https://docs.strapi.io/dev-docs/api/rest#update-an-entry
      .subscribe(data => {
        console.log("data", data);
        this.selectedYear = data;
        this.selectedProjectJSON = this.selectedYear && this.selectedYear.released;
      });
  }

  private buildIdUrl(endpoint: string, id: number) {
    return `${environment.api}/${endpoint}/${id}`;
  }

  setReleased(newState: boolean) {
    this.selectedYear["released"] = newState;
    this.updateData(this.selectedYear);
  }
}
