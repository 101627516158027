<app-metadata [graphName]="'reg-health-workers'"></app-metadata>
<button mat-raised-button color="primary" (click)="addNewHealthData()"
        i18n="add new district@@add_district">
  Add new district or national level data
</button>
<app-form
  *ngFor="let district of districts"
  [helptexts]="helptexts[0]"
  [data]="district"
  [showDelete]="true"
  (deleted)="deleteHealthData($event)"
  [informations]="informations"
  (dataSubmitted)="onData($event)"
>
</app-form>
