<h3 i18n="create new formula@@create_new_formula">Create new formula</h3>
<p i18n="avaliable collections@@available_collections">Available collections</p>
<div style="height: 20vh; overflow-y: scroll">
  <mat-list *ngIf="collections">
    <mat-list-item *ngFor="let collection of collections"
      ><button mat-raised-button (click)="selectCollection(collection)">
        {{ collection.collection }}
      </button>
    </mat-list-item>
  </mat-list>
</div>
<mat-divider></mat-divider>
<div style="height: 20vh; overflow-y: scroll">
  <h3 i18n="selected collection@@selected_collection">Selected collection {{ selectedCollection?.collection }}</h3>
  <p *ngIf="!selectedCollection" i18n="please select a collection@@please_select_a_collection">Please select a collection</p>
  <div *ngIf="selectedCollection">
    <div *ngFor="let field of selectedCollection.fields">{{ field.name }}</div>
  </div>
</div>
<div *ngIf="selectedCollection && formGroup">
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label i18n="name@@name">Formula Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="description@@description">Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button
    *ngIf="selectedCollection"
    [disabled]="!isNewFormulaValid()"
    mat-button
    [mat-dialog-close]="selectedCollection"
    i18n="submit@@submit"
  >
    Submit
  </button>
  <button mat-button color="accent" (click)="onCancel()" i18n="cancel@@cancel">Cancel</button>
</div>
