import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KoboService } from '../kobo.service';
import { KoboFormula } from './form-editor/kobo-formula';
import { NewFormulaDialogComponent } from './new-formula-dialog/new-formula-dialog.component';
import {LocaleService} from "../../locale.service";
import { SurveyModifierDialogComponent } from '../../survey-modifier-dialog/survey-modifier-dialog.component';

@Component({
  selector: 'app-kobo-forms',
  templateUrl: './kobo-forms.component.html',
  styleUrls: ['./kobo-forms.component.css'],
})
export class KoboFormsComponent implements OnInit {
  formulas: any[] | undefined;
  JSON: any;

  selectedFormula: any | undefined;
  constructor(private koboService: KoboService, 
              private dialog: MatDialog,
              private localeService: LocaleService) {
                this.JSON = JSON;
              }

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.loadData();
  }

  public loadData() {
    this.koboService.loadFormulas().subscribe((formulas) => {
      this.formulas = formulas;
      console.log("loaddata", this.formulas);
      this.formulas.forEach(formula => {
        console.log(formula)
        formula.formula.fields.forEach((field:any) => {
          if(!("divisor" in field)){
            field["divisor"] = [];
          }
        })
      })
    });
  }

  openNewFormulaDialog() {
    const dialogRef = this.dialog.open(NewFormulaDialogComponent, {
      width: '35vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.createNewFormula(result);
    });
  }

  createNewFormula(result: any) {
    if (result) {
      const newKoboFormula = {
        collectionName: result.collection,
        formulaName: result.name,
        formulaDescription: result.description,
        formula: this.buildFormulafromTemplate(result),
      };
      console.log(newKoboFormula)
      this.koboService.createNewFormula(newKoboFormula).subscribe(() => {
        this.loadData();
      });
    }
  }

  editFormula(formula: any) {
    this.selectedFormula = undefined;
    setTimeout(() => {
      this.selectedFormula = formula;
    }, 100);
  }

  private buildFormulafromTemplate(result: any) {
    const formula: KoboFormula = {
      collectionName: {
        collection: result.collection,
        display: result.name,
      },
      options: {},
      fields: [],
    };
    for (const field of result.fields) {
      formula.fields.push({
        name: field.name,
        type: field.type.type,
        value: 1,
        conditions: [],
        divisor: []
      });
    }
    return formula;
  }

  reloadDataset() {
    this.selectedFormula = undefined;
    this.loadData();
  }
}
