<mat-card-content>
  <div class="row">
    <div class="col">
      <mat-tab-group>
        <mat-tab label="Community Interactions" i18n-label="community interaction@@community_interaction">
          <app-community-interactions></app-community-interactions>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</mat-card-content>
