import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { User } from './user';
import { DataService } from './data.service';

type strapiResponse = {
  jwt: string;
  user: User;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user: User | undefined;

  constructor(private http: HttpClient, private dataService: DataService) {}

  login(email: string, password: string): Observable<User> {
    console.log(email, password)
    return this.http
      .post<strapiResponse>(`${environment.api}/auth/local`, {
        identifier: email,
        password,
      })
      .pipe(
        tap((res) => {
          this.setSession(res.jwt);
          this.user = res.user;
          this.dataService.loadDataForUser(this.user);
        }),
        map((response) => response.user),
        shareReplay()
      );
  }

  private setSession(token: string) {
    const jwt: { id: number; exp: number; iat: number } = jwt_decode(token);
    const expiresAt = moment().add(jwt.iat, 'second');
    localStorage.setItem('id_token', token);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
  }

  public isLoggedIn(): boolean {
    try {
      return moment().isBefore(this.getExpiration());
    } catch (e) {
      return false;
    }
  }

  public isLoggedOut() {
    return !this.isLoggedIn();
  }

  private getExpiration() {
    const exp = localStorage.getItem('expires_at');
    if (!exp) throw new Error('Not logged in');
    const expiresAt = JSON.parse(exp);
    return moment(expiresAt);
  }

  getUser(): Observable<User> {
    if (this.isLoggedOut()) throw new Error('Not logged in');
    if (this.user) return of(this.user);
    return this.http.get<User>(`${environment.api}/users/me`);
  }
}
