import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { User } from '../shared/user';
import {LocaleService} from "../locale.service";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
})
export class WelcomeComponent implements OnInit {
  user: User | undefined;

  greeting: string | undefined;

  constructor(private authService: AuthService,
              private localeService: LocaleService) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    if (this.authService.isLoggedIn()) {
      this.authService.getUser().subscribe((user: any) => {
        this.user = user;
        this.greeting = `${user.firstname} ${user.surname}`;
      });
    } else {
      this.greeting = 'dear visitor';
    }
  }
}
