import { Component, OnInit } from '@angular/core';
import {DataService} from "../shared/data.service";
import { switchMap, tap} from "rxjs/operators";
import {SocialInvestmentsService} from "./social-investments.service";
import {HelptextService} from "../shared/helptext.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Information} from "../shared/information";
import {LocaleService} from "../locale.service";

@Component({
  selector: 'app-social-investments',
  templateUrl: './social-investments.component.html',
  styleUrls: ['./social-investments.component.css']
})
export class SocialInvestmentsComponent implements OnInit {
  informations: Information[] = [
    {
      fieldName: 'id',
      display_en: 'none',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'none'
    },{
      fieldName: 'data_year',
      display_en: 'none',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'none'
    },
    {
      fieldName: 'name',
      display_en: 'Name',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'string',
    },
    {
      fieldName: 'activityType',
      display_en: 'Category',
      display_es: 'Categoría',
      display_fr: 'Catégorie',
      display_pt: 'Categoria',
      type: 'dropdown',
      options: [
        {value: "WellbeingAndServices", viewValue: 'WellbeingAndServices'},
        {value: "Environment", viewValue: "Environment"},
        {value: "EducationAndJobs", viewValue: "EducationAndJobs"},
        {value: "Health", viewValue: "Health"},
        {value: "SocialCapital", viewValue: "SocialCapital"},
      ] 
       },
    {
      fieldName: 'description',
      display_en: 'Description',
      display_es: 'Descripción',
      display_fr: 'Description',
      display_pt: 'Descrição',
      type: 'string',
    },

    {
      fieldName: 'contribution',
      display_en: 'Contribution of the Mine',
      display_es: 'Contribución de la mina',
      display_fr: 'Contribution de la mine',
      display_pt: 'Contribuição da Mina',
      type: 'number',
    },
    {
      fieldName: 'contributionUSD',
      display_en: 'Contribution in USD',
      display_es: 'Contribución en USD',
      display_fr: 'Contribution en USD',
      display_pt: 'Contribuição em USD',
      type: 'number',
    },
    {
      fieldName: 'contributionFromOhterParties',
      display_en: 'Contribution from other Parties',
      display_es: 'Contribución de otras partes',
      display_fr: 'Contribution des autres parties',
      display_pt: 'Contribuição de outras partes',
      type: 'string',
    },

    {
      fieldName: 'identificationNeeds',
      display_en: 'Identification Needs',
      display_es: 'Necesidades de identificación',
      display_fr: 'Besoins d\'identification',
      display_pt: 'Necessidades de Identificação',
      type: 'string',
    },

    {
      fieldName: 'directBeneficiaries',
      display_en: 'Direct Beneficiaries',
      display_es: 'Beneficiarios directos',
      display_fr: 'Bénéficiaires directs',
      display_pt: 'Beneficiários directos',
      type: 'string',
    },

    {
      fieldName: 'directlyCreatedJobs',
      display_en: 'Directly created Jobs',
      display_es: 'Puestos de trabajo creados directamente',
      display_fr: 'Emplois directement créés',
      display_pt: 'Empregos criados directamente',
      type: 'string',
    },
    {
      fieldName: 'complaintsReceived',
      display_en: 'Complaints Received',
      display_es: 'Reclamaciones recibidas',
      display_fr: 'Plaintes reçues',
      display_pt: 'Reclamações Recebidas',
      type: 'boolean',
    },
    {
      fieldName: 'complaintKeywords',
      display_en: 'Complaints Keywords',
      display_es: 'Reclamaciones Palabras clave',
      display_fr: 'Mots clés des plaintes',
      display_pt: 'Reclamaciones Palabras clave',
      type: 'string',
    },
    {
      fieldName: 'beneficieariesPopulationInformation',
      display_en: 'Beneficiaries population information',
      display_es: 'Información sobre la población beneficiaria',
      display_fr: 'Informations sur la population des bénéficiaires',
      display_pt: 'Informação sobre a população beneficiária',
      type: 'string',
    },
    {
      fieldName: 'start',
      display_en: 'Start Date',
      display_es: 'Fecha de inicio',
      display_fr: 'Date de début',
      display_pt: 'Data de início',
      type: 'date',
    },
    {
      fieldName: 'end',
      display_en: 'End Date',
      display_es: 'Fecha de finalización',
      display_fr: 'Date de fin',
      display_pt: 'Data de fim',
      type: 'date',
    },
    {
      fieldName: 'activityMaintainerAfterEnd',
      display_en: 'Activity Maintainer after End',
      display_es: 'Mantenedor de la actividad después del fin',
      display_fr: 'Mainteneur d\'activité après la fin',
      display_pt: 'Mantenedor da actividade após o fim',
      type: 'string',
    },
  ]
  data: any
  latestValue: any
  helptexts: any;

  constructor(
    private dataService: DataService,
    private socialInvestmentsService: SocialInvestmentsService,
    private helptextService: HelptextService,
    private snackbar: MatSnackBar,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.helptextService.getHelptexts('social-investments-helptexts').subscribe(res => this.helptexts = res)
    let tmp: any = undefined
    this.dataService.selectedYear.pipe(
      tap(datayear => {
        tmp = datayear
      }),
      switchMap(() => this.socialInvestmentsService.fetch()),
    ).subscribe(socialInvestments => {
      // @ts-ignore
      if (socialInvestments.length == 0) {
        this.data = [{
          name: '',
          data_year: tmp,
          description: '',
          contribution:'',
          contributionUSD: '',
          contributionFromOhterParties: '',
          identificationNeeds: '',
          directBeneficiaries: '',
          directlyCreatedJobs: '',
          complaintsReceived: '',
          complaintKeywords: '',
          beneficieariesPopulationInformation: '',
          start: '',
          end: '',
          activityMaintainerAfterEnd: '',
        }];
      } else {
        this.data = socialInvestments
      }
    })
  }

  onData(formData: any) {
    if (formData.id == null) {
      this.socialInvestmentsService.post(formData).subscribe(res => {
        this.data = [res]
        this.snackbar.open('Values updated successfully', undefined, {duration: 2500})
      })
    } else {
      this.socialInvestmentsService.update(formData).subscribe(() => {
        this.snackbar.open('Values updated successfully', undefined, {duration: 2500})
      })
    }
  }

  deleteSocialInvestment($event: any) {
    this.socialInvestmentsService.deleteSocialInvestment($event.id).subscribe((res: any) => {
      this.data = this.data.filter((obj: any) => obj.id != res.id)
      this.snackbar.open('Successfully deleted Social Investment', undefined, {duration: 2500})
    })
  }

  addSocialInvestment() {
    return this.socialInvestmentsService.createNewSocialInvestment().subscribe(res => {
      this.data.push(res)
      this.snackbar.open('Successfully added new Social Investment', undefined, {duration: 2500})
    })
  }
}
