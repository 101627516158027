<mat-card>
  <h1 i18n="change email address@@change_email_address">Change E-Mail Address</h1>
  <app-form
    [informations]="emailInformations"
    [data]="[]"
    (dataSubmitted)="onEmail($event)"
  ></app-form>
</mat-card>
<mat-card>
  <h1 i18n="change password@@change_password">Change Password</h1>
  <app-form
    [informations]="passwordInformations"
    [data]="[]"
    (dataSubmitted)="onData($event)"
  ></app-form>
</mat-card>

