import { Component, OnInit } from '@angular/core';
import {LocaleService} from "../locale.service";

@Component({
  selector: 'app-education-health-forms',
  templateUrl: './education-health-forms.component.html',
  styleUrls: ['./education-health-forms.component.css']
})
export class EducationHealthFormsComponent implements OnInit {

  constructor(private localeService: LocaleService) { }

  ngOnInit(): void {
    this.localeService.updateLocale()
  }


}
