<div *ngIf="condition && condition.type">
  <div style="display: flex">
    <div *ngIf="condition.type === 'combination'">
      <p i18n="combination@@combination">Combination:</p>
      <mat-button-toggle-group
        value="{{ condition.bool }}"
        (change)="selectCombination($event)"
      >
        <mat-button-toggle value="and" i18n="and@@and">And</mat-button-toggle>
        <mat-button-toggle value="or" i18n="or@@or">Or</mat-button-toggle>
        <mat-button-toggle value="average" i18n="average@@average">Average</mat-button-toggle>
        <mat-button-toggle value="head+children" i18n="average@@average">Head+children</mat-button-toggle>
        <mat-button-toggle value="one-of-children" i18n="average@@average">One-of-children</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div></div>
    <div style="margin-left: 5vw" *ngIf="condition.conditions">
      <p i18n="conditions@@conditions">Conditions:</p>
      <div *ngFor="let condition of condition.conditions; index as i">
        <button mat-button color="warn" (click)="removeCondition(i)" i18n="remove condition@@remove_condition">
          Remove condition
        </button>
        <div *ngIf="condition.type === 'combination'">{{ condition.bool }}</div>
        <div *ngIf="condition.type === 'statement'">
          {{ condition.surveyFieldName }}
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="condition" i18n="submit@@submit">Submit</button>
    <button mat-button color="accent" (click)="onCancel()" i18n="cancel@@cancel">Cancel</button>
  </div>
</div>
