import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KoboFieldService {
  private fields = new BehaviorSubject<{
    allFields: any[];
    sharedFields: any[];
  }>({
    allFields: [],
    sharedFields: [],
  });

  fieldObs = this.fields.asObservable();

  updateFields(assignedSurveys: any[]) {
    if (assignedSurveys && assignedSurveys.length > 0) {
      this.fields.next({
        sharedFields: this.calculateSharedFields(assignedSurveys),
        allFields: this.calculateAllFields(assignedSurveys),
      });
    }
  }
  private calculateSharedFields(assignedSurveys: any) {
    const sharedFields = assignedSurveys[0].surveyFields as string[];
    const notSharedFields: number[] = [];
    sharedFields.forEach((fieldName, index) => {
      for (const survey of assignedSurveys) {
        if (!survey.surveyFields.includes(fieldName))
          notSharedFields.push(index);
      }
    });
    notSharedFields
      .sort((a, b) => b - a)
      .forEach((index) => {
        sharedFields.splice(index, 1);
      });
    return sharedFields;
  }
  private calculateAllFields(assignedSurveys: any) {
    const allFields = assignedSurveys[0].surveyFields;
    for (const survey of assignedSurveys) {
      for (const field of survey.surveyFields) {
        if (!allFields.includes(field)) {
          allFields.push(field);
        }
      }
    }
    return allFields;
  }
}
