import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {LocaleService} from "../../../../locale.service";

@Component({
  selector: 'app-dialog-new-field',
  templateUrl: './dialog-new-field.component.html',
  styleUrls: ['./dialog-new-field.component.css'],
})
export class DialogNewFieldComponent implements OnInit {
  formGroup: FormGroup | undefined;

  fieldName: string | undefined;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DialogNewFieldComponent>,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.formGroup = this.fb.group({
      fieldName: '',
    });
    this.formGroup.valueChanges.subscribe((values) => {
      this.fieldName = values.fieldName;
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
