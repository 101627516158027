import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {LocaleService} from "../../../../../locale.service";

@Component({
  selector: 'app-kobo-combination-dialog',
  templateUrl: './kobo-combination-dialog.component.html',
  styleUrls: ['./kobo-combination-dialog.component.css'],
})
export class KoboCombinationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<KoboCombinationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public condition: any,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()}

  selectCombination(value: any) {
    if (this.condition && this.condition.type === 'combination') {
      this.condition['bool'] = value.value;
    }
  }
  removeCondition(index: number) {
    this.condition.conditions.splice(index, 1);
  }

  onCancel() {
    this.dialogRef.close();
  }
}
