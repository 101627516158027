import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { isArray } from 'rxjs/internal-compatibility';
import { HelptextService } from '../shared/helptext.service';
import { FCUServiceConfig } from '../shared/types/fcu-service-config';
import { FetchCreateUpdateService } from '../shared/fetch-create-update.service';
import { Helpers } from '../shared/helpers';
import {MatSnackBar} from "@angular/material/snack-bar";
import {LocaleService} from "../locale.service";

@Component({
  selector: 'app-company-payments-to-gov',
  templateUrl: './company-payments-to-gov.component.html',
  styleUrls: ['./company-payments-to-gov.component.css'],
})
export class CompanyPaymentsToGovComponent implements OnInit {
  private fcuConfig: FCUServiceConfig = {
    dyName: 'payments_to_gov',
    endpoint: 'payments-to-govs',
  };

  informations = [
    {
      fieldName: '',
      display_en: 'Payments to Government',
      display_es: 'Pagos a la Administración',
      display_fr: 'Paiements au gouvernement',
      display_pt: 'Pagamentos ao Governo',
      type: 'title',
    },
    {
      fieldName: 'id',
      display_en: 'none',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'none'
    },
    {
      fieldName: 'licenseFee',
      display_en: 'License Fee',
      display_es: 'Tasa de licencia',
      display_fr: 'Droits de licence',
      display_pt: 'Taxa de licença',
      type: 'number',
    },
    {
      fieldName: 'surfaceTax',
      display_en: 'Surface Tax',
      display_es: 'Impuesto de superficie',
      display_fr: 'Taxe de surface',
      display_pt: 'Imposto de Superfície',
      type: 'number',
    },
    {
      fieldName: 'naturalResourceUseCharge',
      display_en: 'Natural Resource Use Charge',
      display_es: 'Tasa por uso de recursos naturales',
      display_fr: 'Redevance d\'utilisation des ressources naturelles',
      display_pt: 'Taxa de Utilização de Recursos Naturais',
      type: 'number',
    },
    {
      fieldName: 'propertyTax',
      display_en: 'Property Tax',
      display_es: 'Impuesto sobre bienes inmuebles',
      display_fr: 'Impôt foncier',
      display_pt: 'Imposto predial',
      type: 'number',
    },
    {
      fieldName: 'csrTax',
      display_en: 'CSR Tax',
      display_es: 'Impuesto sobre la RSE',
      display_fr: 'Taxe CSR',
      display_pt: 'Imposto RSE',
      type: 'number',
    },
    {
      fieldName: 'royalities',
      display_en: 'Royalities',
      display_es: 'Regalías',
      display_fr: 'Redevances',
      display_pt: 'Realidades',
      type: 'number',
    },
    {
      fieldName: 'withholdingTaxesPaid',
      display_en: 'Withholding Taxes Paid',
      display_es: 'Retenciones pagadas',
      display_fr: 'Retenues d\'impôt payées',
      display_pt: 'Impostos retidos na fonte pagos',
      type: 'number',
    },
    {
      fieldName: 'vatSalesTax',
      display: 'VAT Sales Tax',
      display_es: 'IVA Impuesto sobre las ventas',
      display_fr: 'TVA Taxe de vente',
      display_pt: 'IVA Imposto sobre as vendas',
      type: 'number',
    },
    {
      fieldName: 'importExportDuties',
      display: 'Import/Export duties',
      display_es: 'Derechos de importación/exportación',
      display_fr: 'Droits d\'importation/exportation',
      display_pt: 'Direitos de importação/exportação',
      type: 'number',
    },
    {
      fieldName: 'fuelDutiesVehicleTaxation',
      display: 'Fuel Duties Vehicle Taxation',
      display_es: 'Impuestos sobre los carburantes Impuestos sobre los vehículos',
      display_fr: 'Droits sur les carburants Taxation des véhicules',
      display_pt: 'Taxas sobre combustíveis Imposto sobre veículos',
      type: 'number',
    },
    {
      fieldName: 'payRollTaxes',
      display: 'Payroll Taxes',
      display_es: 'Impuestos sobre la nómina',
      display_fr: 'Impôts sur les salaires',
      display_pt: 'Impostos sobre os salários',
      type: 'number',
    },
    {
      fieldName: 'socialSecurityTaxes',
      display: 'Social Security Taxes',
      display_es: 'Impuestos de la Seguridad Social',
      display_fr: 'Taxes de sécurité sociale',
      display_pt: 'Impostos da Segurança Social',
      type: 'number',
    },
    {
      fieldName: 'irpcTurnoverTax',
      display: 'Irpc Turnover Tax',
      display_es: 'Impuesto sobre el volumen de negocios del Irpc',
      display_fr: 'Taxe sur le chiffre d\'affaires de l\'Irpc',
      display_pt: 'Imposto sobre o volume de negócios Irpc',
      type: 'number',
    },
    {
      fieldName: 'capitalGainsTax',
      display: 'Capital Gains Tax',
      display_es: 'Impuesto sobre las ganancias de capital',
      display_fr: 'Impôt sur les gains en capital',
      display_pt: 'Imposto sobre Ganhos de Capital',
      type: 'number',
    },
    {
      fieldName: 'personalIncomeTax',
      display: 'Personal Income Tax',
      display_es: 'Impuesto sobre la Renta de las Personas Físicas',
      display_fr: 'Impôt sur le revenu des personnes physiques',
      display_pt: 'Imposto sobre o Rendimento das Pessoas Singulares',
      type: 'number',
    },
  ];
  data: any;
  latestValue: any;
  helptexts: any;

  constructor(
    private dataService: DataService,
    private fcuService: FetchCreateUpdateService,
    private snackbar: MatSnackBar,
    private helptextService: HelptextService,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.helptextService
      .getHelptexts('payments-to-government-helptexts')
      .subscribe((res) => {
        this.helptexts = res;
      });
    this.dataService.selectedYear
      .pipe(
        switchMap((dy) => this.fcuService.fetch(this.fcuConfig, dy)),
        map((arr) => (isArray(arr) ? arr[0] : arr))
      )
      .subscribe((payments) => {
        if (!payments)
          this.data = Helpers.createEmptyDataset(this.informations);
        else this.data = payments;
      });
  }
  onData(formData: any) {
    console.log(formData);
    if (formData.id) {
      this.fcuService.update(this.fcuConfig, formData).subscribe((payments) => {
        this.snackbar.open(
          `Updated payments to government data for ${payments.data_year.year} successfully`
        );
        this.data = payments;
      });
    } else {
      this.dataService.selectedYear
        .pipe(
          switchMap((dy) =>
            this.fcuService.create(this.fcuConfig, dy, formData)
          ),
          // @ts-ignore
          map((arr) => (isArray(arr) ? arr[0] : arr))
        )
        .subscribe((payments) => {
          this.snackbar.open(
            `Created payments to government data for ${payments.data_year.year} successfully`
          );
          this.data = payments;
        });
    }
  }
}
