import { Component, OnInit } from '@angular/core';
import {LocaleService} from "../locale.service";

@Component({
  selector: 'app-social-capital-forms',
  templateUrl: './social-capital-forms.component.html',
  styleUrls: ['./social-capital-forms.component.css']
})
export class SocialCapitalFormsComponent implements OnInit {

  constructor(private localeService: LocaleService) { }

  ngOnInit(): void {
    this.localeService.updateLocale()
  }

}
