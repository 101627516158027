<h3 i18n="add new year@@add_new_year">Add new year</h3>
<div *ngIf="formGroup">
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label i18n="new year@@new_year">New Year</mat-label>
      <input matInput type="number" required formControlName="year" />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button
    [disabled]="!isNewYearValid()"
    mat-button
    [mat-dialog-close]="newYear"
    i18n="submit@@submit"
  >
    Submit
  </button>
  <button mat-button color="accent" (click)="onCancel()" i18n="cancel@@cancel">Cancel</button>
</div>
