<h2 i18n="change statement@@change_statement">Change statement</h2>
<form *ngIf="formGroup" [formGroup]="formGroup">
  <mat-form-field style="width: 45%">
    <input
      matInput
      placeholder="Field"
      formControlName="field"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let field of surveyFieldOptions | async"
        [value]="field"
      >
        {{ field }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field
    appearance="fill"
    style="width: 15%; margin-left: 2%; margin-right: 2%"
  >
    <mat-label i18n="relation@@relation">Relation</mat-label>
    <mat-select formControlName="relation">
      <mat-option value="eq">=</mat-option>
      <mat-option value="gt">></mat-option>
      <mat-option value="gte">>=</mat-option>
      <mat-option value="lt"><</mat-option>
      <mat-option value="lte"><=</mat-option>
      <mat-option value="ne">≠</mat-option>
      <mat-option value="in" i18n="includes@@includes">includes</mat-option>
      <mat-option value="notIn" i18n="not includes@@not_includes">not includes</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field style="width: 35%">
    <input matInput placeholder="Field"  i18n-placeholder="field@@field" formControlName="value" />
  </mat-form-field>
</form>
<div mat-dialog-actions>
  <button
    mat-button
    [disabled]="!isStatementValid()"
    [mat-dialog-close]="statement"
    i18n="submit@@submit"
  >
    Submit
  </button>
  <button mat-button color="accent" (click)="onCancel()" i18n="cancel@@cancel">Cancel</button>
</div>
