<div *ngIf="data && helptexts && informations">
  <ng-container *ngFor="let datum of data">
    <app-form
      [informations]="informations"
      [data]="datum"
      [helptexts]="helptexts[0]"
      (dataSubmitted)="onData($event)"
    ></app-form>
  </ng-container>
  <button mat-raised-button color="accent" (click)="addGrievance()" i18n="add grievance@@add_grievance">Add Grievance</button>
  <ng-container *ngFor="let grievance of grievances">
    <app-form
    [informations]="grievanceInformations"
    [data]="grievance"
    [showDelete]="true"
    (deleted)="deleteGrievance($event)"
    (dataSubmitted)="onGrievanceData($event)"
    ></app-form>
  </ng-container>
</div>
