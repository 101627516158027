<mat-card-content>
  <div class="row">
    <div class="col">
      <mat-tab-group>
        <mat-tab label="Regional Completion Rates" i18n-label="regional completion rates@@regional_completion_rates">
          <app-educational-data></app-educational-data>
        </mat-tab>
        <mat-tab label="Health Workers" i18n-label="health workers@@health_workers">
          <app-health-data></app-health-data>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</mat-card-content>
