import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { KoboService } from '../../kobo.service';
import { LocaleService } from "../../../locale.service";

@Component({
  selector: 'app-new-formula-dialog',
  templateUrl: './new-formula-dialog.component.html',
  styleUrls: ['./new-formula-dialog.component.css'],
})
export class NewFormulaDialogComponent implements OnInit {
  collections: any[] | undefined;

  selectedCollection: any | undefined;

  formGroup: FormGroup | undefined

  constructor(
    private fb: FormBuilder,
    private koboService: KoboService,
    private dialogRef: MatDialogRef<NewFormulaDialogComponent>,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.koboService.loadHHCollection().subscribe((collections) => {
      this.collections = collections;
      console.log(this.collections);
    });
    this.formGroup = this.fb.group({
      name:'',
      description: ''
    })
    this.formGroup.valueChanges.subscribe((values) => {
      if(this.selectedCollection){
        this.selectedCollection['name'] = values.name
        this.selectedCollection['description'] = values.description
      }
    })
  }
  selectCollection(collection: any) {
    this.selectedCollection = collection;
    if(this.formGroup){
      this.formGroup.controls.name.setValue('')
      this.formGroup.controls.description.setValue('')
    }
  }

  isNewFormulaValid(){
    if(this.selectedCollection){
      return (this.selectedCollection.name && this.selectedCollection.name?.length > 2)
    }
    return false
  }

  isValid(){
    return this.selectedCollection && this.selectedCollection.name.length > 4
  }

  onCancel() {
    this.dialogRef.close();
  }
}
