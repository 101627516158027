import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KoboStatement } from '../kobo-statement';
import { KoboStatementDialogComponent } from './kobo-statement-dialog/kobo-statement-dialog.component';
import {LocaleService} from "../../../../locale.service";

@Component({
  selector: 'app-kobo-statement',
  templateUrl: './kobo-statement.component.html',
  styleUrls: ['./kobo-statement.component.css'],
})
export class KoboStatementComponent implements OnInit {
  @Input() input: any;

  statement: KoboStatement | undefined;

  constructor(public dialog: MatDialog,
              private localeService: LocaleService) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    if (this.input && this.input.type === 'statement')
      this.statement = this.input;
  }

  openChangeStatementDialog(): void {
    const dialogRef = this.dialog.open(KoboStatementDialogComponent, {
      width: '80vw',
      data: JSON.parse(JSON.stringify(this.statement)),
    });
    dialogRef.afterClosed().subscribe((result: KoboStatement) => {
      if (result && result.type == 'statement' && this.statement) {
        this.statement.surveyFieldName = result.surveyFieldName;
        this.statement.statement = result.statement;
      }
    });
  }
}
