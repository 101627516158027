import { Component, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { DataService } from 'src/app/shared/data.service';
import { HelptextService } from 'src/app/shared/helptext.service';
import { CommunityInteractionsService } from './community-interactions.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {Information} from "../../shared/information";
import {LocaleService} from "../../locale.service";

@Component({
  selector: 'app-community-interactions',
  templateUrl: './community-interactions.component.html',
  styleUrls: ['./community-interactions.component.css'],
})
export class CommunityInteractionsComponent implements OnInit {
  grievanceInformations = [
    {
      fieldName: 'id',
      display_en: 'none',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'n/a',
    },{
      fieldName: 'category',
      display_en: 'Category',
      display_es: 'Categoría',
      display_fr: 'Catégorie',
      display_pt: 'Categoria',
      type: 'string',
    },{
      fieldName: 'keyword',
      display_en: 'Keyword',
      display_es: 'Palabra clave',
      display_fr: 'Mot clé',
      display_pt: 'Palavra-chave',
      type: 'string',
    },
  ]
  informations: Information[] = [
    {
      fieldName: 'id',
      display_en: 'none',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'n/a'
    },
    {
      fieldName: 'meetingLocalAuth',
      display_en: 'Meeting with local authorities',
      display_es: 'Reunión con las autoridades locales',
      display_fr: 'Réunion avec les autorités locales',
      display_pt: 'Reunião com as autoridades locais',
      type: 'number',
    },
    {
      fieldName: 'meetingLocalAuthTotParticipants',
      display_en: 'Meeting with local authorities total participants',
      display_es: 'Reunión con las autoridades locales total de participantes',
      display_fr: 'Réunion avec les autorités locales total des participants',
      display_pt: 'Reunião com as autoridades locais total de participantes',
      type: 'number',
    },
    {
      fieldName: 'meetingCommunityRepresentatives',
      display_en: 'Meeting with community representatives',
      display_es: 'Reunión con representantes de la comunidad',
      display_fr: 'Réunion avec les représentants de la communauté',
      display_pt: 'Reunião com representantes da comunidade',
      type: 'number',
    },
    {
      fieldName: 'meetingCommunityRepresentativesTotParticipants',
      display_en: 'Meeting with community representatives total participants',
      display_es: 'Reunión con representantes de la comunidad total de participantes',
      display_fr: 'Réunion avec les représentants de la communauté ; total des participants',
      display_pt: 'Reunião com representantes da comunidade total de participantes',
      type: 'number',
    },
    {
      fieldName: 'publicTownHallMeetings',
      display_en: 'Public townhall meetings',
      display_es: 'Reuniones públicas en el ayuntamiento',
      display_fr: 'Réunions publiques de discussion ouverte',
      display_pt: 'Reuniões públicas na cidade',
      type: 'number',
    },
    {
      fieldName: 'publicTownHallMeetingsTotalParticipants',
      display_en: 'Public townhall meetings (total participants)',
      display_es: 'Reuniones públicas (total de participantes)',
      display_fr: 'Réunions publiques de discussion ouverte (total des participants)',
      display_pt: 'Reuniões públicas (total de participantes)',
      type: 'number',
    },
    {
      fieldName: 'InformationCampaigns',
      display_en: 'Information campaigns',
      display_es: 'Campañas de información',
      display_fr: 'Campagnes d\'information',
      display_pt: 'Campanhas de informação',
      type: 'string',
    },
    {
      fieldName: 'asmOreBuying',
      display_en: 'ASM ore buying',
      display_es: 'Compra de mineral de MAPE',
      display_fr: 'Achat de minerais par ASM',
      display_pt: 'Compra de minério MAPE',
      type: 'boolean',
    },
    {
      fieldName: 'asmEngagementAgreement',
      display_en: 'ASM engagement agreement',
      display_es: 'Acuerdo de compromiso con la MAPE',
      display_fr: 'Accord d\'engagement ASM',
      display_pt: 'Acordo de compromisso da MAPE',
      type: 'boolean',
    },
    {
      fieldName: 'grievComplaints',
      display_en: 'Grievance Complaints',
      display_es: 'Reclamaciones de quejas',
      display_fr: 'Plaintes de griefs',
      display_pt: 'Queixas de reclamação',
      type: 'number',
    },
    {
      fieldName: 'grievComplaintsIncludingASM',
      display_en: 'Grievance Complaints (including ASM miners)',
      display_es: 'Reclamaciones (incluidos los mineros de la MAPE)',
      display_fr: 'Plaintes de griefs (y compris les mineurs de l\'ASM)',
      display_pt: 'Queixas de reclamação (incluindo os mineiros da MAPE)',
      type: 'number',
    },
    {
      fieldName: 'grievProtest',
      display_en: 'Grievance Protests',
      display_es: 'Protestas por agravios',
      display_fr: 'Protestations de griefs',
      display_pt: 'Protestos de reclamação',
      type: 'number',
    },
    {
      fieldName: 'grievProtestIncludingASM',
      display_en: 'Grievance Protests (including ASM miners)',
      display_es: 'Protestas por reclamaciones (incluidos los mineros de la MAPE)',
      display_fr: 'Protestations de griefs (y compris les mineurs de l\'ASM)',
      display_pt: 'Protestos de reclamação (incluindo os mineiros da MAPE)',
      type: 'number',
    },
    {
      fieldName: 'grievConflict',
      display_en: 'Grievance Conflicts',
      display_es: 'Conflictos de reclamaciones',
      display_fr: 'Conflits de griefs',
      display_pt: 'Conflitos de luto',
      type: 'number',
    },
    {
      fieldName: 'grievConflictIncludingASM',
      display_en: 'Grievance Conflicts (including ASM miners)',
      display_es: 'Conflictos por reclamaciones (incluidos los mineros de la MAPE)',
      display_fr: 'Conflits liés aux griefs (y compris les mineurs de l\'ASM)',
      display_pt: 'Conflitos de queixas (incluindo os mineiros da MAPE)',
      type: 'number',
    },
    {
      fieldName: 'businessInterruptions',
      display_en: 'Business Interruptions',
      display_es: 'Interrupciones comerciales',
      display_fr: 'Interruptions d\'activité',
      display_pt: 'Interrupções de negócios',
      type: 'number',
    },
    {
      fieldName: 'complaintsReceived',
      display_en: 'Complaints Received',
      display_es: 'Reclamaciones recibidas',
      display_fr: 'Plaintes reçues',
      display_pt: 'Reclamações Recebidas',
      type: 'number',
    },
    {
      fieldName: 'complaintsResolved',
      display_en: 'Complaints Resolved',
      display_es: 'Reclamaciones resueltas',
      display_fr: 'Plaintes résolues',
      display_pt: 'Queixas Resolvidas',
      type: 'number',
    },
    {
      fieldName: 'complaintsPending',
      display_en: 'Complaints Pending',
      display_es: 'Reclamaciones pendientes',
      display_fr: 'Plaintes en suspens',
      display_pt: 'Reclamações Pendentes',
      type: 'number',
    },
  ];

  data: any;
  latestValue: any;
  helptexts: any;
  grievances: any[] = [];
  private communityInteractionsId: number = 0;

  constructor(
    private dataService: DataService,
    private communityInteractionsService: CommunityInteractionsService,
    private helptextService: HelptextService,
    private snackbar: MatSnackBar,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.helptextService
      .getHelptexts('social-investments-helptexts')
      .subscribe((res) => (this.helptexts = res));
    this.dataService.selectedYear
      .pipe(switchMap(() => this.communityInteractionsService.fetch()))
      .subscribe((communityInteractions: any) => {
        // @ts-ignore
        if (communityInteractions.length == 0) {
          this.communityInteractionsService
            .createNewSocialInvestment()
            .subscribe((res) => {
              this.data = [res];
            });
        } else {
          this.data = communityInteractions;
          this.communityInteractionsId = communityInteractions[0].id
          communityInteractions.forEach((interaction: any) => {
            interaction.z_grievances.forEach((grievance: any) => {
              this.grievances.push(grievance)
            })
          })
        }
      });
  }

  onData(formData: any) {
    this.communityInteractionsService.update(formData).subscribe(() => {
      this.snackbar.open('Values updated successfully', undefined, {duration: 2500})
    });
  }

  onGrievanceData($event: any) {
    this.communityInteractionsService.updateGrievance($event).subscribe(() => {
      this.snackbar.open('Values updated successfully', undefined, {duration: 2500})
    })
  }

  addGrievance() {
    this.communityInteractionsService.addGrievance(this.communityInteractionsId).subscribe(res =>{
      this.grievances.push(res)
    })
  }

  deleteGrievance($event: any) {
    this.communityInteractionsService.deleteGrievance($event.id).subscribe((res: any) => {
      this.grievances = this.grievances.filter(griev => griev.id != res.id)
    })
  }
}
