import { Component, OnInit } from '@angular/core';
import {HealthDataService} from "./health-data.service";
import {EducationalDataService} from "../educational-data/educational-data.service";
import {HelptextService} from "../shared/helptext.service";
import {isArray} from "rxjs/internal-compatibility";
import {MatSnackBar} from "@angular/material/snack-bar";
import {switchMap} from "rxjs/operators";
import {combineLatest, of} from "rxjs";
import {DataService} from "../shared/data.service";
import {LocaleService} from "../locale.service";

@Component({
  selector: 'app-health-data',
  templateUrl: './health-data.component.html',
  styleUrls: ['./health-data.component.css']
})
export class HealthDataComponent implements OnInit {


  informations = [
    {
      fieldName: 'id',
      display: 'none',
      type: 'n/a'
    },
    {
      fieldName: 'data_year',
      display: 'none',
      type: 'n/a'
    },
    {
      fieldName: 'district',
      display: 'District',
      type: 'string'
    },
    {
      fieldName: 'doctors',
      display: 'doctors',
      type: 'number'
    },
    {
      fieldName: 'nurses',
      display: 'nurses',
      type: 'number'
    },
    {
      fieldName: 'midwifes',
      display: '(auxiliary) midwives',
      type: 'number'
    },
    {
      fieldName: 'healthWorkers',
      display: 'community health workers',
      type: 'number'
    },

  ];
  helptexts: any[] = []
  districts: any[] = []

  constructor(private healthDataService: HealthDataService,
              private helptextService: HelptextService,
              private snackbar: MatSnackBar,
              private dataservice: DataService,
              private localeService: LocaleService
  ) { }

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.helptextService.getHelptexts('helptexts-health-workers').subscribe(res => this.helptexts = res)
    combineLatest([
      this.dataservice.selectedYear,
      this.healthDataService.getHealthWorkersData()
    ]).pipe(
      switchMap(([year, res]) => {
        if (res.length < 1) {
          return of({
            data_year: year,
            doctors: '',
            midwifes: '',
            healthWorkers: '',
            nurses: '',
            district: ''
          })
        }
        return of(res)
      })
    ).subscribe(res => {
      isArray(res) ? this.districts = res : this.districts = [res]
    })
  }

  onData(formData: any) {
    if (!this.districts) throw new Error('Missing Data');
    if (formData.id == null) {
      this.healthDataService.post(formData).subscribe(res => {
        this.snackbar.open('Values updated successfully', undefined , {duration: 2500})
        this.districts.pop()
        this.districts.push(res)
      })
    } else  {
      this.healthDataService.updateData(formData).subscribe((data) => {
        this.snackbar.open('Values updated successfully', undefined , {duration: 2500})
        formData = data;
      });
    }
  }

  addNewHealthData() {
    this.dataservice.selectedYear.subscribe(year => {
      this.districts.push({
        data_year: year,
        doctors: '',
        midwifes: '',
        healthWorkers: '',
        nurses: '',
        district: ''
      })
    })
  }

  deleteHealthData($event: any) {
    this.healthDataService.delete($event.id).subscribe((res: any) => {
      this.districts = this.districts.filter(distr => distr.id != res.id)
      this.snackbar.open('Deleted successfully', undefined, {duration: 2500})
    })
  }

}
