import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataService } from '../shared/data.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectDetailService {
  private endPoint = 'projects';

  constructor(private http: HttpClient, private navService: DataService) {}

  fetchProject(projectId: number) {
    return this.http.get(this.buildIdUrl(this.endPoint, projectId));
  }

  updateData(data: { [key: string]: any }) {
    delete data.operating_company;
    return this.http
      .put(this.buildIdUrl(this.endPoint, data.id), data)
      .pipe(tap(() => this.navService.reloadData()));
  }

  fetchAll() {
    return this.http.get(`${environment.api}/${this.endPoint}`);
  }

  private buildIdUrl(endpoint: string, id: number) {
    return `${environment.api}/${endpoint}/${id}`;
  }

  fetchOperatingCompanies(id: number) {
    return this.http.get<any[]>(`${environment.api}/operating-companies?project.id=${id}`)
  }

  updateOperatingCompanies($event: any) {
    return this.http.put(`${environment.api}/operating-companies/${$event.id}`, $event)
  }

  deleteOperatingCompany(id: number) {
    return this.http.delete(`${environment.api}/operating-companies/${id}`)
  }

  addOperatingCompany(projectId: number) {
    return this.http.post(`${environment.api}/operating-companies`, {project: projectId})
  }
}
