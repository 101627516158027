import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, retry, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Metadata } from './metadata';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  constructor(private http: HttpClient) {}

  getMetadata(graphName: string, projectId: number): Observable<Metadata> {
    return this.http
      .get<Metadata[]>(`${environment.api}/metadata?diagram_name=${graphName}`)
      .pipe(
        tap((res) => {
          console.log(res);
        }),
        switchMap((res: Metadata[]) => {
          if (res.length === 0) {
            return this.createNewMetadata(graphName).pipe(map((res) => res));
          } else {
            return of(this.findMetadataForProject(res, projectId));
          }
        })
      );
  }

  private findMetadataForProject(res: Metadata[], projectId: number): Metadata {
    const projectMetadata = res.find(
      (metadata) => metadata.project && metadata.project.id === projectId
    );
    if (projectMetadata !== undefined) return projectMetadata;
    const defaultMetadata = res.find((metadata) => metadata.project === null);
    if (defaultMetadata !== undefined) return defaultMetadata;
    throw new Error('No Metadata found');
  }

  createNewMetadata(graphName: string) {
    return this.http.post<Metadata>(`${environment.api}/metadata`, {
      diagram_name: graphName,
    });
  }

  update(result: any): Observable<Metadata> {
    return this.http.put<Metadata>(
      `${environment.api}/metadata/${result.id}`,
      result
    );
  }

  post(result: any): Observable<Metadata> {
    console.log('New metadata', result);
    return this.http.post<Metadata>(`${environment.api}/metadata`, result);
  }

  postNewAlteredDefault(result: any, projectId: number): Observable<Metadata> {
    console.log('New Project metadata');
    delete result.id;
    result.project = projectId;
    return this.http.post<Metadata>(`${environment.api}/metadata`, result);
  }
}
