import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogNewFieldComponent } from '../dialog-new-field/dialog-new-field.component';
import { KoboCombinationConditionDialogComponent } from '../kobo-combination/kobo-combination-condition-dialog/kobo-combination-condition-dialog.component';
import { KoboFormulaField } from '../kobo-formula-field';
import {LocaleService} from "../../../../locale.service";

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.css'],
})
export class FormFieldComponent implements OnInit {
  @Input() formField: KoboFormulaField | undefined;

  @Input() fieldindex: number | undefined;

  @Input() isFieldDeletable?: boolean | undefined;

  @Output() deleteFieldEvent = new EventEmitter();

  public JSON;
  formGroup: FormGroup | undefined;
  surveyFieldOptions: string[] = [];

  constructor(public dialog: MatDialog,
              private fb: FormBuilder,
              private localeService: LocaleService) {
    this.JSON = JSON;
    this.surveyFieldOptions = ['hazard', 'mine'];
  }

  ngOnInit(): void {
    this.localeService.updateLocale()
    console.log("form-field.component.ts", "ngOnInit", this.formField);
    this.formGroup = this.fb.group({
      type: this.formField!.value,
    });
    this.formGroup.valueChanges.subscribe((values) => {
      console.log("formvalues", values);
      this.formField!.value = values.type;
      console.log("formfield", this.formField)
    });
  }

  private assertData() {
    if (!this.formField || this.fieldindex === undefined) {
      throw Error('Component not properly supplied with data');
    }
    return true;
  }

  openAddConditionDialog(type="numerator"): void {
    this.assertData();
    const dialogRef = this.dialog.open(
      KoboCombinationConditionDialogComponent,
      {
        width: '80vw',
        data: {},
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if(type=="numerator"){
          this.formField!.conditions.push(result);
        }
        else if(type=="divisor"){
          this.formField!.divisor.push(result);
        }
      }
    });
  }

  clearConditions(type="numerator") {
    this.assertData();
    if(type=="numerator"){
      this.formField!.conditions = [];
    }
    else if(type=="divisor"){
      this.formField!.divisor = [];
    }
  }

  addNewField() {
    this.assertData();
    const dialogRef = this.dialog.open(DialogNewFieldComponent, {
      width: '80vw',
    });
    dialogRef.afterClosed().subscribe((f) => {
      if (f && f.length > 0) {
        if (!this.formField!.detailFields) this.formField!.detailFields = [];
        this.formField!.detailFields!.push({
          name: f,
          type: 'decimal',
          value: 1,
          conditions: [],
          divisor: []
        });
      }
    });
  }

  deleteField() {
    if (this.isFieldDeletable && this.isFieldDeletable === true) {
      this.deleteFieldEvent.emit(this.formField);
    }
  }

  deleteFreeField(freefieldIndex: number) {
    if (this.formField && this.formField.detailFields) {
      this.formField?.detailFields?.splice(freefieldIndex, 1);
    }
  }
}
