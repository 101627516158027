<div *ngIf="formGroup">
  <form [formGroup]="formGroup" novalidate (ngSubmit)="onSubmit()">
    <mat-card class="shipping-card">
      <mat-card-content>
        <div *ngFor="let info of informations">
          <mat-card-title *ngIf="info.type === 'title'">{{
            info['display_' + locale]
          }}</mat-card-title>
          <div class="row flex" *ngIf="info.type === 'string'">
            <div class="col flex-50">
              <mat-form-field class="full-width">
                <input
                  matInput
                  placeholder="{{ info['display_' + locale] }}"
                  formControlName="{{ info.fieldName }}"
                />
              </mat-form-field>
            </div>
            <div class="helptext flex-50" *ngIf="helptexts">
              <mat-icon>info_outlined</mat-icon>
              <div [innerHtml]="helptexts[info.fieldName]"></div>
            </div>
          </div>
          <div class="row" *ngIf="info.type === 'email'">
            <div class="col flex-50">
              <mat-form-field class="full-width">
                <input
                  matInput
                  type="email"
                  placeholder="{{ info['display_' + locale] }}"
                  formControlName="{{ info.fieldName }}"
                />
              </mat-form-field>
            </div>
            <div class="helptext flex-50" *ngIf="helptexts">
              <mat-icon>info_outlined</mat-icon>
              <div [innerHtml]="helptexts[info.fieldName]"></div>
            </div>
          </div>
          <div class="row" *ngIf="info.type === 'password'">
            <div class="col flex-50">
              <mat-form-field class="full-width">
                <input
                  matInput
                  type="password"
                  placeholder="{{ info['display_' + locale] }}"
                  formControlName="{{ info.fieldName }}"
                />
              </mat-form-field>
            </div>
            <div class="helptext flex-50" *ngIf="helptexts">
              <mat-icon>info_outlined</mat-icon>
              <div [innerHtml]="helptexts[info.fieldName]"></div>
            </div>
          </div>
          <div class="row" *ngIf="info.type === 'textarea'">
            <div class="col flex-50">
              <mat-form-field class="full-width">
                <textarea
                  matInput
                  placeholder="{{ info['display_' + locale] }}"
                  formControlName="{{ info.fieldName }}"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="helptext flex-50" *ngIf="helptexts">
              <mat-icon>info_outlined</mat-icon>
              <div [innerHtml]="helptexts[info.fieldName]"></div>
            </div>
          </div>
          <div class="row" *ngIf="info.type === 'number'">
            <div class="col flex-50">
              <mat-form-field class="full-width">
                <input
                  matInput
                  type="number"
                  placeholder="{{ info['display_' + locale] }}"
                  formControlName="{{ info.fieldName }}"
                />
              </mat-form-field>
            </div>
            <div class="helptext flex-50" *ngIf="helptexts">
              <mat-icon>info_outlined</mat-icon>
              <div [innerHtml]="helptexts[info.fieldName]"></div>
            </div>
          </div>
          <div class="row" *ngIf="info.type === 'boolean'">
            <div class="col flex-50">
              <mat-checkbox formControlName="{{ info.fieldName }}">{{
                info['display_' + locale]
              }}</mat-checkbox>
            </div>
            <div class="helptext flex-50" *ngIf="helptexts">
              <mat-icon>info_outlined</mat-icon>
              <div [innerHtml]="helptexts[info.fieldName]"></div>
            </div>
          </div>
          <div class="row" *ngIf="info.type === 'date'">
            <mat-form-field>
              <input
                matInput
                placeholder="{{ info['display_' + locale] }}"
                formControlName="{{ info.fieldName }}"
                [matDatepicker]="picker"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>

              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="row flex-50" *ngIf="info.type === 'dropdown'">
            <div class="flex-51">
              <mat-form-field>
                <mat-label>{{info['display_' + locale]}}</mat-label>
                <mat-select [formControlName]="info.fieldName"
                            *ngIf="data[info.fieldName] !== null"
                            [value]="data[info.fieldName].id">
                  <mat-option *ngFor="let option of info.options" [value]="option.value">{{option.viewValue}}</mat-option>
                </mat-select>
                <mat-select
                  *ngIf="data[info.fieldName] === null"
                  [formControlName]="info.fieldName"
                >
                  <mat-option
                    *ngFor="let option of info.options"
                    [value]="option.value"
                  >{{ option.viewValue }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="helptext flex-50 mw-40" *ngIf="helptexts">
              <mat-icon>info_outlined</mat-icon>
              <div [innerHtml]="helptexts[info.fieldName]"></div>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button
          *ngIf="showButton"
          mat-raised-button
          color="primary"
          [disabled]="!hasChangedForm || !formGroup.valid"
          type="submit"
          i18n="submit@@submit"
        >
          Submit
        </button>
        <button
          *ngIf="showCancelButton"
          mat-raised-button
          color="accent"
          type="button"
          (click)="cancel()"
          i18n="cancel@@cancel"
        >
          Cancel
        </button>
        <button
          *ngIf="showDelete"
          mat-raised-button
          color="warn"
          type="button"
          (click)="deleteEvent(formGroup)"
          i18n="delete@@delete"
        >
          Delete
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
<div *ngIf="!formGroup">No form detected</div>
