<mat-card>
  <h2 i18n="kobo_forms@@kobo_forms">KOBO Forms</h2>
  <button mat-button (click)="openNewFormulaDialog()" i18n="create formula@@create_formula">
    Create new formula
  </button>
  <mat-list *ngIf="formulas && formulas.length > 0">
    <mat-list-item *ngFor="let formula of formulas"
    >
      <button
        style="margin-right: 1vw"
        mat-raised-button
        (click)="editFormula(formula)"
        i18n="edit@@edit"
      >
        Edit
      </button>
      {{ formula.formulaName && formula.formulaName }}:
      {{ formula.formula && formula.formula.fields && formula.formula.fields.length }} <p i18n="fields@@fields">Fields</p>
      <span style="padding: 0px 5px"> </span> (collection: {{ formula.collectionName }} )
    </mat-list-item
    >
  </mat-list>
  <mat-list *ngIf="!formulas || formulas.length === 0">
    <mat-list-item i18n="no_formulas@@no_formulas">No formulas detected... Create new formulas!</mat-list-item>
  </mat-list>
  <app-form-editor
    (deleted)="reloadDataset()"
    *ngIf="selectedFormula"
    [formula]="selectedFormula"
    (duplicated)="reloadDataset()"
  ></app-form-editor>
</mat-card>
