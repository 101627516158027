<div *ngIf="title">
  <form *ngIf="sampleForm" [formGroup]="sampleForm" (ngSubmit)="onSubmit()">
    <mat-card>
      <mat-card-header
        ><mat-card-title>{{ title }}</mat-card-title></mat-card-header
      >
      <mat-card-content>
        <div class="row flex" *ngFor="let info of informations">
          <div class="col flex-50">
            <mat-form-field>
              <input
                matInput
                type="number"
                placeholder="{{ info['display_' + locale] }}"
                formControlName="{{ info.fieldName }}"
              />
            </mat-form-field>
          </div>
          <div class="helptext flex-50 col" *ngIf="helptexts">
            <mat-icon class="material-icons">info_outlined</mat-icon>
            <div [innerHtml]="helptexts[info.fieldName]"></div>
          </div>
        </div>
        <div class="row" formArrayName="points">
          <mat-card-title style="font-size: medium"><p
            i18n="measurement points@@measurement_points">Measurement points
          </p>
            <mat-card-actions>
              <button mat-flat-button color="primary" (click)="addPoint()" i18n="add measurement point caps@@add_measurement_point">
                ADD MEASUREMENT POINT
              </button>
            </mat-card-actions>
          </mat-card-title>
          <div *ngFor="let point of points.controls; let i = index">
            <div [formGroupName]="i">
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  i18n-placeholder="latitude@@latitude"
                  placeholder="Latitude"
                  formControlName="latitude"
                />
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  i18n-placeholder="longitude@@longitude"
                  placeholder="Longitude"
                  formControlName="longitude"
                />
              </mat-form-field>
              <label
                ><button mat-flat-button color="warn" (click)="deletePoint(i)" i18n="delete@@delete">
                  Delete
                </button>
              </label>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>
