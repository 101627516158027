<div *ngIf="data && informations">
  <app-form
    [informations]="informations"
    [helptexts]="helptexts"
    [data]="data"
    (dataSubmitted)="onData($event)"
  ></app-form>
  <div *ngIf="error">
    <mat-error>{{ error }}</mat-error>
  </div>
</div>
