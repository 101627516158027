import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormsSampleService {
  private endpoint = `${environment.api}/sample-charts`;

  private mpEndpoint = `${environment.api}/measurement-points`;

  constructor(private http: HttpClient) {}

  fetchSample(id: number) {
    return this.http.get(`${this.endpoint}/${id}`);
  }

  createSample(carrier: any, name: string) {
    return this.http.post(`${this.endpoint}`, {});
  }

  createMeasurementPoint(sampleChartId: number): Observable<any> {
    return this.http.post(`${this.mpEndpoint}`, {
      sample_chart: sampleChartId,
    });
  }

  deleteMeasurementPoint(pointId: number): Observable<any> {
    return this.http.delete(`${this.mpEndpoint}/${pointId}`);
  }

  // you get this information from the strapi realtions
  private getCarrierName(name: string): string {
    const carrierassignments: { [key: string]: string } = {
      air_quality: 'air_quality_aq',
      workspace_air_quality: 'air_quality_waq',
      radiation: 'air_quality_radiation',
      water_quality_effluent: 'effluent_quality',
      surface_quality: 'water_quality_surface',
      groundwater_quality: 'water_quality_groundwater',
      outside_noise: 'noise_co_2_outside',
      workplace_noise: 'noise_co_2_workplace',
    };
    return carrierassignments[name];
  }
}
