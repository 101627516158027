import { JsonPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, zip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from './user';
import { UserRole } from './user-role.enum';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private dataSource = new BehaviorSubject<{
    companies: any[];
    projects: any[];
  }>({
    companies: [],
    projects: [],
  });

  private user: User | undefined;

  public data = this.dataSource.asObservable();

  private projectSource = new BehaviorSubject<any>(undefined);

  public selectedProject = this.projectSource.asObservable();

  private yearSource = new BehaviorSubject<any>(undefined);

  public selectedYear = this.yearSource.asObservable();

  constructor(private http: HttpClient) {}

  public setProject(project: any) {
    this.projectSource.next(project);
  }

  public setYear(year: any) {
    this.yearSource.next(year);
  }

  public loadDataForUser(user?: User) {
    if (user) {
      this.user = user;
    }
    if (!this.user) return;
    switch (this.user.role.name) {
      case UserRole.ADMIN:
        this.loadAllCompaniesAndProjects();
        break;
      case UserRole.NGO:
        this.loadDataForCountry();
        break;
      case UserRole.COMPANY:
        this.loadDataForCompany();
        break;
      case UserRole.KOBO:
        break;
    }
  }

  private loadDataForCompany() {
    if (!this.user) return;
    const companyId = this.getCompanyId(this.user);
    if (companyId === null) return;
    zip(
      this.http.get(`${environment.api}/projects?company.id=${companyId}`),
      this.http.get(`${environment.api}/companies?id=${companyId}`)
    ).subscribe((data: any[]) => {
      const value = this.dataSource.value;
      value.projects = data[0];
      console.log("value.projects", value.projects);
      //value.projects = value.projects.filter((project:any)=>project.)
      value.companies = data[1];
      this.dataSource.next(value);
    });
  }

  private loadDataForCountry() {
    if (!this.user) return;
    const countryId = this.getCountryId(this.user);
    if (countryId === null) return;
    zip(
      this.http.get(`${environment.api}/projects?country.id=${countryId}`),
      this.http.get(
        `${environment.api}/companies?projects.country=${countryId}`
      )
    ).subscribe((data: any[]) => {
      const value = this.dataSource.value;
      value.projects = data[0];
      console.log("value.projects", value.projects);

      value.companies = data[1];
      this.dataSource.next(value);
    });
  }

  private getCountryId(user: User) {
    if (user.country === null) return null;
    if (typeof user.country === 'number') return user.country;
    return user.country.id;
  }

  private getCompanyId(user: User) {
    if (user.company === null) return null;
    if (typeof user.company === 'number') return user.company;
    return user.company.id;
  }

  private reloadDataForUser() {
    if (!this.user) return;
    this.loadDataForUser(this.user);
  }

  public reloadData() {
    this.reloadDataForUser();
  }

  private loadAllCompaniesAndProjects() {
    zip(
      this.http.get(`${environment.api}/projects`),
      this.http.get(`${environment.api}/companies`)
    ).subscribe((data: any[]) => {
      const value = this.dataSource.value;
      value.projects = data[0];
      console.log("value.projects", value.projects);

      value.companies = data[1];
      this.dataSource.next(value);
    });
  }

  createNewProject(user: User) {
    const newProject: { name: string; company?: number; country?: number } = {
      name: 'New Project',
    };
    console.log(user);
    if (user.role.name === 'company')
      newProject.company = this.getCompanyId(user) || undefined;
    if (user.role.name === 'ngo')
      newProject.country = this.getCountryId(user) || undefined;
    return this.http.post<any>(`${environment.api}/projects`, newProject).pipe(
      tap((p) => {
        this.loadDataForUser();
      })
    );
  }

  createNewCompany() {
    return this.http
      .post<any>(`${environment.api}/companies`, { name: 'New Company' })
      .pipe(
        tap(() => {
          this.loadDataForUser();
        })
      );
  }

  createNewYear(project: any, year: number) {
    return this.http
      .post<any>(`${environment.api}/data-years`, {
        project: project.id,
        year: year,
        uniqueKey: `${project.id}/${year}`,
        name: `${year} ${project.name}`,
      })
      .pipe(
        tap(() => {
          this.loadDataForUser();
        })
      );
  }

  getProjectId() {
    return this.projectSource.value.id;
  }
  getProject() {
    return this.projectSource.value;
  }
}
