import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CompanyFormsComponent } from './company-forms/company-forms.component';
import { EducationHealthFormsComponent } from './education-health-forms/education-health-forms.component';
import { EnvironmentalFormsComponent } from './environmental-forms/environmental-forms.component';
import { LabelEditorComponent } from './kobo/label-editor/label-editor.component';
import { KoboFormsComponent } from './kobo/kobo-forms/kobo-forms.component';
import { KoboImporterComponent } from './kobo/kobo-importer/kobo-importer.component';
import { LoginComponent } from './login/login.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { CompanyGuard } from './shared/guards/company.guard';
import { KoboGuard } from './shared/guards/kobo.guard';
import { SocialCapitalFormsComponent } from './social-capital-forms/social-capital-forms.component';
import { TabFormsComponent } from './tab-forms/tab-forms.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { WelcomeComponent } from './welcome/welcome.component';

const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },

  {
    path: 'welcome',
    component: WelcomeComponent,
  },
  {
    path: 'settings',
    component: UserSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'logout',
    component: WelcomeComponent,
  },

  {
    path: 'company/:id',
    component: CompanyDetailsComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'project/:id',
    component: ProjectDetailsComponent,
    canActivate: [AuthGuard, CompanyGuard],
  },
  {
    path: 'project/:id/year/:year',
    component: TabFormsComponent,
    canActivate: [AuthGuard, CompanyGuard],
    children: [
      {
        path: 'company',
        component: CompanyFormsComponent,
      },
      {
        path: 'environment',
        component: EnvironmentalFormsComponent,
      },
      {
        path: 'social-capital',
        component: SocialCapitalFormsComponent,
      },
      {
        path: 'education-health',
        component: EducationHealthFormsComponent,
      },
    ],
  },
  {
    path: 'kobo/import',
    component: KoboImporterComponent,
    canActivate: [AuthGuard, KoboGuard],
  },
  {
    path: 'kobo/forms',
    component: KoboFormsComponent,
    canActivate: [AuthGuard, KoboGuard],
  },
  {
    path: 'kobo/labeleditor',
    component: LabelEditorComponent,
    canActivate: [AuthGuard, KoboGuard],
  },
  {
    path: '**',
    redirectTo: 'welcome',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
