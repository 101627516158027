import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { KoboFieldService } from 'src/app/kobo/kobo-field.service';
import {LocaleService} from "../../../../../locale.service";

@Component({
  selector: 'app-kobo-combination-condition-dialog',
  templateUrl: './kobo-combination-condition-dialog.component.html',
  styleUrls: ['./kobo-combination-condition-dialog.component.css'],
})
export class KoboCombinationConditionDialogComponent implements OnInit {
  condition: any;

  formGroup: FormGroup | undefined;

  surveyFieldOptions!: Observable<string[]>;

  sharedFields: any[] = [];

  allFields: any[] = [];

  /**Fill these during condition valid check */
  invalidFields: any[] = [];

  notSharedFields: any[] = [];

  constructor(
    private fb: FormBuilder,
    private fieldService: KoboFieldService,
    public dialogRef: MatDialogRef<KoboCombinationConditionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()

    this.formGroup = this.fb.group({
      type: 'statement',
      field: '',
      relation: 'eq',
      value: '',
    });
    this.formGroup.valueChanges.subscribe((values) => {
      this.condition = {
        type: values.type,
        surveyFieldName: values.field,
        statement: {
          [values.relation]: values.value,
        },
      };
    });
    this.surveyFieldOptions = this.formGroup.valueChanges.pipe(
      startWith(''),
      map((value: any) => this._filter(value))
    );
    this.fieldService.fieldObs.subscribe((fields) => {
      this.allFields = fields.allFields;
      this.sharedFields = fields.sharedFields;
    });
  }

  private _filter(value: any): string[] {
    if (value) {
      const filterValue = value.field.toLowerCase();
      return this.sharedFields.filter((field: string) =>
        field.toLowerCase().includes(filterValue)
      );
    }
    return this.sharedFields;
  }

  onCancel() {
    this.dialogRef.close();
  }

  toggleSelection(value: any) {
    this.condition = {
      type: value.value,
    };
  }

  selectCombination(value: any) {
    if (this.condition && this.condition.type === 'combination') {
      this.condition['bool'] = value.value;
      this.condition['conditions'] = [];
    }
  }

  isConditionValid() {
    if (!this.condition || !this.condition.type) return false;
    if (this.condition.type === 'combination') return this.isCombinationValid();
    if (this.condition.type === 'statement') return this.isStatementValid();
    return false;
  }

  isCombinationValid() {
    return this.condition.bool === 'and' || 
    this.condition.bool === 'or' || 
    this.condition.bool === 'average' ||
    this.condition.bool === 'head+children' ||
    this.condition.bool === 'one-of-children';
  }

  /** add more validityChecks here */
  isStatementValid() {
    if (!this.condition.surveyFieldName) return false;
    const key = Object.keys(this.condition.statement);
    if (!['eq', 'gt', 'gte', 'lt', 'lte', 'ne', 'in', 'notIn'].includes(key[0]))
      return false;
    if (!this.condition.statement[key[0]]) return false;
    return true;
  }
}
