import { Component, OnInit } from '@angular/core';
import {EducationalDataService} from "./educational-data.service";
import {HelptextService} from "../shared/helptext.service";
import {isArray} from "rxjs/internal-compatibility";
import {MatSnackBar} from "@angular/material/snack-bar";
import {map, switchMap, tap} from "rxjs/operators";
import {combineLatest, of} from "rxjs";
import {DataService} from "../shared/data.service";
import {Validators} from "@angular/forms";
import {LocaleService} from "../locale.service";


@Component({
  selector: 'app-educational-data',
  templateUrl: './educational-data.component.html',
  styleUrls: ['./educational-data.component.css'],
})
export class EducationalDataComponent implements OnInit {
  informations = [
    {
      fieldName: 'id',
      display: 'none',
      type: 'n/a',
    },
    {
      fieldName: 'data_year',
      display: 'none',
      type: 'n/a',
    },
    {
      fieldName: 'district',
      display: 'District',
      type: 'string',
    },
    {
      fieldName: 'kids_in_primary_age',
      display: 'primary enrolment rate %',
      type: 'number',
    },
    {
      fieldName: 'completion_rate_in_primary_stage',
      display: 'primary completion rate %',
      type: 'number',
    },
    {
      fieldName: 'pupils_per_teacher',
      display: 'primary pupils per teacher',
      type: 'number',
    },
    {
      fieldName: 'enrolment_rate_in_secondary_stage',
      display: 'secondary enrolment rate %',
      type: 'number',
    },
    {
      fieldName: 'completion_rate_in_secondary_stage',
      display: 'secondary completion rate % ',
      type: 'number',
    },
  ];
  helptexts: any[] = [];
  districts: any[] = [];

  constructor(
    private eduDataService: EducationalDataService,
    private helptextService: HelptextService,
    private snackbar: MatSnackBar,
    private dataservice: DataService,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.helptextService
      .getHelptexts('helptexts-educational-data')
      .subscribe((res) => (this.helptexts = res));
    combineLatest([
      this.dataservice.selectedYear,
      this.eduDataService.getEducationalData(),
    ])
      .pipe(
        switchMap(([year, edudata]) => {
          if (edudata.length < 1) {
            return of({
              data_year: year,
              kids_in_primary_age: '',
              completion_rate_in_primary_stage: '',
              pupils_per_teacher: '',
              enrolment_rate_in_secondary_stage: '',
              completion_rate_in_secondary_stage: '',
              district: '',
            });
          }
          return of(edudata);
        })
      )
      .subscribe((res) => {
        isArray(res) ? (this.districts = res) : (this.districts = [res]);
      });
  }

  onData(formData: any) {
    if (!this.districts) throw new Error('Missing Data');
    if (formData.id == null) {
      this.eduDataService.post(formData).subscribe((res) => {
        this.snackbar.open('Values updated successfully', undefined, {
          duration: 2500,
        });
        this.districts.pop();
        this.districts.push(res);
      });
    } else {
      this.eduDataService.updateData(formData).subscribe((data) => {
        this.snackbar.open('Values updated successfully', undefined, {
          duration: 2500,
        });
        formData = data;
      });
    }
  }

  addNewRegionalCompletionRate() {
    this.dataservice.selectedYear.subscribe((year) => {
      this.districts.push({
        data_year: year,
        kids_in_primary_age: '',
        completion_rate_in_primary_stage: '',
        pupils_per_teacher: '',
        enrolment_rate_in_secondary_stage: '',
        completion_rate_in_secondary_stage: '',
        district: '',
      });
    });
  }

  deleteRegionalCompletionRateDistrict($event: any) {
    this.eduDataService.delete($event.id).subscribe((res: any) => {
      this.districts = this.districts.filter((distr) => distr.id != res.id);
      this.snackbar.open('Deleted successfully', undefined, { duration: 2500 });
    });
  }
}
