import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-survey-modifier-dialog',
  template: `
    <h2 mat-dialog-title>Modify Formula</h2>
    <mat-dialog-content>
      {{ dialogText }}
      <form [formGroup]="myForm">
        <mat-form-field>
          <mat-label i18n="name@@name">Name</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button color="primary" (click)="confirm()">Confirm</button>
    </mat-dialog-actions>
  `,
})
export class SurveyModifierDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SurveyModifierDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dialogText: string }
  ) {
    this.myForm = new FormGroup({
      name: new FormControl(),
    });
  }
  myForm: FormGroup;

  get dialogText(): string {
    return this.data.dialogText;
  }

  confirm(): void {
    const newname = this.myForm.value.name;
    console.log(newname)
    if (newname) {
      this.dialogRef.close(newname);
    }  
  }
}
