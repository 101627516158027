<h2 i18n="add new field@@add_new_field" >Add new field</h2>
<form *ngIf="formGroup" [formGroup]="formGroup">
  <mat-form-field style="width: 45%">
    <input matInput placeholder="Field" formControlName="fieldName" />
  </mat-form-field>
</form>
<div mat-dialog-actions>
  <button
    mat-button
    [disabled]="!(fieldName && fieldName.length > 0)"
    [mat-dialog-close]="fieldName"
  >
    Submit
  </button>
  <button mat-button color="accent" (click)="onCancel()">Cancel</button>
</div>
