<div>
  <h3>Assign Surveys</h3>
  <div *ngIf="surveys">
    <div *ngFor="let survey of surveys">
      {{ survey.surveyName }}
      <label *ngIf="isSurveyAssignedInOtherFormula(survey)"
             i18n="assigned in formula@@assigned_in_formula"
        >Assigned in formula:
        {{ getAssignedFormula(survey).formulaName }}</label
      >
      <div *ngIf="!isSurveyAssignedInOtherFormula(survey)">
        <button
          mat-raised-button
          [disabled]="isSurveyInAssignedStack(survey)"
          (click)="assignSurvey(survey)"
          i18n="assign@@assign"
        >
          Assign
        </button>
        <button
          mat-raised-button
          [disabled]="!isSurveyInAssignedStack(survey)"
          (click)="unassignSurvey(survey)"
          i18n="unassign@@unassign"
        >
          Unassign
        </button>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button
    *ngIf="assignedSurveys"
    mat-button
    [disabled]="assignedSurveys.length === 0"
    [mat-dialog-close]="assignedSurveys"
    i18n="submit@@submit"
  >
    Submit
  </button>
  <button mat-button color="accent" (click)="onCancel()" i18n="cancel@@cancel">Cancel</button>
</div>
