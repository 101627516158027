<mat-card-content>
  <div class="row">
    <div class="col">
      <mat-tab-group>
        <mat-tab label="Local Procurement" i18n-label="@@local procurement@@local_procurement">
          <app-local-procurement></app-local-procurement>
        </mat-tab>
        <mat-tab label="Payments to government" i18n-label="payments to government@@payments_to_government">
          <app-company-payments-to-gov></app-company-payments-to-gov>
        </mat-tab>
        <mat-tab label="Social Investments" i18n-label="social investments@@social_investments">
          <app-social-investments></app-social-investments>
        </mat-tab>
        <mat-tab label="Company Employment" i18n-label="company employment@@company_employment">
          <app-company-employment></app-company-employment>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</mat-card-content>
