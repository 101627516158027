import { Component, Input, OnInit } from '@angular/core';
import {LocaleService} from "../../../../locale.service";

type DisplayData = {
  field: string;
  type: 'decimal' | 'list';
  value?: number;
  values?: DisplayData[];
};

@Component({
  selector: 'app-demo-results',
  templateUrl: './demo-results.component.html',
  styleUrls: ['./demo-results.component.css'],
})
export class DemoResultsComponent implements OnInit {
  @Input() results: any | undefined;

  displayData: DisplayData[] | undefined;

  get fields() {
    return Object.keys(this.results.fields);
  }

  constructor(private localeService: LocaleService) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    if (this.results) {
      this.createDisplayData();
    }
  }

  private createDisplayData() {
    this.displayData = [];
    for (const key of Object.keys(this.results.fields)) {
      if (this.results.fields[key] instanceof Object) {
        const entries: DisplayData[] = [];
        for (const v of this.results.fields[key]) {
          entries.push({
            field: v.name,
            type: 'decimal',
            value: v.value,
          });
        }
        this.displayData.push({
          field: key,
          values: entries,
          type: 'list',
        });
      } else {
        this.displayData.push({
          field: key,
          value: this.results.fields[key],
          type: 'decimal',
        });
      }
    }
  }

  isFieldDecimal(value: any) {
    return value >= 0;
  }
}
