import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSliderModule } from '@angular/material/slider';
import { MatNativeDateModule } from '@angular/material/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { NavigationComponent } from './navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { WelcomeComponent } from './welcome/welcome.component';
import { YearsComponent } from './years/years.component';
import { MatTreeModule } from '@angular/material/tree';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { FormComponent } from './shared/form/form.component';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './shared/interceptors';
import { TabFormsComponent } from './tab-forms/tab-forms.component';
import { CompanyFormsComponent } from './company-forms/company-forms.component';
import { EnvironmentalFormsComponent } from './environmental-forms/environmental-forms.component';
import { SocialCapitalFormsComponent } from './social-capital-forms/social-capital-forms.component';
import { EducationHealthFormsComponent } from './education-health-forms/education-health-forms.component';
import { CompanyPaymentsToGovComponent } from './company-payments-to-gov/company-payments-to-gov.component';

import { UserSettingsComponent } from './user-settings/user-settings.component';
import { LocalProcurementComponent } from './local-procurement/local-procurement.component';
import { SocialInvestmentsComponent } from './social-investments/social-investments.component';
import { AirQualityComponent } from './environmental-forms/air-quality/air-quality.component';
import { WaterQualityComponent } from './environmental-forms/water-quality/water-quality.component';
import { LandForestComponent } from './environmental-forms/land-forest/land-forest.component';
import { NoiseCo2Component } from './environmental-forms/noise-co2/noise-co2.component';
import { FormsSampleComponent } from './shared/forms-sample/forms-sample.component';
import { CompanyEmploymentComponent } from './company-employment/company-employment.component';
import { HealthDataComponent } from './health-data/health-data.component';
import { EducationalDataComponent } from './educational-data/educational-data.component';
import { MetadataComponent } from './metadata/metadata.component';
import { MetadataDialogComponent } from './metadata/metadata-dialog/metadata-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { KoboLoaderComponent } from './kobo/kobo-loader/kobo-loader.component';
import { KoboFormsComponent } from './kobo/kobo-forms/kobo-forms.component';
import { LabelEditorComponent } from './kobo/label-editor/label-editor.component';
import { LabelEditorDetailComponent } from './kobo/kobo-forms/label-editor-detail/label-editor-detail.component';
import { KoboImporterComponent } from './kobo/kobo-importer/kobo-importer.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { CommunityInteractionsComponent } from './social-capital-forms/community-interactions/community-interactions.component';
import { FormEditorComponent } from './kobo/kobo-forms/form-editor/form-editor.component';
import { KoboCombinationComponent } from './kobo/kobo-forms/form-editor/kobo-combination/kobo-combination.component';
import { KoboStatementComponent } from './kobo/kobo-forms/form-editor/kobo-statement/kobo-statement.component';
import { KoboStatementDialogComponent } from './kobo/kobo-forms/form-editor/kobo-statement/kobo-statement-dialog/kobo-statement-dialog.component';
import { KoboCombinationDialogComponent } from './kobo/kobo-forms/form-editor/kobo-combination/kobo-combination-dialog/kobo-combination-dialog.component';
import { KoboCombinationConditionDialogComponent } from './kobo/kobo-forms/form-editor/kobo-combination/kobo-combination-condition-dialog/kobo-combination-condition-dialog.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NewFormulaDialogComponent } from './kobo/kobo-forms/new-formula-dialog/new-formula-dialog.component';
import { DialogAssignSurveysComponent } from './kobo/kobo-forms/form-editor/dialog-assign-surveys/dialog-assign-surveys.component';
import { AddNewYearComponent } from './navigation/add-new-year/add-new-year.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DialogAssignRepeatFieldComponent } from './kobo/kobo-forms/form-editor/dialog-assign-repeat-field/dialog-assign-repeat-field.component';
import { DialogNewFieldComponent } from './kobo/kobo-forms/form-editor/dialog-new-field/dialog-new-field.component';
import { FormFieldComponent } from './kobo/kobo-forms/form-editor/form-field/form-field.component';
import { FormFieldLabelComponent } from './kobo/kobo-forms/label-editor-detail/form-field-label/form-field-label.component';
import { FormFieldAggregationComponent } from './kobo/kobo-forms/form-editor/form-field-aggregation/form-field-aggregation.component';
import { DemoResultsComponent } from './kobo/kobo-forms/form-editor/demo-results/demo-results.component';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AddFormulaAssignmentDialogComponent } from './add-formula-assignment-dialog/add-formula-assignment-dialog.component';
import { SurveyModifierDialogComponent } from './survey-modifier-dialog/survey-modifier-dialog.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CompanyDetailsComponent,
    NavigationComponent,
    WelcomeComponent,
    YearsComponent,
    ProjectDetailsComponent,
    FormComponent,
    TabFormsComponent,
    CompanyFormsComponent,
    CompanyEmploymentComponent,
    EnvironmentalFormsComponent,
    SocialCapitalFormsComponent,
    EducationHealthFormsComponent,
    CompanyPaymentsToGovComponent,
    UserSettingsComponent,
    AirQualityComponent,
    WaterQualityComponent,
    LandForestComponent,
    NoiseCo2Component,
    FormsSampleComponent,
    LocalProcurementComponent,
    SocialInvestmentsComponent,
    HealthDataComponent,
    EducationalDataComponent,
    KoboLoaderComponent,
    KoboFormsComponent,
    LabelEditorComponent,
    LabelEditorDetailComponent,
    KoboImporterComponent,
    MetadataComponent,
    MetadataDialogComponent,
    CommunityInteractionsComponent,
    FormEditorComponent,
    KoboCombinationComponent,
    KoboStatementComponent,
    KoboStatementDialogComponent,
    KoboCombinationDialogComponent,
    KoboCombinationConditionDialogComponent,
    NewFormulaDialogComponent,
    DialogAssignSurveysComponent,
    AddNewYearComponent,
    DialogAssignRepeatFieldComponent,
    DialogNewFieldComponent,
    FormFieldComponent,
    FormFieldLabelComponent,
    FormFieldAggregationComponent,
    DemoResultsComponent,
    ConfirmDialogComponent,
    AddFormulaAssignmentDialogComponent,
    SurveyModifierDialogComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatCheckboxModule,
    MatTabsModule,
    MatChipsModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatCardModule,
    ReactiveFormsModule,
    LayoutModule,
    MatToolbarModule,
    MatMenuModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatMomentDateModule,
    MatIconModule,
    MatButtonToggleModule,
    MatListModule,
    MatTreeModule,
    MatDialogModule,
    MatSnackBarModule,
  ],
  providers: [
    httpInterceptorProviders,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
