<div
  *ngIf="formField && fieldindex !== undefined"
  style="
    display: flex;
    margin-bottom: 1vh;
    padding-top: 1vh;
    border-top: 1px solid black;
  "
>
  <div style="width: 20%; margin-right: 2vw">
    <p>
      {{ formField?.name }}
    </p>
  </div>
  <!-- we keep formfield.type due to backwards compatibility-->
  <div>
    <div>Labels:</div>
    <div *ngIf="true">
        <div style="margin-left: 1vw; margin-top: 0.5vh">
          <mat-form-field appearance="fill">
            <textarea 
              (change)="changeLabels($event, formField?.name)" 
              matInput 
              rows="4" 
              placeholder="Enter your labels like this: {maritalstatus0: 'unmarried', maritalstatus1: 'married'}"
              value={{formField?.labels}}
            ></textarea>
          </mat-form-field>          
        </div>
    </div>
  </div>
</div>