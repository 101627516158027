import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../shared/data.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  BASE_URL = `${environment.api}/companies`;

  constructor(private http: HttpClient, private navService: DataService) {}

  fetchCompany(id: number) {
    return this.http.get(`${this.BASE_URL}/${id}`);
  }

  updateData(data: any) {
    return this.http.put(`${this.BASE_URL}/${data.id}`, data).pipe(
      tap(() => {
        this.navService.reloadData();
      })
    );
  }

  getAllCompanies() {
    return this.http.get<any[]>(`${this.BASE_URL}`);
  }
}
