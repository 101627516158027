import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import { MetadataService } from './metadata.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MetadataDialogComponent } from './metadata-dialog/metadata-dialog.component';
import { switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { isArray } from 'rxjs/internal-compatibility';
import { Metadata } from './metadata';

@Component({
  selector: 'app-metadata',
  templateUrl: './metadata.component.html',
  styleUrls: ['./metadata.component.css'],
})
export class MetadataComponent {
  @Input() graphName: string = 'n/a';

  @Input() displayString?: string;

  companyId: number = 0;

  projectId: number = 0;

  metadata?: Metadata;

  constructor(
    private dataService: DataService,
    private metadataService: MetadataService,
    private dialog: MatDialog
  ) {}

  openMetadataDialog() {
    this.companyId = this.dataService.getProject().company.id;
    this.projectId = this.dataService.getProject().id;
    this.metadataService
      .getMetadata(this.graphName, this.projectId)
      .pipe(
        tap((res: Metadata) => {
          this.metadata = res;
        }),
        switchMap(() =>
          this.dialog
            .open(MetadataDialogComponent, { data: this.metadata })
            .afterClosed()
        ),
        //tap(((res) => {console.log('New entry' ,res)}),
        switchMap((result) => {
          console.log('Foobar', result);
          if (result.project === null)
            return this.metadataService.postNewAlteredDefault(
              result,
              this.projectId
            );
          return this.metadataService.update(result);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.metadata = res;
        }
      });
  }
}
