<app-metadata [graphName]="'outside-air-quality'" [displayString]="'Outside Air Quality Metadata'"></app-metadata>
<app-metadata [graphName]="'workplace-air-quality'" [displayString]="'Workplace Air Quality Metadata'"></app-metadata>
<app-metadata [graphName]="'radiation'" [displayString]="'Radiation Metadata'"></app-metadata>
<mat-card *ngIf="sampleInformations && data">
  <mat-card-content>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!(activateSaveButton && hasValidData())"
      (click)="updateData()"
      i18n="save@@save">
      Save
    </button>
    <button
      mat-raised-button
      style="margin-left: 5em"
      color="warn"
      (click)="resetForms()"
      i18n="cancel@@cancel">
      Cancel
    </button>
    <div *ngFor="let info of sampleInformations">
      <div class="row">
        <div class="col">
          <app-forms-sample
            [title]="info['display_' + locale]"
            [sampleCarrier]="data"
            [sampleName]="info.fieldName"
            [helptexts]="helptexts[0]"
            (formUpdates)="onFormUpdates(info.fieldName, $event)"
          ></app-forms-sample>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
