import { Component, OnInit } from '@angular/core';
import { Information } from 'src/app/shared/information';
import { LandForestService } from './land-forest.service';
import { HelptextService } from '../../shared/helptext.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {LocaleService} from "../../locale.service";
import {Locale} from "../../locale";

@Component({
  selector: 'app-land-forest',
  templateUrl: './land-forest.component.html',
  styleUrls: ['./land-forest.component.css'],
})
export class LandForestComponent implements OnInit {
  informations: any[] = [
    {
      fieldName: '',
      display_en: 'Land and Forest',
      display_es: 'Tierra y bosque',
      display_fr: 'Terres et forêts',
      display_pt: 'Terra e floresta',
      type: 'title',
    },
    {
      fieldName: 'landConvertedMining',
      display_en: 'Land converted for Mining',
      display_es: 'Terrenos convertidos para la minería',
      display_fr: 'Terres converties pour l\'exploitation minière',
      display_pt: 'Terra convertida para Mineração',
      type: 'number',
    },
    {
      fieldName: 'topsoiling',
      display_en: 'Topsoiling',
      display_es: 'Topsoiling',
      display_fr: 'Terrassement',
      display_pt: 'Sujeira de topo',
      type: 'number' },
    {
      fieldName: 'landRehabilitation',
      display_en: 'Land rehabilitation',
      display_es: 'Rehabilitación de terrenos',
      display_fr: 'Réhabilitation des terres',
      display_pt: 'Reabilitação de terrenos',
      type: 'number',
    },
    {
      fieldName: 'deforestation',
      display_en: 'Deforestation',
      display_es: 'Deforestación',
      display_fr: 'Déforestation',
      display_pt: 'Desmatamento',
      type: 'number'
    },
    {
      fieldName: 'afforastationCompany',
      display_en: 'Afforestation through company',
      display_es: 'Reforestación a través de la empresa',
      display_fr: 'Boisement par l\'entreprise',
      display_pt: 'Arborização através da empresa',
      type: 'number',
    },
  ];

  locale = Locale.EN

  private obsoleteFields = ['created_at', 'updated_at', 'data_year'];

  data: any | undefined;
  helptexts: any = [];
  constructor(
    private dataService: LandForestService,
    private helptextService: HelptextService,
    private snackbar: MatSnackBar,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.localeService.locale.subscribe(loc => this.locale = loc)
    this.helptextService
      .getHelptexts('helptexts-land-and-forests')
      .subscribe((res) => {
        this.helptexts = res;
      });
    this.dataService.fetchData().subscribe((data) => {
      this.data = data;
    });
  }
  // TODO Make helper Functions for this
  onSubmit(data: any) {
    for (const key of this.informations) {
      let submittedValue = data[key.fieldName];
      if (key.type === 'number') {
        if (submittedValue === '' || isNaN(submittedValue)) {
          submittedValue = null;
        }
        this.data[key.fieldName] = submittedValue;
      }
    }
    for (const obsoleField of this.obsoleteFields) {
      delete this.data[obsoleField];
    }
    this.dataService.updateData(this.data).subscribe((data) => {
      this.data = data;
      this.snackbar.open('Values updated successfully', undefined, {duration: 2500})
    });
  }
}
