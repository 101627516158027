import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import { map, switchMap } from 'rxjs/operators';
import { isArray } from 'rxjs/internal-compatibility';
import { HelptextService } from '../shared/helptext.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FCUServiceConfig } from '../shared/types/fcu-service-config';
import { FetchCreateUpdateService } from '../shared/fetch-create-update.service';
import { Helpers } from '../shared/helpers';
import {LocaleService} from "../locale.service";

@Component({
  selector: 'app-company-employment',
  templateUrl: './company-employment.component.html',
  styleUrls: ['./company-employment.component.css'],
})
export class CompanyEmploymentComponent implements OnInit {
  private fcuConfig: FCUServiceConfig = {
    dyName: 'company_employment',
    endpoint: 'company-employments',
  };

  informations = [
    {
      fieldName: 'id',
      display_en: 'none',
      type: 'none',
    },
    {
      fieldName: '',
      display_en: 'Management',
      type: 'title',
    },
    {
      fieldName: 'male_local_management',
      display_en: 'Male Local Management',
      type: 'number',
    },
    {
      fieldName: 'female_local_management',
      display_en: 'Female Local Management',
      type: 'number',
    },
    {
      fieldName: 'male_national_management',
      display_en: 'Male National Management',
      type: 'number',
    },
    {
      fieldName: 'female_national_management',
      display_en: 'Female National Management',
      type: 'number',
    },
    {
      fieldName: 'male_international_management',
      display_en: 'Male International Management',
      type: 'number',
    },
    {
      fieldName: 'female_international_management',
      display_en: 'Female International Management',
      type: 'number',
    },
    {
      fieldName: '',
      display_en: 'Skilled',
      type: 'title',
    },
    {
      fieldName: 'male_local_skilled',
      display_en: 'Male Local Skilled',
      type: 'number',
    },
    {
      fieldName: 'female_local_skilled',
      display_en: 'Female Local Skilled',
      type: 'number',
    },
    {
      fieldName: 'male_national_skilled',
      display_en: 'Male National Skilled',
      type: 'number',
    },
    {
      fieldName: 'female_national_skilled',
      display_en: 'Female National Skilled',
      type: 'number',
    },
    {
      fieldName: 'male_international_skilled',
      display_en: 'Male International Skilled',
      type: 'number',
    },
    {
      fieldName: 'female_international_skilled',
      display_en: 'Female International Skilled',
      type: 'number',
    },
    {
      fieldName: '',
      display_en: 'Semiskilled',
      type: 'title',
    },
    {
      fieldName: 'male_local_semiskilled',
      display_en: 'Male Local Semiskilled',
      type: 'number',
    },
    {
      fieldName: 'female_local_semiskilled',
      display_en: 'Female Local Semiskilled',
      type: 'number',
    },
    {
      fieldName: 'male_national_semiskilled',
      display_en: 'Male National Semiskilled',
      type: 'number',
    },
    {
      fieldName: 'female_national_semiskilled',
      display_en: 'Female National Semiskilled',
      type: 'number',
    },
    {
      fieldName: 'male_international_semiskilled',
      display_en: 'Male International Semiskilled',
      type: 'number',
    },
    {
      fieldName: 'female_international_semiskilled',
      display_en: 'Female International Semiskilled',
      type: 'number',
    },
    {
      fieldName: '',
      display_en: 'Unskilled',
      type: 'title',
    },
    {
      fieldName: 'male_local_unskilled',
      display_en: 'Male Local Unskilled',
      type: 'number',
    },
    {
      fieldName: 'female_local_unskilled',
      display_en: 'Female Local Unskilled',
      type: 'number',
    },
    {
      fieldName: 'male_national_unskilled',
      display_en: 'Male National Unskilled',
      type: 'number',
    },
    {
      fieldName: 'female_national_unskilled',
      display_en: 'Female National Unskilled',
      type: 'number',
    },
    {
      fieldName: 'male_international_unskilled',
      display_en: 'Male International Unskilled',
      type: 'number',
    },
    {
      fieldName: 'female_international_unskilled',
      display_en: 'Female International Unskilled',
      type: 'number',
    },
  ];
  helptexts: any;
  data: any;

  constructor(
    private dataService: DataService,
    private fcuService: FetchCreateUpdateService,
    private snackbar: MatSnackBar,
    private helptextsService: HelptextService,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.helptextsService
      .getHelptexts('company-employments-helptexts')
      .subscribe((res) => {
        this.helptexts = res;
      });
    this.dataService.selectedYear
      .pipe(
        switchMap((dy) => this.fcuService.fetch(this.fcuConfig, dy)),
        map((arr) => (isArray(arr) ? arr[0] : arr))
      )
      .subscribe((employments) => {
        if (!employments)
          this.data = Helpers.createEmptyDataset(this.informations);
        else this.data = employments;
      });
  }

  onData(formData: any) {
    console.log(formData);
    if (formData.id) {
      this.fcuService
        .update(this.fcuConfig, formData)
        .subscribe((employments) => {
          this.snackbar.open(
            `Updated company employment data for ${employments.data_year.year} successfully`
          );
          this.data = employments;
        });
    } else {
      this.dataService.selectedYear
        .pipe(
          switchMap((dy) =>
            this.fcuService.create(this.fcuConfig, dy, formData)
          ),
          // @ts-ignore
          map((arr) => (isArray(arr) ? arr[0] : arr))
        )
        .subscribe((employments) => {
          this.snackbar.open(
            `Created company employment data for ${employments.data_year.year} successfully`
          );
          this.data = employments;
        });
    }
  }

}
