import { Component, OnInit } from '@angular/core';
import {LocaleService} from "../../locale.service";

@Component({
  selector: 'app-kobo-loader',
  templateUrl: './kobo-loader.component.html',
  styleUrls: ['./kobo-loader.component.css']
})
export class KoboLoaderComponent implements OnInit {

  constructor(private localeService: LocaleService) { }

  ngOnInit(): void {
    this.localeService.updateLocale()
  }

}
