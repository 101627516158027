import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogNewFieldComponent } from '../dialog-new-field/dialog-new-field.component';
import { KoboCombinationConditionDialogComponent } from '../kobo-combination/kobo-combination-condition-dialog/kobo-combination-condition-dialog.component';
import { KoboFormulaField } from '../kobo-formula-field';
import { LocaleService } from "../../../../locale.service";

@Component({
  selector: 'app-form-field-aggregation',
  templateUrl: './form-field-aggregation.component.html',
  styleUrls: ['./form-field-aggregation.component.css'],
})
export class FormFieldAggregationComponent implements OnInit {
  @Input() formField: KoboFormulaField | undefined;
  @Input() formFields: string[] | undefined;

  @Input() fieldindex: number | undefined;

  @Input() isFieldDeletable?: boolean | undefined;

  @Output() deleteFieldEvent = new EventEmitter();

  @Output() addAggregatedFields: EventEmitter<string> = new EventEmitter<string>();
  @Output() removeAggregatedFields: EventEmitter<string> = new EventEmitter<string>();

  constructor(public dialog: MatDialog,
              private localeService: LocaleService) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
  }

  private assertData() {
    if (!this.formField || this.fieldindex === undefined) {
      throw Error('Component not properly supplied with data');
    }
    return true;
  }

  openAddConditionDialog(): void {
    this.assertData();
    const dialogRef = this.dialog.open(
      KoboCombinationConditionDialogComponent,
      {
        width: '80vw',
        data: {},
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.formField!.conditions.push(result);
      }
    });
  }

  clearConditions() {
    this.assertData();
    this.formField!.conditions = [];
  }

  addNewField() {
    this.assertData();
    const dialogRef = this.dialog.open(DialogNewFieldComponent, {
      width: '80vw',
    });
    dialogRef.afterClosed().subscribe((f) => {
      if (f && f.length > 0) {
        if (!this.formField!.detailFields) this.formField!.detailFields = [];
        this.formField!.detailFields!.push({
          name: f,
          type: 'decimal',
          value: 1,
          conditions: [],
          divisor: []
        });
      }
    });
  }

  deleteField() {
    if (this.isFieldDeletable && this.isFieldDeletable === true) {
      this.deleteFieldEvent.emit(this.formField);
    }
  }

  deleteFreeField(freefieldIndex: number) {
    if (this.formField && this.formField.detailFields) {
      this.formField?.detailFields?.splice(freefieldIndex, 1);
    }
  }

  selectFormField(fieldName: string){
    this.addAggregatedFields.emit(fieldName);
  }

  unselectFormField(fieldName: string){
    this.removeAggregatedFields.emit(fieldName);
  }

  isConditionSelectedForAggregation(fieldName: string){
    if(this.formFields?.includes(fieldName)){
      return true;
    }
    return false;
  }


}
