<div
  *ngIf="formField && fieldindex !== undefined"
  style="
    display: flex;
    margin-bottom: 1vh;
    padding-top: 1vh;
    border-top: 1px solid black;
  "
>
  <div style="width: 20%; margin-right: 2vw">
    <p>
      {{ formField.name }}
    </p>
    <div *ngIf="formField.type === 'json'">
      {{ formField.type }}
      <button mat-raised-button (click)="addNewField()">Add free field</button>
    </div>
  </div>
  <!-- we keep formfield.type due to backwards compatibility-->
  <!--<div
    *ngIf="
      formField.type === 'decimal' ||
      formField.type === 'regular' ||
      formField.type === undefined
    "
  >
    <div>Conditions:</div>
    <div *ngIf="formField.conditions && formField.conditions.length > 0">
      <div *ngFor="let condition of formField.conditions">
        <div style="margin-left: 1vw; margin-top: 0.5vh">
          <app-kobo-combination
            *ngIf="condition.type === 'combination'"
            [input]="condition"
          ></app-kobo-combination>
          <app-kobo-statement
            *ngIf="condition.type === 'statement'"
            [input]="condition"
          ></app-kobo-statement>
        </div>
      </div>
    </div>
    <div *ngIf="formField.conditions && formField.conditions.length > 0">
      <button mat-raised-button color="warn" (click)="clearConditions()" i18n="clear conditions@@clear_conditions">
        Clear conditions
      </button>
    </div>
    <div *ngIf="formField.conditions && formField.conditions.length === 0">
      <button mat-button (click)="openAddConditionDialog()" i18n="@@create_new_conditions">
        Create new conditions
      </button>
    </div>
  </div>-->
  <div>
    <button
      mat-raised-button
      [disabled]="isConditionSelectedForAggregation(formField.name)"
      (click)="selectFormField(formField.name)"
      i18n="assign@@assign"
    >
      Include
    </button>
    <button
      mat-raised-button
      [disabled]="!isConditionSelectedForAggregation(formField.name)"
      (click)="unselectFormField(formField.name)"
      i18n="unassign@@unassign"
    >
      Exclude
    </button>
  </div>
  <div *ngIf="isFieldDeletable" style="padding-left: 5vw">
    <button mat-raised-button color="warn" (click)="deleteField()" i18n="@@delete_field">
      Delete Field
    </button>
  </div>
</div>
<div style="margin-left: 2.5vw" *ngIf="formField && formField.detailFields">
  <app-form-field
    [formField]="field"
    [fieldindex]="fieldIndex"
    [isFieldDeletable]="true"
    (deleteFieldEvent)="deleteFreeField($event)"
    *ngFor="let field of formField.detailFields; index as fieldIndex"
  ></app-form-field>
</div>
