import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { CompanyService } from './company.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserSettingsService } from '../user-settings/user-settings.service';
import { HelptextService } from '../shared/helptext.service';
import {LocaleService} from "../locale.service";
import { User } from '../shared/user';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css'],
})
export class CompanyDetailsComponent {
  data: { [key: string]: any } | undefined;

  informations = [
    {
      fieldName: 'id',
      display_en: 'none',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'n/a',
    },
    {
      fieldName: 'name',
      display_en: 'Company Detail',
      display_es: 'Detalle de la empresa',
      display_fr: 'Détail de l\'entreprise',
      display_pt: 'Detalhe da empresa',
      type: 'title',
    },
    {
      fieldName: 'name',
      display_en: 'Name',
      display_es: 'Nombre',
      display_fr: 'Nom',
      display_pt: 'Nome',
      type: 'string',
    },
    {
      fieldName: 'shareHolders',
      display_en: 'Shareholders',
      display_es: 'Accionistas',
      display_fr: 'Actionnaires',
      display_pt: 'Accionistas',
      type: 'string',
    },
    {
      fieldName: 'stockExchange',
      display_en: 'Stockexchange',
      display_es: 'Bolsa de Valores',
      display_fr: 'Bourse des valeurs',
      display_pt: 'Stockexchange',
      type: 'string',
    },
    {
      fieldName: 'csrPolicy',
      display_en: 'CSR Policy',
      display_es: 'Política de RSE',
      display_fr: 'Politique RSE',
      display_pt: 'Política de RSE',
      type: 'string',
    },
    {
      fieldName: 'commitment',
      display_en: 'Commitment',
      display_es: 'Compromiso',
      display_fr: 'Engagement',
      display_pt: 'Compromisso',
      type: 'string',
    },
  ];
  userinformations = [
    {
      fieldName: 'id',
      display_en: 'none',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'n/a',
    },
    {
      fieldName: 'isNew',
      display_en: 'none',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'n/a',
    },
    {
      fieldName: 'company',
      display_en: 'none',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'n/a',
    },
    {
      fieldName: 'email',
      display_en: 'E-Mail',
      display_es: 'Correo electrónico',
      display_fr: 'Courriel',
      display_pt: 'E-Mail',
      type: 'email',
    },
    {
      fieldName: 'username',
      display_en: 'Username',
      display_es: 'Nombre de usuario',
      display_fr: 'Nom d\'utilisateur',
      display_pt: 'Nome de utilizador',
      type: 'string',
    },
    {
      fieldName: 'firstname',
      display_en: 'First Name',
      display_es: 'Nombre',
      display_fr: 'Prénom',
      display_pt: 'Primeiro nome',
      type: 'string',
    },
    {
      fieldName: 'surname',
      display_en: 'Last Name',
      display_es: 'Apellido',
      display_fr: 'Nom de famille',
      display_pt: 'Sobrenome',
      type: 'string',
    },
    {
      fieldName: 'password',
      display_en: 'Password',
      display_es: 'Contraseña',
      display_fr: 'Mot de passe',
      display_pt: 'Senha',
      type: 'string',
    },
  ];

  users: any[] = [];
  companyId = 0;
  helptexts: any[] = [];

  currentUser: User | undefined;

  constructor(
    private dataService: CompanyService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private userService: UserSettingsService,
    private helptextservice: HelptextService,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.authService.getUser().subscribe((user) => {
      this.currentUser = user;
    });
    this.localeService.updateLocale()
    this.helptextservice
      .getHelptexts('helptexts-companies')
      .subscribe((res) => {
        this.helptexts = res;
      });
    this.activatedRoute.paramMap
      .pipe(
        tap((params: any) => {
          this.data = undefined;
          this.companyId = params.params.id;
        }),
        switchMap((params: any) =>
          this.dataService.fetchCompany(params.params.id)
        )
      )
      .subscribe((company) => {
        this.data = undefined;
        this.data = company;
        this.userService
          .getUsersByCompanyId(this.companyId)
          .subscribe((res: any[]) => {
            this.users = res;
          });
      });
  }
  onData(formData: any) {
    if (!this.data) throw new Error('Missing Data');
    console.log('updating');
    this.dataService.updateData(formData).subscribe((data) => {
      this.snackbar.open('Values updated Successfully', undefined, {
        duration: 2500,
      });
      this.data = data;
    });
  }

  onUserData(formData: any) {
    if (formData.isNew) {
      this.addNewUser(formData);
      return;
    }
    if (!this.data) throw new Error('Missing Data');
    this.userService.updateCompanyUser(formData).subscribe((data) => {
      this.snackbar.open('Values updated Successfully', undefined, {
        duration: 3000,
      });
      this.data = data;
    });
  }

  addNewUser(formData: any) {
    this.userService.addCompanyUser(formData).subscribe(
      (res: any) => {
        this.snackbar.open('Successfully saved new user', undefined, {
          duration: 2500,
        });
        this.users = this.users.filter((user: any) => user.isNew != true);
        this.users.push(res);
      },
      (error) => {
        if (
          error.error &&
          error.error.message &&
          error.error.message[0] &&
          error.error.message[0].messages &&
          error.error.message[0].messages[0] &&
          error.error.message[0].messages[0].message
        ) {
          this.snackbar.open(
            error.error.message[0].messages[0].message,
            undefined,
            { duration: 2500 }
          );
        }
        if (error.error.message === 'missing.password') {
          this.snackbar.open('Please specify a password', undefined, {
            duration: 2500,
          });
        }
      }
    );
  }

  private updateData(formData: { [key: string]: any }) {
    if (!this.data) throw new Error('Missing Data');
    for (const key of Object.keys(formData)) {
      if (this.data[key]) {
        if (formData[key] instanceof Date) {
          const d = formData[key] as Date;
          const date = `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;
          this.data[key] = date;
        } else {
          this.data[key] = formData[key];
        }
      }
    }
  }

  addUser() {
    this.users.push({ isNew: true, company: this.companyId, role: 6 });
  }

  deleteUser($event: any) {
    if (!$event.id) return;
    if (!$event.delete) return;
    this.userService.deleteUser($event.id).subscribe((res: any) => {
      this.snackbar.open(
        `Successfully deleted user ${$event.email}`,
        undefined,
        { duration: 2500 }
      );
      this.users = this.users.filter((user: any) => user.id != res.id);
    });
  }
}
