import { Component, OnInit } from '@angular/core';
import { UserSettingsService } from './user-settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../shared/auth.service';
import {getTableUnknownDataSourceError} from "@angular/cdk/table/table-errors";
import {LocaleService} from "../locale.service";

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css'],
})
export class UserSettingsComponent implements OnInit {
  passwordInformations = [
    {
      fieldName: 'new_password',
      display_en: 'New Password',
      display_es: 'Nueva contraseña',
      display_fr: 'Nouveau mot de passe',
      display_pt: 'Nova Palavra-passe',
      type: 'password'
    },
    {
      fieldName: 'confirm_new_password',
      display_en: 'Confirm new password',
      display_es: 'Confirmar la nueva contraseña',
      display_fr: 'Confirmer le nouveau mot de passe',
      display_pt: 'Confirmar nova palavra-passe',
      type: 'password'
    },
  ];

  emailInformations = [
    {
      fieldName: 'new_email',
      display_en: 'New E-Mail',
      display_es: 'Nuevo correo electrónico',
      display_fr: 'Nouveau courriel',
      display_pt: 'Novo E-Mail',
      type: 'email'
    },
    {
      fieldName: 'confirm_new_email',
      display_en: 'Confirm new E-Mail',
      display_es: 'Confirmar nuevo correo electrónico',
      display_fr: 'Confirmer le nouveau courriel',
      display_pt: 'Confirmar novo E-Mail',
      type: 'email'
    },
  ];
  currentUser: any;

  constructor(
    private userSettingsService: UserSettingsService,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.authService.getUser().subscribe((res: any) => {
      this.currentUser = res;
    });
    this.localeService.updateLocale()
  }

  onData($event: any) {
    if ($event.new_password !== $event.confirm_new_password) {
      this.snackbar.open("New Passwords don't match", undefined, {
        duration: 2500,
      });
      return;
    }
    if ($event.password == $event.confirm_new_password) {
      this.snackbar.open(
        "New Passwords can't be the same as the old password",
        undefined,
        { duration: 2500 }
      );
      return;
    }
    this.userSettingsService
      .updatePassword(this.currentUser, $event)
      .subscribe(() => {
        this.snackbar.open('Password changed successfully.', undefined, {
          duration: 2500,
        });
      });
  }

  onEmail($event: any) {
    if ($event.new_email !== $event.confirm_new_email) {
      this.snackbar.open("new E-Mails don't match", undefined, {
        duration: 2500,
      });
      return;
    }
    if ($event.email == $event.confirm_new_email) {
      this.snackbar.open(
        "New E-Mail can't be the same as the old E-Mail",
        undefined,
        { duration: 2500 }
      );
      return;
    }
    console.log(this.currentUser);
    this.userSettingsService
      .updateEmail(this.currentUser, $event)
      .subscribe((res) => {
        this.snackbar.open('E-Mail changed successfully', undefined, {
          duration: 2500,
        });
      });
  }
}
