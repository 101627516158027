import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormsSampleService} from './forms-sample.service';
import {Locale} from "../../locale";
import {LocaleService} from "../../locale.service";

@Component({
  selector: 'app-forms-sample',
  templateUrl: './forms-sample.component.html',
  styleUrls: ['./forms-sample.component.css'],
})
export class FormsSampleComponent implements OnInit {
  @Input() sampleName: any | undefined;

  @Input() sampleCarrier: any | undefined;

  @Input() title: string | undefined;

  @Input() helptexts: any;

  @Output() formUpdates = new EventEmitter();

  locale = Locale.EN

  data: any | undefined;

  sampleForm: FormGroup | undefined;

  pointsForm: FormArray | undefined;

  informations: any[] = [
    {
      fieldName: 'total',
      display_en: 'Total',
      display_es: 'Total',
      display_fr: 'Total',
      display_pt: 'Total',
      type: 'number'
    },
    {
      fieldName: 'above',
      display_en: 'Above',
      display_es: 'Por encima de',
      display_fr: 'Au-dessus de',
      display_pt: 'Acima de',
      type: 'number'
    },
  ];

  constructor(
    private fb: FormBuilder,
    private dataService: FormsSampleService,
    private localeService: LocaleService
  ) {
  }

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.localeService.locale.subscribe(locale => this.locale = locale)
    if (this.sampleCarrier[this.sampleName]) {
      const sample = this.sampleCarrier[this.sampleName];
      this.dataService.fetchSample(sample.id).subscribe((data) => {
        this.data = data;
        this.buildSampleForm();
      });
    } else {
      this.dataService
        .createSample(this.sampleCarrier, this.sampleName)
        .subscribe((data) => {
          this.data = data;
          this.buildSampleForm();
        });
    }
  }

  private buildSampleForm() {
    if (!this.data) throw new Error('No Data');
    this.sampleForm = this.fb.group({
      id: [this.data.id],
      total: [this.data.total],
      above: [this.data.above],
      points: this.fb.array(this.buildPointsForm()),
    });
    this.emitFormChanges();
  }

  private emitFormChanges() {
    if (!this.sampleForm) throw new Error('Should not happen');
    this.sampleForm.valueChanges.subscribe((values) => {
      this.formUpdates.emit({isValid: this.sampleForm!.valid, data: values});
    });
  }

  get points() {
    return this.sampleForm!.get('points') as FormArray;
  }

  addPoint() {
    if (!this.data) throw new Error('Got called without data');
    this.dataService.createMeasurementPoint(this.data.id).subscribe((point) => {
      this.points.push(
        this.fb.group({
          id: point.id,
          latitude: '',
          longitude: '',
        })
      );
    });
  }

  deletePoint(i: number) {
    let msg: string = 'Do you really want to delete this?'
    this.locale == Locale.PT ? msg = 'Quer mesmo apagar isto?': ''
    this.locale == Locale.ES ? msg = '¿Realmente quieres borrar esto?': ''
    this.locale == Locale.FR ? msg = 'Vous voulez vraiment effacer ça ?': ''
    if (confirm(msg)) {
      this.dataService
        .deleteMeasurementPoint(this.points.at(i).get('id')!.value)
        .subscribe(() => {
          this.points.removeAt(i);
        });
    }
  }

  private buildPointsForm() {
    const pointsGroup: any = [];
    for (const point of this.data.measurement_points) {
      pointsGroup.push(
        this.fb.group({
          id: point.id,
          latitude: [point.latitude, Validators.required],
          longitude: [point.longitude, Validators.required],
        })
      );
    }
    return pointsGroup;
  }

  public onSubmit() {
    if (!this.sampleForm) throw new Error('Wrong call');
  }
}
