import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concat, forkJoin, Observable, zip } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DataService } from 'src/app/shared/data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AirQualityService {
  private endPoint = `${environment.api}/air-qualities`;

  private dyName = 'air_quality';

  private updateSampleChartsUrl = `${environment.api}/sample-charts`;

  private updateMeasurementPointsUrl = `${environment.api}/measurement-points`;

  constructor(private http: HttpClient, private dataService: DataService) {}

  fetchData(): Observable<any> {
    return this.dataService.selectedYear.pipe(
      switchMap((dy) => {
        if (dy[this.dyName]) {
          return this.http.get(`${this.endPoint}/${dy[this.dyName]}`);
        } else {
          return this.http.post(`${this.endPoint}`, {
            data_year: dy.id,
          });
        }
      })
    );
  }

  updateData(formValues: any): Observable<any[]> {
    for (const key of Object.keys(formValues)) {
      if (formValues[key].data.id === undefined) delete formValues[key];
    }
    const splittedObjects = this.splitFormValues(formValues);
    const updateRequests: Observable<any>[] = [];
    splittedObjects.sampleCharts.forEach((sample) => {
      updateRequests.push(
        this.http.put(`${this.updateSampleChartsUrl}/${sample.id}`, sample)
      );
    });
    splittedObjects.measurementPoints.forEach((mp) => {
      updateRequests.push(
        this.http.put(`${this.updateMeasurementPointsUrl}/${mp.id}`, mp)
      );
    });
    return forkJoin(updateRequests);
  }

  private splitFormValues(formValues: any): {
    sampleCharts: any[];
    measurementPoints: any[];
  } {
    const sampleCharts: any[] = [];
    const measurementPoints: any[] = [];
    for (const key of Object.keys(formValues)) {
      if (formValues[key].data != {}) {
        if (formValues[key].data.points) {
          const points = formValues[key].data.points as Array<any>;
          delete formValues[key].data.points;
          points.forEach((p) => measurementPoints.push(p));
        }
        sampleCharts.push(formValues[key].data);
      }
    }
    return { sampleCharts, measurementPoints };
  }
}
