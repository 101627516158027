import { Component, OnInit } from '@angular/core';
import { DataService } from '../shared/data.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { isArray } from 'rxjs/internal-compatibility';
import { HelptextService } from '../shared/helptext.service';
import { FCUServiceConfig } from '../shared/types/fcu-service-config';
import { FetchCreateUpdateService } from '../shared/fetch-create-update.service';
import { Helpers } from '../shared/helpers';
import { MatSnackBar } from '@angular/material/snack-bar';
import {LocaleService} from "../locale.service";

@Component({
  selector: 'app-local-procurement',
  templateUrl: './local-procurement.component.html',
  styleUrls: ['./local-procurement.component.css'],
})
export class LocalProcurementComponent implements OnInit {
  private fcuConfig: FCUServiceConfig = {
    dyName: 'company_local_procurement',
    endpoint: 'company-local-procurements',
  };

  informations = [
    {
      fieldName: 'id',
      display_en: 'none',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'none',
    },
    {
      fieldName: '',
      display_en: 'Regional',
      display_es: 'Regional',
      display_fr: 'Régional',
      display_pt: 'Regional',
      type: 'title',
    },
    {
      fieldName: 'agricultureRegional',
      display_en: 'Agriculture',
      display_es: 'Agricultura',
      display_fr: 'Agriculture',
      display_pt: 'Agricultura',
      type: 'number',
    },
    {
      fieldName: 'miningRegional',
      display_en: 'Mining',
      display_es: 'Minería',
      display_fr: 'Exploitation minière',
      display_pt: 'Mineração',
      type: 'number',
    },
    {
      fieldName: 'manufacturingRegional',
      display_en: 'Manufacturing',
      display_es: 'Fabricación',
      display_fr: 'Fabrication',
      display_pt: 'Fabrico',
      type: 'number',
    },
    {
      fieldName: 'utilitiesRegional',
      display_en: 'Utilities',
      display_es: 'Servicios públicos',
      display_fr: 'Services publics',
      display_pt: 'Serviços públicos',
      type: 'number',
    },
    {
      fieldName: 'constructionRegional',
      display_en: 'Construction',
      display_es: 'Construcción',
      display_fr: 'Construction',
      display_pt: 'Construção',
      type: 'number',
    },
    {
      fieldName: 'servicesRegional',
      display_en: 'Services',
      display_es: 'Servicios',
      display_fr: 'Services',
      display_pt: 'Serviços',
      type: 'number',
    },
    {
      fieldName: 'transportationRegional',
      display_en: 'Transportation',
      display_es: 'Transporte',
      display_fr: 'Transport',
      display_pt: 'Transportes',
      type: 'number',
    },
    {
      fieldName: 'tradeRegional',
      display_en: 'Trade',
      display_es: 'Comercio',
      display_fr: 'Commerce',
      display_pt: 'Comércio',
      type: 'number',
    },
    {
      fieldName: 'uncategorizedRegional',
      display_en: 'Uncategorized',
      display_es: 'Sin categoría',
      display_fr: 'Non classé',
      display_pt: 'Não categorizado',
      type: 'number',
    },
    {
      fieldName: '',
      display_en: 'National',
      display_es: 'Nacional',
      display_fr: 'National',
      display_pt: 'Nacional',
      type: 'title',
    },
    {
      fieldName: 'agricultureNational',
      display_en: 'Agriculture',
      display_es: 'Agricultura',
      display_fr: 'Agriculture',
      display_pt: 'Agricultura',
      type: 'number',
    },
    {
      fieldName: 'miningNational',
      display_en: 'Mining',
      display_es: 'Minería',
      display_fr: 'Exploitation minière',
      display_pt: 'Mineração',
      type: 'number',
    },
    {
      fieldName: 'manufacturingNational',
      display_en: 'Manufacturing',
      display_es: 'Fabricación',
      display_fr: 'Fabrication',
      display_pt: 'Fabrico',
      type: 'number',
    },
    {
      fieldName: 'utilitiesNational',
      display_en: 'Utilities',
      display_es: 'Servicios públicos',
      display_fr: 'Services publics',
      display_pt: 'Serviços públicos',
      type: 'number',
    },
    {
      fieldName: 'constructionNational',
      display_en: 'Construction',
      display_es: 'Construcción',
      display_fr: 'Construction',
      display_pt: 'Construção',
      type: 'number',
    },
    {
      fieldName: 'servicesNational',
      display_en: 'Services',
      display_es: 'Servicios',
      display_fr: 'Services',
      display_pt: 'Serviços',
      type: 'number',
    },
    {
      fieldName: 'transportationNational',
      display_en: 'Transportation',
      display_es: 'Transporte',
      display_fr: 'Transport',
      display_pt: 'Transportes',
      type: 'number',
    },
    {
      fieldName: 'tradeNational',
      display_en: 'Trade',
      display_es: 'Comercio',
      display_fr: 'Commerce',
      display_pt: 'Comércio',
      type: 'number',
    },
    {
      fieldName: 'uncategorizedNational',
      display_en: 'Uncategorized',
      display_es: 'Sin categoría',
      display_fr: 'Non classé',
      display_pt: 'Não categorizado',
      type: 'number',
    },
  ];

  data: any;
  latestValue: any;
  helptexts: any = [];

  constructor(
    private dataService: DataService,
    private fcuService: FetchCreateUpdateService,
    private snackbar: MatSnackBar,
    private helptextservice: HelptextService,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.helptextservice
      .getHelptexts('local-procurement-helptexts')
      .subscribe((res) => {
        this.helptexts = res;
      });
    this.dataService.selectedYear
      .pipe(
        switchMap((dy) => this.fcuService.fetch(this.fcuConfig, dy)),
        map((arr) => (isArray(arr) ? arr[0] : arr))
      )
      .subscribe((localProcurements) => {
        this.data = undefined;
        setTimeout(() => {
          if (!localProcurements)
            this.data = Helpers.createEmptyDataset(this.informations);
          else this.data = localProcurements;
        }, 50);
      });
  }

  onData(formData: any) {
    console.log(formData);
    if (formData.id) {
      this.fcuService
        .update(this.fcuConfig, formData)
        .subscribe((localProcurements) => {
          this.snackbar.open(
            `Updated local procurement data for ${localProcurements.data_year.year} successfully`
            , undefined, {duration: 2500}
          );
          this.data = localProcurements;
        });
    } else {
      this.dataService.selectedYear
        .pipe(
          switchMap((dy) =>
            this.fcuService.create(this.fcuConfig, dy, formData)
          ),
          // @ts-ignore
          map((arr) => (isArray(arr) ? arr[0] : arr))
        )
        .subscribe((localProcurements) => {
          this.snackbar.open(
            `Created local procurement data for ${localProcurements.data_year.year} successfully`
            , undefined, {duration: 2500}
          );
          this.data = localProcurements;
        });
    }
  }
}
