import { Component, OnInit } from '@angular/core';
import {LocaleService} from "../locale.service";

@Component({
  selector: 'app-environmental-forms',
  templateUrl: './environmental-forms.component.html',
  styleUrls: ['./environmental-forms.component.css']
})
export class EnvironmentalFormsComponent implements OnInit {

  constructor(private localeService: LocaleService) { }

  ngOnInit(): void {
    this.localeService.updateLocale()
  }

}
