import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KoboFieldService } from '../../kobo-field.service';
import { KoboService } from '../../kobo.service';
import { DialogAssignRepeatFieldComponent } from './dialog-assign-repeat-field/dialog-assign-repeat-field.component';
import { DialogAssignSurveysComponent } from './dialog-assign-surveys/dialog-assign-surveys.component';
import { DialogNewFieldComponent } from './dialog-new-field/dialog-new-field.component';
import { KoboCombinationConditionDialogComponent } from './kobo-combination/kobo-combination-condition-dialog/kobo-combination-condition-dialog.component';
import { KoboFormula } from './kobo-formula';
import { KoboFormulaField } from './kobo-formula-field';
import {LocaleService} from "../../../locale.service";
import { SurveyModifierDialogComponent } from '../../../survey-modifier-dialog/survey-modifier-dialog.component';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-form-editor',
  templateUrl: './form-editor.component.html',
  styleUrls: ['./form-editor.component.css'],
})
export class FormEditorComponent implements OnInit {
  private readonly regularMode = 'hh-survey';

  private readonly repeatMode = 'repeat';

  @Input() formula:
    | {
        aggregatedName: string;
        aggregatedFields?: string[];
        fields: any;
        collectionName?: any;
        id?: number;
        formulaName: string;
        formulaDescription: string;
        formula: KoboFormula;
        kobo_surveys: any[];
      }
    | undefined;

  @Output() deleted = new EventEmitter<boolean>();
  @Output() duplicated = new EventEmitter<boolean>();

  @Input() loadData: any;

  selected: any | undefined;
  demoResults: any | undefined;
  fields: any | undefined;
  form: KoboFormula | undefined;
  selectedFields: any[] = [];

  constructor(
    public dialog: MatDialog,
    private koboService: KoboService,
    private fieldService: KoboFieldService,
    private localeService: LocaleService
  ) {
    this.myForm = new FormGroup({
      name: new FormControl(),
    });
    this.myForm.get('name')?.valueChanges.subscribe((updatedValue) => {
      console.log(updatedValue); // Access the updated value here
      if(this.formula && this.form){
        this.form.aggregatedName = updatedValue;
        console.log(this.formula)
      }
    });
  }
  myForm: FormGroup;
  
  ngOnInit(): void {
    this.localeService.updateLocale()
    if (this.formula) {
      this.form = this.formula.formula;
      this.fieldService.fieldObs.subscribe((fields) => {
        this.fields = fields;
      });
      this.fieldService.updateFields(this.formula!.kobo_surveys);

      console.log("loaded", this.formula)
      // Set the value of the 'name' form control
      this.myForm.get('name')?.setValue(this.form.aggregatedName || "");
    }
  }

  openAddConditionDialog(): void {
    const dialogRef = this.dialog.open(
      KoboCombinationConditionDialogComponent,
      {
        width: '80vw',
        data: {},
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (!this.form) return;
      if (result) {
        this.form.postProcessing!.conditions.push(result);
      }
    });
  }

  openAssignSurveyDialog() {
    if (!this.form) return;
    const dialogRef = this.dialog.open(DialogAssignSurveysComponent, {
      width: '35vw',
      data: {
        collectionName: this.form.collectionName.collection,
        formulaName: this.formula?.formulaName,
        assignments: this.formula?.kobo_surveys,
      },
    });
    dialogRef.afterClosed().subscribe((assignments) => {
      if (this.formula && assignments) {
        this.formula.kobo_surveys = assignments;
        this.fieldService.updateFields(this.formula.kobo_surveys);
        console.log("assigning formulas", this.formula.kobo_surveys)
      }
    });
  }

  openFormulaModifierDialog(type: string) {
    if (!this.form) return;
    let dialogText;
    if(type == "changename"){
      dialogText = "Enter the new name for the formula:"
    }
    else if(type == "duplicate"){
      dialogText = "Enter a name for the copied formula:"
    }
    const dialogRef = this.dialog.open(SurveyModifierDialogComponent, {
      width: '35vw',
      data: {
        dialogText
      },
    });
    dialogRef.afterClosed().subscribe((newname) => {
      console.log(newname);
      if(this.formula){
        if(type=="changename"){
          this.formula.formulaName = newname;
          this.formula.formula.collectionName.display = newname;
          console.log(this.formula)
        }
        else if(type=="duplicate"){
          let new_formula = Object.assign({}, this.formula);
          new_formula.formulaName = newname;
          new_formula.formula.collectionName.display = newname;
          console.log(new_formula)
          const newKoboFormula = { // J: this weird format is expected by the backend
            collectionName: new_formula.collectionName,
            formulaName: new_formula.formulaName,
            formulaDescription: new_formula.formulaDescription,
            formula: this.buildFormulafromTemplate(new_formula.formula),
          };    
          console.log(newKoboFormula);
          this.koboService.createNewFormula(newKoboFormula).subscribe((response) => {
            console.log("created formula", response);
            new_formula.id = response.id;
            this.koboService.updateFormula(new_formula).subscribe((formula: any) => {
              this.formula = formula;
              this.form = this.formula!.formula;
              this.duplicated.emit();
            });
          });
        }
      }
    });
  }

  updateAggregatedFields(newValue: string[]) {
    if(this.formula && this.form){
      this.form.aggregatedFields = newValue;
      console.log(this.formula)
    }
  }

  private buildFormulafromTemplate(new_formula: any) {
    const formula: KoboFormula = {
      collectionName: {
        collection: new_formula.collectionName,
        display: new_formula.name,
      },
      options: {},
      fields: [],
    };
    for (const field of new_formula.fields) {
      formula.fields.push({
        name: field.name,
        type: field.type.type,
        value: 1,
        conditions: [],
        divisor: []
      });
    }
    return formula;
  }

  executeOnDemoSet() {
    this.demoResults = undefined;
    this.koboService
      .executeFormulaOnDemoSet(this.selected, this.form)
      .subscribe((results) => {
        this.demoResults = results;
      });
  }

  saveFormula() {
    this.koboService.updateFormula(this.formula).subscribe((formula: any) => {
      this.formula = formula;
      this.form = this.formula!.formula;
      console.log("saved", formula)
    });
  }

  addPostprocessing() {
    if (!this.form) return;
    this.form.postProcessing = {
      operation: 'divide',
      conditions: [],
    };
  }
  
  removePostprocessing() {
    if (!this.form) return;
    delete this.form.postProcessing;
  }

  addAggregation() {
    if (!this.form) return;
    this.form.postAggregation = {
      operation: 'divide',
      conditions: [],
    };
  }
  
  removeAggregation() {
    if (!this.form) return;
    delete this.form.postAggregation;
  }

  changeMode(field: any) {
    if (field === '' && this.form) {
      if (!this.form.options) {
        this.form.options = { mode: this.regularMode };
      }
      if (this.form.options.mode === this.regularMode)
        this.form.options.mode = this.repeatMode;
      else this.form.options.mode = this.regularMode;
      return;
    }
    if (field.type === undefined || field.type === 'regular') {
      field.type = 'repeat';
    } else {
      field.type = 'regular';
    }
  }

  openFormAssignRepeatFieldForm() {
    if (!this.form) return;
    if (!this.form!.options) {
      this.form!.options = {};
    }
    const dialogRef = this.dialog.open(DialogAssignRepeatFieldComponent, {
      width: '80vw',
      data: this.form.options.field || '',
    });
    dialogRef.afterClosed().subscribe((f) => {
      this.form!.options.field = f;
    });
  }

  openConfirmDeleteDialog(): void {
    let dialogText;
    dialogText = 'Are you sure you want to delete this formula?';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { dialogText },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Perform the action after confirmation
        this.deleteFormula()
      }
    });
  }

  deleteFormula() {
    if (this.formula?.id)
      this.koboService.dropFormula(this.formula.id).subscribe((deleted) => {
        this.deleted.emit();
      });
  }

  selectFormField(fieldName: string){
    this.selectedFields.push(fieldName);
    console.log(fieldName);
    console.log(this.selectedFields)
    this.updateAggregatedFields(this.selectedFields);
  }

  unselectFormField(fieldName: string){
    const index = this.selectedFields.find(f => f==fieldName);
    if(index != -1){
      this.selectedFields.splice(index, 1); 
    }
    console.log(fieldName);
    console.log(this.selectedFields)
    this.updateAggregatedFields(this.selectedFields);
  }

}
