import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/auth.service';
import { Information } from '../shared/information';
import { InformationType } from '../shared/infromation-type.enums';
import { User } from '../shared/user';
import {LocaleService} from "../locale.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  informations: Information[] = [
    {
      fieldName: '',
      display_en: 'Login',
      display_es: 'Inicio de sesión',
      display_fr: 'Connexion',
      display_pt: 'Login',
      type: 'title',
    },
    {
      fieldName: 'email',
      display_en: 'Email adress',
      display_es: 'Dirección de correo electrónico',
      display_fr: 'Adresse électronique',
      display_pt: 'Endereço de correio electrónico',
      type: 'email',
    },
    {
      fieldName: 'password',
      display_en: 'Password',
      display_es: 'Contraseña',
      display_fr: 'Mot de passe',
      display_pt: 'Senha',
      type: 'password',
    },
  ];

  helptexts = {
    email: 'Please enter your email address to log in',
    password: 'Please enter your password',
  };

  data = {};

  error: string | undefined;

  constructor(private authService: AuthService, private router: Router,
              private localeService: LocaleService) {}

  ngOnInit(): void {
    this.localeService.updateLocale()}

  onData(formData: any) {
    if (!this.data) throw new Error('Missing Data');
    this.login(formData);
  }

  login(formData: any) {
    if (formData.email && formData.password) {
      if (environment.useDefaultPw) {
        formData.email = 'admin@example.com';
        formData.password = 'abc123';
      }
      this.authService.login(formData.email, formData.password).subscribe({
        next: (user: User) => {
          this.router.navigateByUrl('welcome');
        },
        error: (error) => {
          this.error = 'Your credentials are incorrect. Please try again';
        },
      });
    }
  }
}
