<mat-card>
  <mat-card-header>
    <mat-card-title i18n="welcome@@welcome">Welcome {{ greeting }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="!user" i18n="pre login notification@@pre_login">Please log in first to gain access</div>
    <div *ngIf="user" style="width: 70%">
      <p i18n="userrole@@userrole">Userrole: {{ user.role.name }}</p>
      <p i18n="welcome description1@@welcome_1">
        This is the Admin Panel of the Resource Impact Dashboard (RID). Here you
        can insert data on a new or existing mine site to complete the Resource
        Impact Dashboard.
      </p>
      <br />
      <p i18n="welcome description2@@welcome_2">
        On the left side you find the Menu-bar where you can navigate through
        the sections. In the section “Household survey data”, you can
        automatically import the data generated by the household survey tool via
        Kobo import. In the section “Companies” you can find the owning
        companies of all extractive projects that form part of the RID. Under
        “Projects” all extractive projects are listed. When clicking on a
        project, you can choose and add data-years and insert data on the
        different variables under the sections “company", “environment", "social
        capital" and "education & health”).
      </p>
      <br>
      <p i18n="welcome description3@@welcome_3">
        Company users have access to the sections that concern their mining
        project(s) and the owning company of their mining project(s).
        They may insert data directly into those sections.
      </p>
      <br />
      <p i18n="welcome description4@@welcome_4">
        If you have problems with your data insertion, please contact the RID
        support at: contact@resource-impact.org
      </p>
      <br>
      <p i18n="welcome description5@@welcome_5">
        Thanks for being part of this project and contributing
        to better mine-level monitoring of mining impacts on local development
        and to more effective and equitable deliberation on the ground!
      </p>
    </div>
  </mat-card-content>
  <router-outlet></router-outlet>
</mat-card>
