import { Component, OnInit } from '@angular/core';
import { KoboService } from '../kobo.service';
import {LocaleService} from "../../locale.service";
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddFormulaAssignmentDialogComponent } from '../../add-formula-assignment-dialog/add-formula-assignment-dialog.component';
import { KoboFieldService } from '../kobo-field.service';

@Component({
  selector: 'app-kobo-importer',
  templateUrl: './kobo-importer.component.html',
  styleUrls: ['./kobo-importer.component.css'],
})
export class KoboImporterComponent implements OnInit {
  assignedSurveys: any[] | undefined;
  newSurveys: any[] | undefined;
  projects: any[] | undefined;
  loadingSurveys = false;
  JSON: any;

  constructor(private koboService: KoboService,
              private fieldService: KoboFieldService,
              private localeService: LocaleService,
              private dialog: MatDialog) {
                this.JSON = JSON;
              }

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.koboService.loadAssignedSurveys().subscribe((surveys) => {
      this.assignedSurveys = surveys;
    });
    this.koboService.loadProjects().subscribe((p) => {
      this.projects = p;
    });
  }

  public loadData(): void {
    console.log("loadData loadAssignedSurveys")
    this.koboService.loadAssignedSurveys().subscribe((surveys) => {
      this.assignedSurveys = surveys;
      console.log("this.assignedSurveys", surveys);
      if (this.newSurveys) {
        this.newSurveys = this.newSurveys.filter(
          (ns) => !this.isNewSurveyAssigned(ns)
        );
        console.log("loadData - new surveys", this.newSurveys)
      }
      this.loadingSurveys = false;
    });
  }

  loadKoboSurveys() {
    this.loadingSurveys = true;
    this.koboService.loadNewSurveys().subscribe(
      (newSurveys) => {
        this.newSurveys = newSurveys.filter(
          (ns) => !this.isNewSurveyAssigned(ns)
        );
        console.log("loadKoboSurveys", this.newSurveys)
        this.loadingSurveys = false;
      },
      (err) => {
        this.loadingSurveys = false;
      }
    );
  }

  private isNewSurveyAssigned(newSurvey: any) {
    if (this.assignedSurveys) {
      const assignedUids = this.assignedSurveys?.map((as) => as.koboId);
      return assignedUids?.includes(newSurvey.uid);
    }
    return false;
  }

  selectProject(uid: string, position: number, project: any) {
    const entry = this.newSurveys?.find((ns) => ns.uid === uid);
    if (entry) entry['project'] = project;
  }

  sortYears(years: any[]) {
    return years.sort((a, b) => a.year - b.year);
  }

  selectYear(uid: string, dataYear: any) {
    const entry = this.newSurveys?.find((ns) => ns.uid === uid);
    if (entry) entry['dataYear'] = dataYear;
  }

  saveNewSurvey(ns: any) {
    this.loadingSurveys = true;
    this.koboService.saveNewSurvey(ns).subscribe(() => {
      this.loadData();
    });
  }

  updateSurvey(survey: any) {
    this.loadingSurveys = true;
    this.koboService.updateCollections(survey).subscribe((results) => {
      this.loadData();
    });
  }

  dropAssignments(survey: any) {
    this.koboService.dropSurvey(survey).subscribe(() => {
      this.loadData();
    });
  }

  saveFormula(formula: any) { // J: modified from form-editor
    this.koboService.updateFormula(formula).subscribe((formula: any) => {
      console.log("saved formula", formula)
      this.loadData();
    });
  }

  dropFormulaAssignment(formula: any, survey: any) {
    console.log(formula, survey);
    if (formula && survey) {
      formula.kobo_surveys = formula.kobo_surveys || []; // J: somehow formula.kobo_surveys is always null
      formula.kobo_surveys = formula.kobo_surveys.filter((f: any) => f.id != formula.id);
      this.fieldService.updateFields(formula.kobo_surveys);
      console.log("dropFormulaAssignment", formula);
      this.saveFormula(formula);
    }
  }

  openConfirmDialog(type: string, s: any, f: any): void {
    let dialogText;
    if(type == "survey"){
      dialogText = 'Are you sure you want to delete this imported survey?';
    } 
    else if(type == "formula"){
      dialogText = 'Are you sure you want to remove this formula from this survey?';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { dialogText, f },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Perform the action after confirmation
        if(type == "survey"){
          this.dropAssignments(s);
        }
        else if(type == "formula"){
          this.dropFormulaAssignment(f, s)
        }
      }
    });
  }

  openAddFormulaAssignmentDialog(s: any): void {
    const dialogText = 'Select formula to add:';

    const dialogRef = this.dialog.open(AddFormulaAssignmentDialogComponent, {
      width: '250px',
      data: {
        dialogText
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        // Perform the action after confirmation
        result.formula.kobo_surveys = [...result.formula.kobo_surveys, s];
        this.fieldService.updateFields(result.formula.kobo_surveys);
        console.log("added formula assignment", result.formula);
        this.saveFormula(result.formula);
      }
    });
  }

}
