import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KoboService } from 'src/app/kobo/kobo.service';
import {LocaleService} from "../../../../locale.service";

@Component({
  selector: 'app-dialog-assign-surveys',
  templateUrl: './dialog-assign-surveys.component.html',
  styleUrls: ['./dialog-assign-surveys.component.css'],
})
export class DialogAssignSurveysComponent implements OnInit {
  surveys: any[] | undefined;

  assignedSurveys: any[] = [];

  collectionName: string | undefined;

  formulaName: string | undefined;

  constructor(
    private koboService: KoboService,
    private dialogRef: MatDialogRef<DialogAssignSurveysComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.collectionName = this.data.collectionName;
    this.formulaName = this.data.formulaName;
    this.assignedSurveys = this.data.assignments;
    if (!this.collectionName) return;
    this.koboService.loadAssignedSurveys().subscribe((surveys) => {
      this.surveys = surveys;
    });
  }

  isSurveyAssignedToFormula(survey: any): boolean {
    return (
      survey.kobo_formulas.find(
        (f: any) =>
          f.collectionName === this.collectionName &&
          f.formulaName === this.formulaName
      ) !== undefined
    );
  }

  isSurveyAssignedInOtherFormula(survey: any) {
    return (
      survey.kobo_formulas.find(
        (formula: any) =>
          formula.collectionName === this.collectionName &&
          formula.formulaName !== this.formulaName
      ) !== undefined
    );
  }

  getAssignedFormula(survey: any) {
    return survey.kobo_formulas.find(
      (formula: any) =>
        formula.collectionName === this.collectionName &&
        formula.formulaName !== this.formulaName
    );
  }

  isSurveyInAssignedStack(survey: any) {
    return this.assignedSurveys.map((s) => s.id).includes(survey.id);
  }

  assignSurvey(survey: any) {
    this.assignedSurveys.push(survey);
  }

  unassignSurvey(survey: any) {
    this.assignedSurveys.splice(this.assignedSurveys.indexOf(survey), 1);
  }

  onCancel() {
    this.dialogRef.close();
  }
}
