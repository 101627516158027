<div *ngIf="data && informations">
  <app-form
    [informations]="informations"
    [data]="data"
    [helptexts]="helptexts[0]"
    (dataSubmitted)="onData($event)"
  ></app-form>
</div>

<button mat-raised-button color="primary" (click)="addUser()" i18n="add company user@@add_company_user">
  Add Company User
</button>
<ng-container *ngIf="currentUser && users && userinformations && helptexts">
  <div *ngFor="let user of users">
    <app-form
      [informations]="userinformations"
      [data]="user"
      [showDelete]="user.id !== currentUser.id"
      (deleted)="deleteUser($event)"
      (dataSubmitted)="onUserData($event)"
    ></app-form>
  </div>
</ng-container>
