<app-metadata [graphName]="'social-investments'"></app-metadata>
<button mat-raised-button color="primary" (click)="addSocialInvestment()" i18n="add social investment@@add_social_investment">Add Social Investment</button>
<div *ngIf="data && helptexts &&informations">
  <ng-container *ngFor="let datum of data">
    <app-form
      [informations]="informations"
      [data]="datum"
      [helptexts]="helptexts[0]"
      [showDelete]="true"
      (deleted)="deleteSocialInvestment($event)"
      (dataSubmitted)="onData($event)"
    ></app-form>
  </ng-container>
</div>
