<mat-card>
  <h2 i18n="available surveys@@available_surveys">Available Surveys</h2>
  <mat-list *ngIf="assignedSurveys && assignedSurveys.length > 0">
    <mat-list-item
      *ngFor="let s of assignedSurveys"
      style="height: auto; padding-top: 2vh; padding: 100px"
      class="mat-list-item-content_new"
      >
        <mat-divider></mat-divider>
        <div>
          <p i18n="survey@@survey">Survey:{{ s.surveyName && s.surveyName }}<br />{{ s.data_year && s.data_year.name }}</p>
          <p i18n="last_update@@last_update">Last Update: {{ s.lastUpdated || "-" }}</p>
        </div>
        <label style="flex: 1 1 auto"></label>
        <button mat-raised-button (click)="updateSurvey(s)" i18n="update collections@@update_collections">
          Update collections
        </button>
        <label style="flex: 1 1 auto"></label>
        <div
          *ngIf="true || s.kobo_formulas && s.kobo_formulas.length > 0"
          style="max-width: 40%; width: 40%; padding-bottom: 20px"
          i18n="formulas@@formulas"
        >
          Formulas:
          <div>
            <mat-chip-list>
              <mat-chip *ngFor="let f of s.kobo_formulas">
                {{ f.formulaName }} | <mat-icon  (click)="openConfirmDialog('formula', s, f);" class="close-icon" aria-label="Close icon" matTooltip="Close">close</mat-icon>
              </mat-chip>
              <button mat-raised-button (click)="openAddFormulaAssignmentDialog(s)">
                Add formula
              </button>
            </mat-chip-list>
          </div>
        </div>
        <label style="flex: 1 1 auto"></label>
        <button mat-raised-button color="warn" (click)="openConfirmDialog('survey', s, null);" i18n="remove assignment@@remove_assignment">
          Delete imported survey
        </button>
    </mat-list-item>
  </mat-list>
  <div *ngIf="!assignedSurveys || assignedSurveys.length === 0" i18n="no assigned surveys found, laod new surveys and assign them@@no_assigned_surveys">
    No assigned surveys found. Load new surveys and assign them!
  </div>
  <h2 i18n="import new sets@@import_new_sets">Import new sets</h2>
  <div *ngIf="!loadingSurveys">
    <button mat-raised-button color="primary" (click)="loadKoboSurveys()" i18n="load new surveys@@load_new_surveys">
      Load new surveys
    </button>
  </div>
  <mat-spinner *ngIf="loadingSurveys"></mat-spinner>
  <mat-list *ngIf="newSurveys && newSurveys.length > 0">
    <mat-list-item *ngFor="let ns of newSurveys; index as i">
      <div style="width: 10vw; padding-right: 2vw; margin-right: 2vw">
        {{ ns.name }}
        <div style="font-size: x-small">UID: {{ ns.uid }}</div>
      </div>
      <div style="padding-right: 2vw; margin-right: 2vw" i18n="no entries@@no_entries">
        No Entries:{{ ns.deployment__submission_count }}
      </div>
      <button
        mat-button
        [matMenuTriggerData]="{ data: { uid: ns.uid, position: i } }"
        [matMenuTriggerFor]="assignProject"
        i18n="assign project@@assign_project"
      >
        Assign project
      </button>
      <div *ngIf="ns.project" style="padding-right: 2vw; margin-right: 2vw">
        {{ ns.project.name }}
      </div>
      <div *ngIf="ns.project">
        <button
          mat-button
          [matMenuTriggerFor]="assignYear"
          [matMenuTriggerData]="{ years: ns.project.data_years, uid: ns.uid }"
          i18n="assign year@@assign_year"
        >
          Assign year
        </button>
      </div>
      <div *ngIf="ns.dataYear" style="padding-right: 2vw; margin-right: 2vw">
        {{ ns.dataYear.year }}
      </div>
      <div *ngIf="ns.dataYear">
        <button
          [disabled]="loadingSurveys"
          mat-raised-button
          color="primary"
          (click)="saveNewSurvey(ns)"
          i18n="save new survey@@save_new_survey"
        >
          Save imported survey
        </button>
      </div>
    </mat-list-item>
  </mat-list>
  <div *ngIf="newSurveys && newSurveys.length === 0" i18n="no unassigned surveys@@no_unassigned_surveys_admin">
    No unassigned surveys found, please contact the KOBO admin
  </div>
</mat-card>

<mat-menu #assignProject="matMenu">
  <ng-template matMenuContent let-data="data">
    <button
      mat-menu-item
      *ngFor="let p of projects"
      (click)="selectProject(data.uid, data.position, p)"
    >
      {{ p.name }}
    </button>
  </ng-template>
</mat-menu>

<mat-menu #assignYear="matMenu">
  <ng-template matMenuContent let-years="years" let-uid="uid">
    <button
      mat-menu-item
      *ngFor="let y of sortYears(years)"
      (click)="selectYear(uid, y)"
    >
      {{ y.year }}
    </button>
  </ng-template>
</mat-menu>
