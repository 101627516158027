<mat-card-content>
  <div class="row">
    <div class="col">
      <mat-tab-group>
        <mat-tab label="Air Quality" i18n-label="air quality@@air_quality">
          <app-air-quality></app-air-quality>
        </mat-tab>
        <mat-tab label="Water Quality" i18n-label="water quality@@water_quality">
          <app-water-quality></app-water-quality>
        </mat-tab>
        <mat-tab label="Land and Forest" i18n-label="land and forest@@land_and_forest">
          <app-land-forest></app-land-forest>
        </mat-tab>
        <mat-tab label="Noise and CO2" i18n-label="noise and CO2@@noise_and_co2">
          <app-noise-co2></app-noise-co2>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</mat-card-content>
