<div *ngIf="statement">
  {{ statement.surveyFieldName }}
  <label *ngIf="statement.statement.eq">= {{ statement.statement.eq }}</label>
  <label *ngIf="statement.statement.gt !== undefined">
    > {{ statement.statement.gt }}</label
  >
  <label *ngIf="statement.statement.gte !== undefined">
    >= {{ statement.statement.gte }}</label
  >
  <label *ngIf="statement.statement.lt !== undefined"> 
    < {{ statement.statement.lt }}</label
  >
  <label *ngIf="statement.statement.lte !== undefined">
    <= {{ statement.statement.lte }}</label
  >
  <label *ngIf="statement.statement.ne !== undefined">
    ≠ {{ statement.statement.ne }}</label
  >
  <label *ngIf="statement.statement.in !== undefined"
     i18n="includes@@includes">
     includes {{ statement.statement.in }}</label
  >
  <label *ngIf="statement.statement.notIn !== undefined"
         i18n="not includes@@not_includes">
    not includes {{ statement.statement.notIn }}</label
  >
  <button mat-button (click)="openChangeStatementDialog()" i18n="change statement@@change_statement">
    Change Statement
  </button>
</div>
