import { Component, OnInit } from '@angular/core';
import { ProjectDetailService } from './project-details.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { DataService } from '../shared/data.service';
import { CompanyService } from '../company-details/company.service';
import { CountryService } from '../shared/country.service';
import { HelptextService } from '../shared/helptext.service';
import { AuthService } from '../shared/auth.service';
import { UserRole } from '../shared/user-role.enum';
import { User } from '../shared/user';
import {LocaleService} from "../locale.service";

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css'],
})
export class ProjectDetailsComponent implements OnInit {
  operating_company_informations = [
    {
      fieldName: '',
      display_en: 'Operating Companies',
      display_es: 'Empresas operadoras',
      display_fr: 'Sociétés d\'exploitation',
      display_pt: 'Empresas Operadoras',
      type: 'title',
    },
    {
      fieldName: 'id',
      display_en: 'none',
      display_es: 'noen',
      display_fr: 'none',
      display_pt: 'none',
      type: 'n/a',
    },
    {
      fieldName: 'name',
      display_en: 'Name',
      display_es: 'Nombre',
      display_fr: 'Nom',
      display_pt: 'Nome',
      type: 'string',
    },
    {
      fieldName: 'shareholders',
      display_en: 'Shareholders',
      display_es: 'Accionistas',
      display_fr: 'Actionnaires',
      display_pt: 'Accionistas',
      type: 'string',
    },
    {
      fieldName: 'stockexchange',
      display_en: 'Stockexchange',
      display_es: 'Bolsa de Valores',
      display_fr: 'Bourse des valeurs',
      display_pt: 'Stockexchange',
      type: 'string',
    },
    {
      fieldName: 'csrPolicy',
      display_en: 'CSR Policy',
      display_es: 'Política de RSE',
      display_fr: 'Politique RSE',
      display_pt: 'Política de RSE',
      type: 'string',
    },
    {
      fieldName: 'voluntaryStandarts',
      display_en: 'Voluntary Standards',
      display_es: 'Normas voluntarias',
      display_fr: 'Normes volontaires',
      display_pt: 'Normas Voluntárias',
      type: 'string',
    },
    {
      fieldName: 'functionOfTheCompany',
      display_en: 'Function of the Company',
      display_es: 'Función de la empresa',
      display_fr: 'Fonction de la société',
      display_pt: 'Função da Empresa',
      type: 'string',
    },
  ];
  informations: any[] = [
    {
      fieldName: '',
      display_en: 'Project Details',
      display_es: 'Detalles del proyecto',
      display_fr: 'Détails du projet',
      display_pt: 'Detalhes do Projecto',
      type: 'title',
    },
    {
      fieldName: 'company',
      display_en: 'Owning Company',
      display_es: 'Empresa propietaria',
      display_fr: 'Société propriétaire',
      display_pt: 'Empresa proprietária',
      type: 'dropdown',
      options: [],
    },
    {
      fieldName: 'country',
      display_en: 'Country',
      display_es: 'País',
      display_fr: 'Pays',
      display_pt: 'País',
      type: 'dropdown',
      options: [],
    },
    {
      fieldName: 'name',
      display_en: 'Name',
      display_es: 'Nombre',
      display_fr: 'Nom',
      display_pt: 'Nome',
      type: 'string',
    },
    { fieldName: 'description',
      display_en: 'Description',
      display_es: 'Descripción',
      display_fr: 'Description',
      display_pt: 'Descrição',
      type: 'textarea'
    },
    { fieldName: 'mineral',
      display_en: 'Mineral',
      display_es: 'Mineral',
      display_fr: 'Minéral',
      display_pt: 'Mineral',
      type: 'string'
    },
    { fieldName: 'latitude',
      display_en: 'Latitude',
      display_es: 'Latitud',
      display_fr: 'Latitude',
      display_pt: 'Latitude',
      type: 'number'
    },
    { fieldName: 'longitude',
      display_en: 'Longitude',
      display_es: 'Longitud',
      display_fr: 'Longitude',
      display_pt: 'Longitude',
      type: 'number'
    },
    {
      fieldName: 'zoneOfInfluence',
      display_en: 'Zone of Influence',
      display_es: 'Zona de influencia',
      display_fr: 'Zone d\'influence',
      display_pt: 'Zona de Influência',
      type: 'string',
    },
    {
      fieldName: 'surfaceOfConcession',
      display_en: 'Surface of concession',
      display_es: 'Superficie de la concesión',
      display_fr: 'Surface de la concession',
      display_pt: 'Superfície da concessão',
      type: 'number',
    },
    {
      fieldName: 'surfaceOfProductionArea',
      display_en: 'Surface of production area',
      display_es: 'Superficie de la zona de producción',
      display_fr: 'Surface de la zone de production',
      display_pt: 'Superfície da área de produção',
      type: 'number',
    },
    {
      fieldName: 'landLicenseArea',
      display_en: 'Land license area',
      display_es: 'Área de la licencia del terreno',
      display_fr: 'Zone de permis d\'utilisation des terres',
      display_pt: 'Área de licenciamento do terreno',
      type: 'number',
    },
    {
      fieldName: 'estimatedLifespan',
      display: 'Estimated lifespan',
      display_es: 'Vida útil estimada',
      display_fr: 'Durée de vie estimée',
      display_pt: 'Tempo de vida estimado',
      type: 'string',
    },
    {
      fieldName: 'contractDuration',
      display: 'Contract duration',
      display_es: 'Duración del contrato',
      display_fr: 'Durée du contrat',
      display_pt: 'Duração do contrato',
      type: 'string',
    },
    { fieldName: 'currentStage',
      display: 'Current stage',
      display_es: 'Etapa actual',
      display_fr: 'Stade actuel',
      display_pt: 'Fase actual',
      type: 'string'
    },
    {
      fieldName: 'constructionStart',
      display: 'Construction start date',
      display_es: 'Fecha de inicio de la construcción',
      display_fr: 'Date de début de la construction',
      display_pt: 'Data de início da construção',
      type: 'date',
    },
    {
      fieldName: 'operationStart',
      display: 'Operation start date',
      display_es: 'Fecha de inicio de la operación',
      display_fr: 'Date de début de l\'opération',
      display_pt: 'Data de início da operação',
      type: 'date',
    },
    {
      fieldName: 'processingDone',
      display: 'Processing Done',
      display_es: 'Procesamiento realizado',
      display_fr: 'Traitement terminé',
      display_pt: 'Processamento Feito',
      type: 'boolean',
    },
    {
      fieldName: 'processDescription',
      display: 'Process description',
      display_es: 'Descripción del proceso',
      display_fr: 'Description du processus',
      display_pt: 'Descrição do processo',
      type: 'textarea',
    },
    {
      fieldName: 'asmActivities',
      display: 'ASM Activities',
      display_es: 'Actividades de la MAPE',
      display_fr: 'Activités de l\'ASM',
      display_pt: 'Actividades da MAPE',
      type: 'boolean',
    },
  ];

  data: { [key: string]: any } | undefined;
  operating_company_data: any[] = [];
  formValid: boolean = false;
  helptexts: any = [];
  opHelptexts: any = [];

  constructor(
    private projectDetailService: ProjectDetailService,
    private authService: AuthService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private companyService: CompanyService,
    private countryService: CountryService,
    private helptextservice: HelptextService,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.helptextservice.getHelptexts('helptexts-projects').subscribe((res) => {
      this.helptexts = res;
    });
    this.helptextservice
      .getHelptexts('helptexts-operating-companies')
      .subscribe((res) => {
        this.opHelptexts = res;
      });
    this.activatedRoute.paramMap
      .pipe(
        tap(() => {
          this.data = undefined;
        }),
        switchMap((params: any) =>
          this.projectDetailService.fetchProject(params.params.id)
        )
      )
      .subscribe((project: any) => {
        this.data = project;
        this.projectDetailService
          .fetchOperatingCompanies(project.id)
          .subscribe((res) => {
            this.operating_company_data = res;
          });
      });
    this.loadDropdownOptions();
  }

  private loadDropdownOptions() {
    this.authService.getUser().subscribe((user) => {
      if (user.role.name === UserRole.ADMIN) {
        this.loadAllCompanies();
        this.loadAllCountries();
      }
      if (user.role.name === UserRole.NGO) {
        this.loadAllCompanies();
        this.loadCountriesForUser(user);
      }
      if (user.role.name === UserRole.COMPANY) {
        this.loadCompaniesForUser(user);
        this.loadAllCountries();
      }
    });
  }

  private loadAllCompanies() {
    this.companyService.getAllCompanies().subscribe((companies: any[]) => {
      this.informations.find(
        (information) => information.fieldName === 'company'
      ).options = companies.map((company) => {
        return { value: company.id, viewValue: company.name };
      });
    });
  }

  private loadAllCountries() {
    this.countryService.getAllCountries().subscribe((countries: any[]) => {
      this.informations.find(
        (information) => information.fieldName === 'country'
      ).options = countries.map((country) => {
        return { value: country.id, viewValue: country.name };
      });
    });
  }

  private loadCompaniesForUser(user: User) {
    if (typeof user.company === 'number')
      this.companyService
        .fetchCompany(user.company)
        .subscribe((company: any) => {
          this.informations.find(
            (information) => information.fieldName === 'company'
          ).options = [
            {
              value: company.id,
              viewValue: company.name,
            },
          ];
        });
  }

  private loadCountriesForUser(user: User) {
    if (typeof user.country === 'number')
      this.countryService
        .fetchCountry(user.country)
        .subscribe((company: any) => {
          this.informations.find(
            (information) => information.fieldName === 'country'
          ).options = [
            {
              value: company.id,
              viewValue: company.name,
            },
          ];
        });
  }

  areOptionsLoaded() {
    return (
      this.informations.find(
        (information) => information.fieldName === 'company'
      ).options.length > 0 &&
      this.informations.find(
        (information) => information.fieldName === 'country'
      ).options.length > 0
    );
  }

  onData(formData: any) {
    if (!this.data) throw new Error('Missing Data');
    this.updateData(formData);
    this.projectDetailService.updateData(this.data).subscribe((data) => {
      this.data = data;
      this.snackbar.open('Values updated Successfully', undefined, {
        duration: 3000,
      });
    });
  }

  private updateData(formData: { [key: string]: any }) {
    if (!this.data) throw new Error('Missing Data');
    for (const key of Object.keys(formData)) {
      if (formData[key] instanceof Date) {
        const d = formData[key] as Date;
        const date = `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;
        this.data[key] = date;
      } else {
        this.data[key] = formData[key];
      }
    }
  }

  processFormChanges(changes: any) {
    this.formValid = changes.isValid;
  }

  onDataOp($event: any) {
    this.projectDetailService
      .updateOperatingCompanies($event)
      .subscribe((res) => {
        this.snackbar.open('Values updated Successfully', undefined, {
          duration: 2500,
        });
      });
  }

  deleteOpComp($event: any) {
    return this.projectDetailService
      .deleteOperatingCompany($event.id)
      .subscribe((res: any) => {
        this.snackbar.open('Deleted Successfully', undefined, {
          duration: 2500,
        });
        this.operating_company_data = this.operating_company_data.filter(
          (obj) => obj.id != res.id
        );
      });
  }

  addOperatingCompany() {
    const id = this.dataService.getProjectId();
    this.projectDetailService.addOperatingCompany(id).subscribe((res: any) => {
      this.operating_company_data.push(res);
    });
  }
}
