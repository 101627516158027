import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KoboCombination } from '../kobo-combination';
import { KoboCombinationConditionDialogComponent } from './kobo-combination-condition-dialog/kobo-combination-condition-dialog.component';
import { KoboCombinationDialogComponent } from './kobo-combination-dialog/kobo-combination-dialog.component';
import {LocaleService} from "../../../../locale.service";

@Component({
  selector: 'app-kobo-combination',
  templateUrl: './kobo-combination.component.html',
  styleUrls: ['./kobo-combination.component.css'],
})
export class KoboCombinationComponent implements OnInit {
  @Input() input: any;

  combination: KoboCombination | undefined;

  constructor(public dialog: MatDialog,
              private localeService: LocaleService) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    if (this.input && this.input.type === 'combination')
      this.combination = this.input;
  }

  openAddConditionDialog(): void {
    const dialogRef = this.dialog.open(
      KoboCombinationConditionDialogComponent,
      {
        width: '80vw',
        data: {},
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.combination?.conditions.push(result);
      }
    });
  }

  openChangeCombinationDialog(): void {
    const dialogRef = this.dialog.open(KoboCombinationDialogComponent, {
      width: '80vw',
      data: JSON.parse(JSON.stringify(this.combination)),
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.type === 'combination') {
        if (result) {
          // we assign only the relevant informations to the existing object in order to
          // keep the proper object in the editor. If we would do this.combination = result we
          // would loose the reference in the form editor
          this.combination!.bool = result.bool;
          this.combination!.conditions = result.conditions;
        }
      }
    });
  }
}
