<mat-card *ngIf="formula && form">
  <div style="display: flex">
    <div>
      <h3 i18n="form editor@@form_editor">Formula Editor</h3>
      <div>Name: {{ form.collectionName.display }} </div>
      <div>Collection: {{ form.collectionName.collection }} </div>
      <button mat-button (click)="openFormulaModifierDialog('changename')" i18n="assign surveys@@assign_surveys">
        Change name
      </button>
      <button mat-button (click)="openFormulaModifierDialog('duplicate')" i18n="assign surveys@@assign_surveys">
        Duplicate
      </button>
    </div>
    <div style="margin-left: 10vw">
      <h3 i18n="assigned surveys@@assigned_surveys">Assigned surveys</h3>
      <div *ngIf="formula.kobo_surveys && formula.kobo_surveys.length > 0">
        <div *ngFor="let survey of formula.kobo_surveys">
          {{ survey.surveyName }}
        </div>
      </div>
      <div *ngIf="!formula.kobo_surveys || formula.kobo_surveys.length === 0" i18n="please assign a survey@@please_assign_survey">
        Please assign a survey
      </div>
      <button mat-button (click)="openAssignSurveyDialog()" i18n="assign surveys@@assign_surveys">
        Assign surveys
      </button>
    </div>
    
    <div style="margin-left: 10vw">
      <h3 i18n="fields of assigned surveys@@fields_of_assigned_surveys">Fields of assigned surveys</h3>
      <p i18n="number of shared fields@@number_of_shared_fields">Number of shared fields: {{ fields.sharedFields.length }}</p>
      <p i18n="number of all fields@@number_of_all_fields">Number of all fields: {{ fields.allFields.length }}</p>
    </div>
  </div>
  <p i18n="fields@@fields">{{ form.fields.length }} Fields</p>
  <!-- J: currently repeat is integrated into the individual formulas, not here anymore
  <div>
    <p  i18n="mode@@mode">Mode:</p>
    {{ form.options?.mode || "regular" }}
    <button mat-button (click)="changeMode('')" i18n="change mode@@change_mode">Change Mode</button>
  </div>
  -->
  <div *ngIf="form.options?.mode === 'repeat'" >
    <p i18n="repeat field@@repeat_field">Repeat Field: </p>{{ form.options.field || "No field selected"
    }}<button mat-raised-button (click)="openFormAssignRepeatFieldForm()" i18n="select field@@select_field">
      Select Field
    </button>
  </div>
  <div>
    <app-form-field
      [formField]="field"
      [fieldindex]="fieldIndex"
      *ngFor="let field of form.fields; index as fieldIndex"
    ></app-form-field>
  </div>
  <div *ngIf="form.postProcessing" style="margin-bottom: 2vh">
    <h3>
      <p  i18n="postprocessing@@postprocessing">Postprocessing</p>
      <button mat-raised-button (click)="removePostprocessing()" i18n="remove postprocessing@@remove_postprocessing">
        Remove Postprocessing
      </button>
    </h3>
    <div i18n="operation@@operation">Operation: {{ form.postProcessing.operation }}</div>
    <div
      *ngIf="
        form.postProcessing.conditions &&
        form.postProcessing.conditions.length > 0
      "
    >
      <div *ngFor="let condition of form.postProcessing.conditions">
        <div style="margin-left: 1vw; margin-top: 0.5vh">
          <app-kobo-combination
            *ngIf="condition.type === 'combination'"
            [input]="condition"
          ></app-kobo-combination>
          <app-kobo-statement
            *ngIf="condition.type === 'statement'"
            [input]="condition"
          ></app-kobo-statement>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        !form.postProcessing.conditions ||
        form.postProcessing.conditions.length === 0
      "
    >
      <button mat-button (click)="openAddConditionDialog()" i18n="create new conditions@@create_new_conditions">
        Create new conditions
      </button>
    </div>
  </div>
  <!--<div style="margin-bottom: 2vh" *ngIf="!form.postProcessing">
    <button mat-raised-button (click)="addPostprocessing()" i18n="create postprocessing@@create_postprocessing">
      Create PostProcessing
    </button>
    </div>
  -->
  <div>
  <!-- J: aggregation of multiple results -->
    <!--<div *ngIf="form.postAggregation" style="margin-bottom: 2vh">
        <h3>
          <p i18n="postprocessing@@postprocessing">Aggregation</p>
          <button mat-raised-button (click)="removeAggregation()" i18n="remove aggregation@@remove_aggregation">
            Remove Aggregation
          </button>
        </h3>

        <form [formGroup]="myForm">
          <mat-form-field>
            <mat-label i18n="name@@name">Name of result</mat-label>
            <input matInput formControlName="name">
          </mat-form-field>
          <div>Pick conditions to aggregate over [using conditions from formula]:</div>
          <\!--
          <div *ngIf="surveys">
            <div *ngFor="let survey of surveys">
              {{ survey.surveyName }}
              <label *ngIf="isSurveyAssignedInOtherFormula(survey)"
                    i18n="assigned in formula@@assigned_in_formula"
                >Assigned in formula:
                {{ getAssignedFormula(survey).formulaName }}</label
              >
              <div *ngIf="!isSurveyAssignedInOtherFormula(survey)">
                <button
                  mat-raised-button
                  [disabled]="isSurveyInAssignedStack(survey)"
                  (click)="assignSurvey(survey)"
                  i18n="assign@@assign"
                >
                  Assign
                </button>
                <button
                  mat-raised-button
                  [disabled]="!isSurveyInAssignedStack(survey)"
                  (click)="unassignSurvey(survey)"
                  i18n="unassign@@unassign"
                >
                  Unassign
                </button>
              </div>
            </div>
          </div>
          --\>
          <\!--
          <app-form-field
          [formField]="field"
          [fieldindex]="fieldIndex"
          *ngFor="let field of form.fields; index as fieldIndex"
          ></app-form-field>
          --\>
          <app-form-field-aggregation
          (addAggregatedFields)="selectFormField($event)"
          (removeAggregatedFields)="unselectFormField($event)"
          [formField]="field"
          [fieldindex]="fieldIndex"
          [formFields]="form.aggregatedFields"
          *ngFor="let field of form.fields; index as fieldIndex"
          ></app-form-field-aggregation>
        </form>   
      </div>
      <div style="margin-bottom: 2vh" *ngIf="!form.postAggregation">
    -->
    <!--<button mat-raised-button (click)="addAggregation()" i18n="create aggregation@@create_aggregation">
      Create Aggregation
    </button>-->
  </div>
  <div style="display: flex">
    <div>
      <div *ngIf="formula.kobo_surveys.length > 0">
        <mat-form-field appearance="fill">
          <mat-label i18n="select demo set@@select_demo_set">Select demo set</mat-label>
          <mat-select [(value)]="selected">
            <mat-option
              *ngFor="let survey of formula.kobo_surveys"
              value="{{ survey.id }}"
              >{{ survey.surveyName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <button
          mat-button
          [disabled]="selected === undefined"
          (click)="executeOnDemoSet()"
          i18n="execute@@execute"
        >
          Execute test
        </button>
      </div>
      <div *ngIf="formula.kobo_surveys.length == 0">
        <mat-form-field appearance="fill">
          <mat-label i18n="select demo set@@select_demo_set">Please assign a survey first</mat-label>
          <mat-select disabled [(value)]="selected">
            <mat-option
              *ngFor="let survey of formula.kobo_surveys"
              value="{{ survey.id }}"
              >{{ survey.surveyName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <button
          mat-button
          [disabled]="selected === undefined"
          (click)="executeOnDemoSet()"
          i18n="execute@@execute"
        >
          Execute test
        </button>
      </div>
      <div *ngIf="demoResults">
        <app-demo-results [results]="demoResults"></app-demo-results>
      </div>
      <div>
        <button mat-button (click)="saveFormula()" i18n="save formula@@save_formula">Save formula</button>
      </div>
    </div>
    <label style="flex: 1 1 auto"></label>
    <div>
      <button mat-raised-button color="warn" (click)="openConfirmDeleteDialog()" i18n="deleteformula@@delete_formula">
        Delete formula
      </button>
    </div>
  </div>
</mat-card>
