import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { KoboService } from 'src/app/kobo/kobo.service';

@Component({
  selector: 'app-add-formula-assignment-dialog',
  template: `
    <h2 mat-dialog-title>Confirm Action</h2>
    <mat-dialog-content>
      <form [formGroup]="myForm">
        <mat-form-field>
          <mat-label>{{ dialogText }}</mat-label>
          <mat-select formControlName="mySelect">
            <mat-option *ngFor="let option of options" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button color="primary" (click)="confirm()">Confirm</button>
    </mat-dialog-actions>
  `,
})
export class AddFormulaAssignmentDialogComponent implements OnInit {
  myForm: FormGroup;
  surveys: any[] | undefined;
  assignedSurveys: any[] = [];
  collectionName: string | undefined;
  formulaName: string | undefined;
  formulas: any[] | undefined;

  options = [
    { label: 'Option 1', value: 'option1' },
  ];

  constructor(
    private koboService: KoboService,
    public dialogRef: MatDialogRef<AddFormulaAssignmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dialogText: string }
  ) {
    this.myForm = new FormGroup({
      mySelect: new FormControl(),
    });
  }

  get dialogText(): string {
    return this.data.dialogText;
  }

  ngOnInit(): void {
    this.koboService.loadAssignedSurveys().subscribe((surveys) => {
      this.surveys = surveys;
      console.log(surveys);
    });
    this.koboService.loadFormulas().subscribe((formulas) => {
      this.formulas = formulas;
      this.options = formulas.map((formula) => {
        return { label: formula.formulaName, value: formula.id };
      });
      console.log(formulas);
    });
  }

  confirm(): void {
    const selectedValue = this.myForm.value.mySelect;
    console.log(selectedValue)
    if (selectedValue) {
      this.dialogRef.close({formula: this.formulas && this.formulas.find(formula => {return formula.id == selectedValue})});
    }  
  }
}
