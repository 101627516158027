import { Injectable } from '@angular/core';
import {switchMap} from "rxjs/operators";
import {DataService} from "../shared/data.service";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import * as qs from "qs";

@Injectable({
  providedIn: 'root'
})
export class HealthDataService {


  endPoint = environment.api + '/health-health-workers'

  dyName = 'health_health_worker'

  constructor(private http: HttpClient, private dataService: DataService) { }

  getHealthWorkersData() {
    return this.dataService.selectedYear.pipe(
      switchMap((dy) => {
        return this.http.get<any[]>(`${this.endPoint}?${this.buildDataYearQuery(dy.year, this.dataService.getProjectId() )}`);

      })
    );
  }


  createNewHealthWorkersData() {
    return this.dataService.selectedYear.pipe(
      switchMap((dy) => {
        return this.http.post<any[]>(`${this.endPoint}`, {data_year: dy});
      })
    );
  }


  buildDataYearQuery(years: any, projectId: any) {
    let where = {
      'data_year.year_in': years,
      'data_year.project_eq': projectId,
    };
    return  qs.stringify({
      _where: [where],
    });
  }


  updateData(data: any) {
    return this.http.put(this.endPoint + '/' + data.id, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.endPoint}/${id}`);
  }

  post(formData: any) {
    return this.http.post(`${this.endPoint}/`, formData)
  }
}
