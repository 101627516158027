import { Component, OnInit } from '@angular/core';
import { Information } from 'src/app/shared/information';
import { AirQualityService } from './air-quality.service';
import { HelptextService } from '../../shared/helptext.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {Locale} from "../../locale";
import {LocaleService} from "../../locale.service";

@Component({
  selector: 'app-air-quality',
  templateUrl: './air-quality.component.html',
  styleUrls: ['./air-quality.component.css'],
})
export class AirQualityComponent implements OnInit {
  locale = Locale.EN
  sampleInformations: any[] = [
    {
      fieldName: 'air_quality',
      display_en: 'Outside Air Quality',
      display_es: 'Calidad del aire exterior',
      display_fr: 'Qualité de l\'air extérieur',
      display_pt: 'Qualidade do Ar Exterior',
      type: 'sample',
    },
    {
      fieldName: 'workspace_air_quality',
      display_en: 'Workplace Air Quality',
      display_es: 'Calidad del aire en el lugar de trabajo',
      display_fr: 'Qualité de l\'air sur le lieu de travail',
      display_pt: 'Qualidade do ar no local de trabalho',
      type: 'sample',
    },
    {
      fieldName: 'radiation',
      display_en: 'Radiation',
      display_es: 'Radiación',
      display_fr: 'Radiation',
      display_pt: 'Radiação',
      type: 'sample',
    },
  ];

  activateSaveButton = false;

  data: any | undefined;

  formValues: {
    [key: string]: {
      valid: boolean;
      data: any;
    };
  } = {};
  helptexts: any = [];
  constructor(
    private dataService: AirQualityService,
    private helptextsService: HelptextService,
    private snackbar: MatSnackBar,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.localeService.locale.subscribe(locale => {
      this.locale = locale
    })
    this.helptextsService
      .getHelptexts('air-quality-helptexts')
      .subscribe((res) => {
        this.helptexts = res;
      });
    this.sampleInformations.forEach((info) => {
      this.formValues[info.fieldName] = {
        valid: true,
        data: {},
      };
    });
    this.loadData();
  }

  private loadData() {
    this.dataService.fetchData().subscribe((data) => {
      this.data = data;
    });
  }

  onFormUpdates(fieldName: string, formUpdates: any) {
    this.activateSaveButton = true;
    this.formValues[fieldName] = {
      valid: formUpdates.isValid,
      data: formUpdates.data,
    };
  }

  hasValidData() {
    for (let v of Object.keys(this.formValues)) {
      if (!this.formValues[v].valid) {
        return false;
      }
    }
    return true;
  }

  updateData() {
    this.dataService.updateData(this.formValues).subscribe((data) => {
      this.data = undefined;
      this.loadData();
      this.snackbar.open('Values updated successfully', undefined, {duration: 2500})
    });
  }

  resetForms() {
    this.activateSaveButton = false;
    this.data = undefined;
    this.loadData();
  }
}
