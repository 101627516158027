import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { HelptextService } from 'src/app/shared/helptext.service';
import { Information } from 'src/app/shared/information';
import { WaterQualityService } from './water-quality.service';
import {Locale} from "../../locale";
import {LocaleService} from "../../locale.service";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-water-quality',
  templateUrl: './water-quality.component.html',
  styleUrls: ['./water-quality.component.css'],
})
export class WaterQualityComponent implements OnInit {

  locale = Locale.EN

  informations: any[] = [
    {
      fieldName: '',
      display_en: 'Water use',
      display_es: 'Utilisaciòn del agua',
      display_fr: 'Utilisation de l\' eau',
      display_pt: 'Utilisação del acua',
      type: 'title',
    },
    {
      fieldName: 'surfacewaterQuota',
      display_en: 'Surface water quota',
      display_es: 'Cuota de aguas superficiales',
      display_fr: 'Quota d\'eau de surface',
      display_pt: 'Quota de águas superficiais',
      type: 'number',
    },
    {
      fieldName: 'groundwaterQuota',
      display_en: 'Ground water quota',
      display_es: 'Cuota de aguas subterráneas',
      display_fr: 'Quota d\'eau souterraine',
      display_pt: 'Quota de águas subterrâneas',
      type: 'number',
    },
    {
      fieldName: 'effluentwaterQuota',
      display_en: 'Effluent water quota',
      display_es: 'Cuota de agua efluente',
      display_fr: 'Quota d\'eau usée',
      display_pt: 'Quota de água efluente',
      type: 'number',
    },
    {
      fieldName: 'surfacewaterAbstraction',
      display_en: 'Surface water abstraction',
      display_es: 'Extracción de aguas superficiales',
      display_fr: 'Prélèvement d\'eau de surface',
      display_pt: 'Captação de águas superficiais',
      type: 'number',
    },
    {
      fieldName: 'groundwaterAbstraction',
      display_en: 'Ground water abstraction',
      display_es: 'Extracción de aguas subterráneas',
      display_fr: 'Prélèvement d\'eau souterraine',
      display_pt: 'Captação de águas subterrâneas',
      type: 'number',
    },
    {
      fieldName: 'effluentwaterAbstraction',
      display_en: 'Effluent water abstraction',
      display_es: 'Extracción de aguas residuales',
      display_fr: 'Prélèvement d\'eau effluente',
      display_pt: 'Captação de água efluente',
      type: 'number',
    },
  ];
  sampleInformations: any[] = [
    {
      fieldName: 'groundwater_quality',
      display_en: 'Groundwater quality',
      display_es: 'Calidad de las aguas subterráneas',
      display_fr: 'Qualité des eaux souterraines',
      display_pt: 'Qualidade das águas subterrâneas',
      type: 'sample',
    },
    {
      fieldName: 'effluent_quality',
      display_en: 'Effluent water quality',
      display_es: 'Calidad del agua de los efluentes',
      display_fr: 'Qualité de l\'eau des effluents',
      display_pt: 'Qualidade da água efluente',
      type: 'sample',
    },
    {
      fieldName: 'surface_quality',
      display_en: 'Surface water quality',
      display_es: 'Calidad de las aguas superficiales',
      display_fr: 'Qualité des eaux de surface',
      display_pt: 'Qualidade das águas superficiais',
      type: 'sample',
    },
  ];

  activateSaveButton = false;

  private obsoleteFields = ['created_at', 'updated_at', 'data_year'];

  data: any | undefined;

  helptexts: any | undefined;

  formValues: {
    [key: string]: {
      valid: boolean;
      data: any;
    };
  } = {};

  constructor(
    private dataService: WaterQualityService,
    private helptextService: HelptextService,
    private snackbar: MatSnackBar,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.localeService.locale.subscribe(loc => this.locale = loc)
    this.helptextService
      .getHelptextsForCollection('water-qualities')
      .subscribe((res) => {
        this.helptexts = res[0].helptexts;
      });
    this.dataService.fetchData().subscribe((data) => {
      this.data = data;
    });
  }

  onFormUpdates(fieldName: string, formUpdates: any) {
    this.activateSaveButton = true;
    if (fieldName === 'formData') {
      this.formValues[fieldName] = {
        valid: formUpdates.isValid,
        data: {
          id: this.data.id,
          ...formUpdates.values,
        },
      };
    } else {
      this.formValues[fieldName] = {
        valid: formUpdates.isValid,
        data: formUpdates.data,
      };
    }
  }

  private loadData() {
    this.dataService.fetchData().subscribe((data) => {
      this.data = data;
    });
  }

  updateData() {
    this.dataService.updateData(this.formValues).subscribe((data) => {
      this.data = undefined;
      this.resetForms();
      this.snackbar.open('Values updated successfully', undefined, {
        duration: 2500,
      });
    });
  }

  resetForms() {
    this.activateSaveButton = false;
    this.data = undefined;
    this.loadData();
  }

  hasValidData() {
    for (let v of Object.keys(this.formValues)) {
      if (!this.formValues[v].valid) {
        return false;
      }
    }
    return true;
  }
}
