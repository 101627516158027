<app-metadata [graphName]="'land-and-forest'"></app-metadata>
<mat-card>
  <div *ngIf="data && informations">
    <app-form
      [informations]="informations"
      [data]="data"
      [showButton]="true"
      [helptexts]="helptexts[0]"
      (dataSubmitted)="onSubmit($event)"
    ></app-form>
  </div>
</mat-card>
