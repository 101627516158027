<div *ngIf="selectedProject">
  <mat-card class="shipping-card">
    <mat-card-header>
      <mat-card-title
        >{{ selectedProject.name }}: {{ selectedYear.year }} -
        <span *ngIf="selectedProjectJSON"><span style="background: lightgreen; padding: 10px;">Published</span> <button (click)="setReleased(false)" mat-raised-button style="margin: 10px;">Unpublish</button></span>
        <span *ngIf="!selectedProjectJSON"><span style="background: lightcoral; padding: 10px;">Not published</span> <button (click)="setReleased(true)" mat-raised-button style="margin: 10px;">Publish</button></span>
        </mat-card-title
      >
    </mat-card-header>
    <router-outlet></router-outlet>
  </mat-card>
</div>
