<mat-card>
  <h2 i18n="kobo_forms@@kobo_forms">Label Editor</h2>
  Select formula to create labels. You can use an empty formula if you want labels for the raw survey results.
  We assume that the same collection could have different labels for different surveys, so the labels are tied to the formulas.
  You can "update collections" in the KOBO import to pre-fill the possible labels.
  <mat-list *ngIf="formulas && formulas.length > 0">
    <mat-list-item *ngFor="let formula of formulas"
    >
      <button
        style="margin-right: 1vw"
        mat-raised-button
        (click)="editFormula(formula)"
        i18n="edit@@edit"
      >
        Select
      </button>
      {{ formula.formulaName && formula.formulaName }}:
      {{ formula.formula && formula.formula.fields.length }} <p i18n="fields@@fields">Fields</p>
    </mat-list-item
    >
  </mat-list>
  <mat-list *ngIf="!formulas || formulas.length === 0">
    <mat-list-item i18n="no_formulas@@no_formulas">No formulas detected... Create new formulas!</mat-list-item>
  </mat-list>
  <app-label-editor-detail
    (deleted)="reloadDataset()"
    *ngIf="selectedFormula"
    [formula]="selectedFormula"
  ></app-label-editor-detail>
</mat-card>
