import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LocaleService} from "../../locale.service";
import { Metadata } from '../metadata';

@Component({
  selector: 'app-metadata-dialog',
  templateUrl: './metadata-dialog.component.html',
  styleUrls: ['./metadata-dialog.component.css'],
})
export class MetadataDialogComponent implements OnInit {
  informations: any[] = [
    {
      fieldName: 'isNew',
      display_en: 'none',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'n/a',
    },
    {
      fieldName: 'isDefault',
      display_en: 'none',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'n/a',
    },
    {
      fieldName: 'diagram_name',
      display_en: 'none',
      display_es: 'none',
      display_fr: 'none',
      display_pt: 'none',
      type: 'n/a',
    },
    {
      fieldName: 'graph_name',
      display_en: 'Graph Name',
      display_es: 'Nombre del gráfico',
      display_fr: 'Nom du graphique',
      display_pt: 'Nome gráfico',
      type: 'string',
    },
    {
      fieldName: 'indicator_name',
      display_en: 'Indicator Name',
      display_es: 'Nombre del indicador',
      display_fr: 'Nom de l\'indicateur',
      display_pt: 'Nome do Indicador',
      type: 'string',
    },
    {
      fieldName: 'unit_of_measure',
      display: 'Unit of Measure',
      type: 'textarea',
      display_en: 'Unit of Measure',
      display_es: 'Unidad de medida',
      display_fr: 'Unité de mesure',
      display_pt: 'Unidade de medida',
    },
    {
      fieldName: 'short_definition',
      display: 'Short Definition',
      type: 'textarea',
      display_en: 'Short Definition',
      display_es: 'Definición breve',
      display_fr: 'Définition courte',
      display_pt: 'Definição breve',
    },
    {
      fieldName: 'long_definition',
      display: 'Long Definition',
      type: 'textarea',
      display_en: 'Long Definition',
      display_es: 'Definición larga',
      display_fr: 'Définition longue',
      display_pt: 'Definição Longa',
    },
    {
      fieldName: 'data_source',
      display: 'Data Source',
      type: 'textarea',
      display_en: 'Data Source',
      display_es: 'Fuente de datos',
      display_fr: 'Source des données',
      display_pt: 'Fonte de dados',
    },
    {
      fieldName: 'methodology',
      display: 'Methodology',
      type: 'textarea',
      display_en: 'Methodology',
      display_es: 'Metodología',
      display_fr: 'Méthodologie',
      display_pt: 'Metodologia',
    },
    {
      fieldName: 'limitations',
      display_en: 'limitations',
      display_es: 'limitaciones',
      display_fr: 'limitations',
      display_pt: 'limitações',
      type: 'textarea',
    },
  ];

  diagramName?: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Metadata,
              private dialogRef: MatDialogRef<MetadataDialogComponent>,
              private localeService: LocaleService) { }

  ngOnInit(): void {
    this.localeService.updateLocale()
  }

  onData($event: any) {
    console.log('Foobar');
    this.dialogRef.close({
      ...$event,
      diagram_name: this.data.diagram_name,
      id: this.data.id,
      project: this.data.project,
    });
  }

  close() {
    this.dialogRef.close();
  }
}
