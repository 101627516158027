import { Component, OnInit } from '@angular/core';
import { HelptextService } from 'src/app/shared/helptext.service';
import { Information } from 'src/app/shared/information';
import { NoiseCo2Service } from './noise-co2.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {Locale} from "../../locale";
import {LocaleService} from "../../locale.service";

@Component({
  selector: 'app-noise-co2',
  templateUrl: './noise-co2.component.html',
  styleUrls: ['./noise-co2.component.css'],
})
export class NoiseCo2Component implements OnInit {

  locale = Locale.EN

  informations: any[] = [
    {
      fieldName: 'co2Emissions',
      display_en: 'CO2 Emmissions',
      display_es: 'Emisiones de CO2',
      display_fr: 'Émissions de CO2',
      display_pt: 'Emissões de CO2',
      type: 'number',
    },
  ];

  sampleInformations: any[] = [
    {
      fieldName: 'workplace_noise',
      display_en: 'Noise at workplace',
      display_es: 'Ruido en el lugar de trabajo',
      display_fr: 'Bruit sur le lieu de travail',
      display_pt: 'Ruído no local de trabalho',
      type: 'sample',
    },
    {
      fieldName: 'outside_noise',
      display_en: 'Noise outside',
      display_es: 'Ruído no exterior',
      display_fr: 'Bruit à l\'extérieur',
      display_pt: 'Ruído no exterior',
      type: 'sample',
    },
  ];

  activateSaveButton = false;

  private obsoleteFields = ['created_at', 'updated_at', 'data_year'];

  data: any | undefined;

  helptexts: any | undefined;

  formValues: {
    [key: string]: {
      valid: boolean;
      data: any;
    };
  } = {};

  constructor(
    private dataService: NoiseCo2Service,
    private helptextService: HelptextService,
    private snackbar: MatSnackBar,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.localeService.locale.subscribe(loc => this.locale = loc)
    this.helptextService
      .getHelptextsForCollection('noise-co-2-s')
      .subscribe((res) => {
        this.helptexts = res[0].helptexts;
      });
    this.dataService.fetchData().subscribe((data) => {
      this.data = data;
    });
  }

  onFormUpdates(fieldName: string, formUpdates: any) {
    this.activateSaveButton = true;
    if (fieldName === 'formData') {
      this.formValues[fieldName] = {
        valid: formUpdates.isValid,
        data: {
          id: this.data.id,
          ...formUpdates.values,
        },
      };
    } else {
      this.formValues[fieldName] = {
        valid: formUpdates.isValid,
        data: formUpdates.data,
      };
    }
  }

  private loadData() {
    this.dataService.fetchData().subscribe((data) => {
      this.data = data;
    });
  }

  updateData() {
    this.dataService.updateData(this.formValues).subscribe((data) => {
      this.data = undefined;
      this.resetForms();
      this.snackbar.open('Values updated successfully', undefined, {duration: 2500})
    });
  }

  resetForms() {
    this.activateSaveButton = false;
    this.data = undefined;
    this.loadData();
  }

  hasValidData() {
    for (let v of Object.keys(this.formValues)) {
      if (!this.formValues[v].valid) {
        return false;
      }
    }
    return true;
  }
}
