import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogNewFieldComponent } from '../../form-editor/dialog-new-field/dialog-new-field.component';
import { KoboCombinationConditionDialogComponent } from '../../form-editor/kobo-combination/kobo-combination-condition-dialog/kobo-combination-condition-dialog.component';
import { KoboFormulaField } from '../../form-editor/kobo-formula-field';
import { LocaleService } from "../../../../locale.service";

@Component({
  selector: 'app-form-field-label',
  templateUrl: './form-field-label.component.html',
  styleUrls: ['./form-field-label.component.css'],
})
export class FormFieldLabelComponent implements OnInit {
  @Input() formField: KoboFormulaField | undefined;

  @Input() fieldindex: number | undefined;

  @Input() isFieldDeletable?: boolean | undefined;

  @Output() deleteFieldEvent = new EventEmitter();
  
  @Output() changeLabelEvent = new EventEmitter();

  constructor(public dialog: MatDialog,
              private localeService: LocaleService) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
  }

  private assertData() {
    if (!this.formField || this.fieldindex === undefined) {
      throw Error('Component not properly supplied with data');
    }
    return true;
  }

  openAddConditionDialog(): void {
    this.assertData();
    const dialogRef = this.dialog.open(
      KoboCombinationConditionDialogComponent,
      {
        width: '80vw',
        data: {},
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.formField!.conditions.push(result);
      }
    });
  }

  clearConditions() {
    this.assertData();
    this.formField!.conditions = [];
  }

  addNewField() {
    this.assertData();
    const dialogRef = this.dialog.open(DialogNewFieldComponent, {
      width: '80vw',
    });
    dialogRef.afterClosed().subscribe((f) => {
      if (f && f.length > 0) {
        if (!this.formField!.detailFields) this.formField!.detailFields = [];
        this.formField!.detailFields!.push({
          name: f,
          type: 'decimal',
          value: 1,
          conditions: [],
          divisor: []
        });
      }
    });
  }

  changeLabels(newLabel: Event, fieldName: any){
    console.log("changeLabels", newLabel, fieldName)
    if (newLabel && newLabel.target instanceof HTMLTextAreaElement) {
      const inputValue = newLabel.target.value;
      this.changeLabelEvent.emit({inputValue, fieldName});
    }
  }

  deleteField() {
    if (this.isFieldDeletable && this.isFieldDeletable === true) {
      this.deleteFieldEvent.emit(this.formField);
    }
  }

  deleteFreeField(freefieldIndex: number) {
    if (this.formField && this.formField.detailFields) {
      this.formField?.detailFields?.splice(freefieldIndex, 1);
    }
  }
}
