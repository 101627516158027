import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {DataService} from "../shared/data.service";
import {switchMap, tap} from "rxjs/operators";
import {of, pipe} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SocialInvestmentsService {
  url = environment.api + '/social-investments'
  private dyName = 'social_investments';

  constructor(private http: HttpClient,private dataService: DataService) { }

  fetch(): any {
    return this.dataService.selectedYear.pipe(
      switchMap((dy) => {
        return this.http.get(this.url, {params: {data_year: dy.id}})
      })
    );
  }

  post(formdata: any) {
    return this.http.post(`${this.url}/`, formdata)
  }

  update(param:any ) {
    return this.http.put(this.url +'/' + param.id, param )
  }

  createNewSocialInvestment() {
    return  this.dataService.selectedYear.pipe(
      switchMap(dy => this.http.post(this.url, {data_year: dy.id}))
    )
  }

  deleteSocialInvestment(id: number) {
    return this.http.delete(`${environment.api}/social-investments/${id}`)
  }
}
