<mat-card>
  <div *ngIf="data && informations && areOptionsLoaded()">
    <app-form
      [informations]="informations"
      [data]="data"
      [showButton]="true"
      [helptexts]="helptexts[0]"
      (dataSubmitted)="onData($event)"
      (formChanges)="processFormChanges($event)"
    ></app-form>
  </div>
</mat-card>
<mat-card>
  <button mat-raised-button color="primary" (click)="addOperatingCompany()" i18n="add operating company@@add_operating_company">
    Add Operating Company
  </button>
  <ng-container *ngFor="let datum of operating_company_data">
    <div *ngIf="datum && operating_company_informations">
      <app-form
        [informations]="operating_company_informations"
        [data]="datum"
        [showButton]="true"
        (dataSubmitted)="onDataOp($event)"
        [showDelete]="true"
        [helptexts]="opHelptexts[0]"
        (deleted)="deleteOpComp($event)"
        (formChanges)="processFormChanges($event)"
      ></app-form>
    </div>
  </ng-container>
</mat-card>
