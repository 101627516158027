<mat-card *ngIf="formula && form">
  <div style="display: flex">
    <div>
      <h3 i18n="form editor@@form_editor">Label Editor</h3>
      <div>Attached to formula: {{ form.collectionName.display }} </div>
      <div>Collection: {{ form.collectionName.collection }} </div>
    </div>
    <div style="margin-left: 10vw">
      <h3 i18n="assigned surveys@@assigned_surveys">Assigned surveys</h3>
      <div *ngIf="formula.kobo_surveys && formula.kobo_surveys.length > 0">
        <div *ngFor="let survey of formula.kobo_surveys">
          {{ survey.surveyName }}
        </div>
      </div>
      <div *ngIf="!formula.kobo_surveys || formula.kobo_surveys.length === 0" i18n="please assign a survey@@please_assign_survey">
        Please assign a survey in the formula editor
      </div>
    </div>
    
  </div>
  <p i18n="fields@@fields">{{ form.fields.length }} Fields</p>
  <div>
    <app-form-field-label
      [formField]="field"
      [fieldindex]="fieldIndex"
      *ngFor="let field of form.fields; index as fieldIndex"
      (changeLabelEvent)="changeLabel($event)"
    ></app-form-field-label>
  </div>
  <div style="display: flex">
    <div>
      <div>
        <button mat-button (click)="saveFormula()" i18n="save formula@@save_formula">Save labels</button>
      </div>
    </div>
    <label style="flex: 1 1 auto"></label>
    <div>
      <button mat-raised-button color="warn" (click)="resetLabels()" i18n="deleteformula@@delete_formula">
        Reset labels
      </button>
    </div>
  </div>
</mat-card>
