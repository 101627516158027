import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {BehaviorSubject, EMPTY, combineLatest, of} from 'rxjs';
import {LocaleService} from "../locale.service";
import {catchError, switchMap, take} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class HelptextService {
  /*
  helptexts MUST HAVE the same fieldName like the corresponding formField in order for the form component to display them correctly.
  for Example: (Local Procurements) formField: Agriculture (Regional) has the fieldName: agricultureRegional. the corresponding helptext
  under /local-procurement-helptexts
  also has the same field name
  */

  currentRelevantHelptexts: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private http: HttpClient,
    private localeService: LocaleService,
  ) {}

  getHelptextsForCollection(collectionName: string) {
    return this.http.get<any>(
      `${environment.api}/helptexts?collectionName=${collectionName}&_locale=${this.localeService.currentLocale}`
    );
  }

  getHelptexts(endpoint: string) {
    return combineLatest([
      this.http.get<any[]>(`${environment.api}/${endpoint}?_locale=${this.localeService.currentLocale}`).pipe(catchError(_ => EMPTY)),
      this.http.get<any[]>(`${environment.api}/${endpoint}?_locale=en`)
    ]).pipe(switchMap(([localised, english]) => {
        if (localised.length > 0) {
          return of(localised)
        } else {
          return of(english)
        }
      })
    )
  }
}
