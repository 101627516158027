import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../../shared/data.service';
import { switchMap, tap } from 'rxjs/operators';
import { of, pipe } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunityInteractionsService {
  url = environment.api + '/cp-community-interactions';
  private dyName = 'cp_community_interaction';

  constructor(private http: HttpClient, private dataService: DataService) {}

  fetch(): any {
    return this.dataService.selectedYear.pipe(
      switchMap((dy) => {
        return this.http.get(this.url, { params: { data_year: dy.id } });
      })
    );
  }

  update(param: any) {
    return this.http.put(this.url + '/' + param.id, param);
  }

  createNewSocialInvestment() {
    return this.dataService.selectedYear.pipe(
      switchMap((dy) => this.http.post(this.url, { data_year: dy.id }))
    );
  }

  updateGrievance($event: any) {
    return this.http.put(`${environment.api}/z-grievances/${$event.id}`, $event)
  }

  addGrievance(commInterId: number) {
    console.log(`${environment.api}/z-grievances`, {category: 'complaint', keyword: '', cp_community_interaction: commInterId});
    return this.http.post(`${environment.api}/z-grievances`, {category: 'complaint', keyword: '', cp_community_interaction: commInterId})
  }

  deleteGrievance(id: number) {
    return this.http.delete(`${environment.api}/z-grievances/${id}`)
  }
}
