import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KoboService {
  private importEndpoint = `${environment.api}/kobo-surveys`;

  private formulaEndpoint = `${environment.api}/kobo-formulas`;

  constructor(private http: HttpClient) {}

  loadProjects() {
    return this.http.get<any[]>(`${environment.api}/projects`);
  }

  loadAssignedSurveys() {
    return this.http.get<any[]>(`${this.importEndpoint}`);
  }

  dropSurvey(survey: any) {
    return this.http.delete<any>(`${this.importEndpoint}/${survey.id}`);
  }

  dropFormula(id: number) {
    return this.http.delete<any>(`${this.formulaEndpoint}/${id}`);
  }

  loadNewSurveys() {
    return this.http
      .get<any>(`${this.importEndpoint}/load`)
      .pipe(map((newSurveys) => newSurveys.results as any[]));
  }

  loadHHCollection() {
    console.log("loadHHCollection", `${this.formulaEndpoint}/house-hold-collections`);
    return this.http.get<any[]>(
      `${this.formulaEndpoint}/house-hold-collections`
    );
  }

  loadFormulas() {
    return this.http.get<any[]>(`${this.formulaEndpoint}`);
  }

  assignSurveys(formula: any, assignments: any[]) {}

  createNewFormula(newFormula: any) {
    return this.http.post<any>(`${this.formulaEndpoint}`, newFormula);
  }

  updateFormula(formula: any) {
    return this.http.put(`${this.formulaEndpoint}/${formula.id}`, formula);
  }

  saveNewSurvey(newSurvey: any) {
    if (!newSurvey.dataYear) throw new Error('Something went wrong');
    const dataSet = {
      surveyName: newSurvey.name,
      koboId: newSurvey.uid,
      data_year: newSurvey.dataYear.id,
      dataLink: newSurvey.data,
    };
    console.log("kobo.service.ts", "saveNewSurvey", dataSet);
    return this.http.post<any>(this.importEndpoint, dataSet);
  }

  executeFormulaOnDemoSet(demoSet: any, formula: any) {
    return this.http.post(`${this.formulaEndpoint}/demo`, {
      koboSurveyId: Number.parseInt(demoSet),
      formula,
    });
  }

  updateCollections(survey: any) {
    return this.http.post(`${this.formulaEndpoint}/update-collections`, survey);
  }
}
