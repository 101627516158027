<div
  *ngIf="formField && fieldindex !== undefined"
  style="
    display: flex;
    margin-bottom: 1vh;
    padding-top: 1vh;
    border-top: 1px solid black;
  "
>
  <div style="width: 20%; margin-right: 2vw">
    <p>
      {{ formField.name }}
    </p>
    <!-- we keep regular because old versions have regular and not decimal -->
    <div
      *ngIf="
        formField.type === undefined ||
        formField.type === 'decimal' ||
        formField.type === 'regular'
      "
    >
      <p>Type: decimal | value: {{ formField.value }}</p>
    </div>
    <div *ngIf="formField.type === 'json'">
      {{ formField.type }}
      <button mat-raised-button (click)="addNewField()">Add free field</button>
    </div>
  </div>
  <!-- we keep formfield.type due to backwards compatibility-->
  <div *ngIf="formField.name == 'perception'">
    <form *ngIf="formGroup" [formGroup]="formGroup">
      <mat-form-field style="width: 45%">
        <mat-select placeholder="Type" formControlName="type">
          <mat-option [value]="0">hazard</mat-option>
          <mat-option [value]="1">mine</mat-option>
        </mat-select>
      </mat-form-field>
    </form>    
  </div>
  <!---->

  <div
    *ngIf="
      formField.type === 'decimal' ||
      formField.type === 'regular' ||
      formField.type === undefined
    "
  >
    <div style="display: inline-block; margin-left: 25px">
      <div>Numerator:</div>
      <div *ngIf="formField.conditions && formField.conditions.length > 0">
        <div *ngFor="let condition of formField.conditions">
          <div style="margin-left: 1vw; margin-top: 0.5vh">
            <app-kobo-combination
              *ngIf="condition.type === 'combination'"
              [input]="condition"
            ></app-kobo-combination>
            <app-kobo-statement
              *ngIf="condition.type === 'statement'"
              [input]="condition"
            ></app-kobo-statement>
          </div>
        </div>
      </div>
      <div *ngIf="formField.conditions && formField.conditions.length > 0">
        <button mat-raised-button color="warn" (click)="clearConditions()" i18n="clear conditions@@clear_conditions">
          Clear conditions
        </button>
      </div>
      <div *ngIf="formField.conditions && formField.conditions.length === 0">
        <button mat-button (click)="openAddConditionDialog()" i18n="@@create_new_conditions">
          Create new conditions
        </button>
      </div>
    </div>
    <div style="display: inline-block; margin-left: 25px">
      <div>Divisor:</div>
      <div *ngIf="formField.divisor && formField.divisor.length > 0">
        <div *ngFor="let condition of formField.divisor">
          <div style="margin-left: 1vw; margin-top: 0.5vh">
            <app-kobo-combination
              *ngIf="condition.type === 'combination'"
              [input]="condition"
            ></app-kobo-combination>
            <app-kobo-statement
              *ngIf="condition.type === 'statement'"
              [input]="condition"
            ></app-kobo-statement>
          </div>
        </div>
      </div>
      <div *ngIf="formField.divisor  && formField.divisor.length > 0">
        <button mat-raised-button color="warn" (click)="clearConditions('divisor')" i18n="clear conditions@@clear_conditions">
          Clear custom rule
        </button>
      </div>
      <div *ngIf="formField.divisor && formField.divisor.length === 0">
        <div>Dividing by number of entries (default)</div>
        <button mat-button (click)="openAddConditionDialog('divisor')" i18n="@@create_new_conditions">
          Create custom rule
        </button>
      </div>
    </div>
  </div>


  <div *ngIf="isFieldDeletable" style="padding-left: 5vw">
    <button mat-raised-button color="warn" (click)="deleteField()" i18n="@@delete_field">
      Delete Field
    </button>
  </div>
</div>
<div style="margin-left: 2.5vw" *ngIf="formField && formField.detailFields">
  <app-form-field
    [formField]="field"
    [fieldindex]="fieldIndex"
    [isFieldDeletable]="true"
    (deleteFieldEvent)="deleteFreeField($event)"
    *ngFor="let field of formField.detailFields; index as fieldIndex"
  ></app-form-field>
</div>
