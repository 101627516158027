import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Locale} from "../../locale";
import {LocaleService} from "../../locale.service";
import {Information} from "../information";

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
})
export class FormComponent implements OnInit {

  locale = Locale.EN

  formGroup: FormGroup | undefined;

  hasChangedForm = false;

  @Input() informations: any[] = []

  @Input() data: any | undefined;

  @Input() helptexts: any;

  @Input() showButton?: boolean = true;
  @Input() showDelete?: boolean = false;
  @Input() showCancelButton?: boolean = false;

  @Output() dataSubmitted = new EventEmitter();
  @Output() cancelled = new EventEmitter();
  @Output() deleted = new EventEmitter();
  @Output() formChanges = new EventEmitter();

  constructor(private fb: FormBuilder, private localeService: LocaleService) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.locale = this.localeService.currentLocale
    this.localeService.locale.subscribe( loc => this.locale = loc)
    const group = this.buildGroup();
    if (group) {
      this.formGroup = this.fb.group(group);
      this.formGroup.valueChanges.subscribe((values) => {
        this.hasChangedForm = true;
        this.formChanges.emit({
          values: this.emptyStringToNull(values),
          isValid: this.formGroup?.valid,
        });
      });
    }
  }
  onUpdate() {}

  onSubmit(): void {
    this.dataSubmitted.emit(this.emptyStringToNull(this.formGroup?.value));
  }

  private buildGroup() {
    if (this.informations && this.data) {
      const f: { [key: string]: any } = {};
      for (let i of this.informations) {
        const group: any = [];

        if (i.type !== 'dropdown') group.push(this.data[i.fieldName] || '');

        if (['string', 'textarea'].includes(i.type)) {
          if (i.fieldName === 'district') {
            group.push(
              Validators.compose([Validators.minLength(1), Validators.required])
            );
          } else {
            group.push(Validators.minLength(1));
          }
        }
        if (i.fieldName === 'latitude') {
          group.push(
            Validators.compose([Validators.min(-91), Validators.max(90)])
          );
        }
        if (i.fieldName === 'longitude') {
          group.push(
            Validators.compose([Validators.min(-180), Validators.max(180)])
          );
        }
        if (i.type === 'email') {
          group.push(
            Validators.compose([Validators.required, Validators.email])
          );
        }
        if (i.type === 'password') {
          group.push(Validators.required);
        }
        if (i.type === 'dropdown') {
          console.log(this.data);
          if (this.data[i.fieldName] === null) group.push('');
          // use id of related object or value if it's a simple enum
          else group.push(this.data[i.fieldName].id || this.data[i.fieldName]);
        }
        f[i.fieldName] = group;
      }
      return f;
    }
    throw new Error('Missing Informations or Data');
  }

  emptyStringToNull(object: any) {
    let newObject = {};
    for (const [key, value] of Object.entries(object)) {
      if (value === '') {
        Object.assign(newObject, { [key]: null });
      } else {
        Object.assign(newObject, { [key]: value });
      }
    }
    return newObject;
  }

  cancel() {
    this.cancelled.emit();
  }

  // Only send the id of the entry
  deleteEvent($event: any) {

    let msg: string = 'Do you really want to delete this item?'
    this.locale == Locale.PT ? msg = 'Quer mesmo apagar este item?': ''
    this.locale == Locale.ES ? msg = '¿Realmente desea eliminar este elemento?': ''
    this.locale == Locale.FR ? msg = 'Voulez-vous vraiment supprimer cet élément ?': ''
    if (confirm(msg))
    this.deleted.emit({ ...$event.value, delete: true });
  }
}
