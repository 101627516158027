export class Helpers {
  static createEmptyDataset(informations: any[]) {
    const dataset: { [key: string]: number | string | null } = {};
    informations.forEach((information) => {
      if (['number'].includes(information.type) && information.fieldName !== '')
        dataset[information.fieldName] = null;
    });
    return dataset;
  }
}
