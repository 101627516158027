<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <div *ngIf="!user">
      <mat-toolbar i18n="visitor@@visitor">Visitor</mat-toolbar>
      <mat-nav-list>
        <a mat-list-item routerLink="login" i18n="login@@login">Login</a>
      </mat-nav-list>
    </div>
    <div *ngIf="user">
      <mat-toolbar
        ><a style="color: inherit; text-decoration: none" routerLink="welcome"
            i18n="menu@@menu"
          >Menu</a
        ></mat-toolbar
      >
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div mat-subheader i18n="settings@@settings">Settings</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item routerLink="settings" i18n="user@@user">User</a>
          <a mat-list-item (click)="logout()" i18n="logout@@logout">Logout</a>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="canAccessKobo()">
        <mat-expansion-panel-header>
          <div mat-subheader i18n="household survey data@@household_survey_data">Household survey data</div>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <a mat-list-item routerLink="kobo/import" i18n="kobo imports@@kobo_imports"> Kobo Surveys (imports)</a>
          <a mat-list-item routerLink="kobo/forms" i18n="kobo formulas@@kobo_formulas"> Kobo Formulas</a>
          <a mat-list-item routerLink="kobo/labeleditor" i18n="kobo formulas@@kobo_formulas"> Label Editor</a>
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="canAccessCompaniesAndProjects()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div mat-subheader *ngIf="companies && companies.length > 0" i18n="companies@@companies">
              Companies
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <button
          *ngIf="canCreateNewCompaniesAndProjects()"
          mat-raised-button
          color="primary"
          style="margin-left: 15px"
          (click)="createNewCompany()"
          i18n="new company@@new_company"
        >
          New Company
        </button>
        <mat-nav-list>
          <a
            *ngFor="let company of companies"
            mat-list-item
            routerLink="company/{{ company.id }}"
            >{{ company.name }}</a
          >
        </mat-nav-list>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="canAccessCompaniesAndProjects()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div mat-subheader i18n="projects@@projects">Projects</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <button
            *ngIf="canCreateNewCompaniesAndProjects()"
            mat-raised-button
            color="primary"
            style="margin-left: 15px"
            (click)="createNewProject()"
            i18n="new projects@@new_projects"
          >
            New Project
          </button>
          <div *ngFor="let project of projects">
            <a
              mat-list-item
              (click)="selectProject(project)"
              routerLink="project/{{ project.id }}"
              >{{ project.name }}</a
            >
            <div *ngIf="selectedProject && selectedProject.id === project.id">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div mat-subheader i18n="years@@years">Years</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="openNewYearDialog(project)"
                    style="margin-left: 15px"
                    i18n="add year@@add_year"
                  >
                    Add Year
                  </button>
                  <div *ngFor="let year of getSortedYears()">
                    <a
                      mat-list-item
                      (click)="selectYear(year)"
                      routerLink="project/{{ selectedProject.id }}/year/{{
                        year.year
                      }}/company"
                      >{{ year.year }}</a
                    >
                    <div *ngIf="selectedYear && selectedYear.id === year.id">
                      <mat-nav-list style="margin-left: 1em">
                        <a
                          mat-list-item
                          *ngFor="let category of categories"
                          routerLink="project/{{ selectedProject.id }}/year/{{
                            year.year
                          }}/{{ category.path }}"
                          >{{ category['display_' + locale] }}</a
                        >
                      </mat-nav-list>
                    </div>
                  </div>
                </mat-nav-list>
              </mat-expansion-panel>
            </div>
          </div>
        </mat-nav-list>
      </mat-expansion-panel>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        style="width: 4rem; margin-right: 2vw"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon style="font-size: 50px" aria-label="Side nav toggle icon"
          >menu</mat-icon
        >
      </button>
      <img
        style="margin-right: 2em"
        src="assets/img/RID_Logo_neg.svg"
        alt="RESOURCE IMPACT DASHBOARD"
      />
      <span i18n="admin panel caps lock@@admin_panel">ADMIN PANEL</span>
      <button mat-button [matMenuTriggerFor]="languageMenu" style="max-width: 100px"><mat-icon>language</mat-icon></button>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<mat-menu #languageMenu>
  <button mat-menu-item i18n="@@english" (click)="changeLocale(Locale.EN)">English</button>
  <button mat-menu-item i18n="@@french" (click)="changeLocale(Locale.FR)">French</button>
  <button mat-menu-item i18n="@@portuguese" (click)="changeLocale(Locale.PT)">Portuguese</button>
  <button mat-menu-item i18n="@@spanish" (click)="changeLocale(Locale.ES)">Spanish</button>
</mat-menu>
