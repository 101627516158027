<app-metadata
  [graphName]="'ambient-noise'"
  [displayString]="'Ambient Noise Metadata'"
></app-metadata>
<app-metadata
  [graphName]="'workplace-noise'"
  [displayString]="'Workplace Noise Metadata'"
></app-metadata>
<app-metadata
  [graphName]="'co2'"
  [displayString]="'CO2 Metadata'"
></app-metadata>
<mat-card>
  <mat-card-content *ngIf="data">
    <button
      mat-raised-button
      color="primary"
      [disabled]="!(activateSaveButton && hasValidData())"
      (click)="updateData()"
      i18n="save@@save"
    >
      Save
    </button>
    <button
      mat-raised-button
      style="margin-left: 5em"
      color="warn"
      (click)="resetForms()"
      i18n="cancel@@cancel"
    >
      Cancel
    </button>
    <div *ngIf="data && informations">
      <app-form
        [informations]="informations"
        [data]="data"
        [showButton]="false"
        [helptexts]="helptexts"
        (formChanges)="onFormUpdates('formData', $event)"
      ></app-form>
    </div>
    <div *ngFor="let info of sampleInformations">
      <div class="row">
        <div class="col">
          <app-forms-sample
            [title]="info['display_' + locale]"
            [sampleCarrier]="data"
            [sampleName]="info.fieldName"
            [helptexts]="helptexts"
            (formUpdates)="onFormUpdates(info.fieldName, $event)"
          ></app-forms-sample>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
