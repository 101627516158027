import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {LocaleService} from "../../locale.service";

@Component({
  selector: 'app-add-new-year',
  templateUrl: './add-new-year.component.html',
  styleUrls: ['./add-new-year.component.css'],
})
export class AddNewYearComponent implements OnInit {
  newYear: string | undefined;

  formGroup: FormGroup | undefined;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddNewYearComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number[],
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.formGroup = this.fb.group({
      year: '',
    });
    this.formGroup.valueChanges.subscribe((values: any) => {
      this.newYear = values.year;
    });
  }
  isNewYearValid() {
    if (!this.newYear) return false;
    const year = Number.parseInt(this.newYear);
    if (Number.isNaN(year)) return false;
    return !this.data.includes(year) && year >= 1990 && year <= 2050;
  }

  onCancel() {
    this.dialogRef.close();
  }
}
