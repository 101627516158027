import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FCUServiceConfig } from './types/fcu-service-config';

@Injectable({
  providedIn: 'root',
})
export class FetchCreateUpdateService {
  constructor(private http: HttpClient) {}

  create(
    fcuConfig: FCUServiceConfig,
    dy: any,
    employmentData: any
  ): Observable<any> {
    return this.http.post(`${environment.api}/${fcuConfig.endpoint}`, {
      ...employmentData,
      data_year: dy.id,
    });
  }

  fetch(fcuConfig: FCUServiceConfig, dy: any): Observable<any[]> {
    if (dy[fcuConfig.dyName] === null) return of([]);
    console.log(dy);
    return this.http.get<any[]>(
      `${environment.api}/${fcuConfig.endpoint}/${dy[fcuConfig.dyName]}`
    );
  }

  update(fcuConfig: FCUServiceConfig, param: any): Observable<any> {
    return this.http.put(
      `${environment.api}/${fcuConfig.endpoint}/${param.id}`,
      param
    );
  }
}
