import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { User } from '../shared/user';
import { AuthService } from '../shared/auth.service';
import { DataService } from '../shared/data.service';
import { MatDialog } from '@angular/material/dialog';
import { AddNewYearComponent } from './add-new-year/add-new-year.component';
import { UserRole } from '../shared/user-role.enum';
import {LocaleService} from "../locale.service";
import {Locale} from "../locale";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent {
  user: User | undefined;

  Locale = Locale

  locale = Locale.EN

  projectSelected = false;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  companies: any[] = [];

  projects: any[] = [];

  selectedProject: any | undefined;

  selectedYear: any | undefined;

  categories: any[] = [
    {
      path: 'company',
      display_en: 'Company',
      display_es: 'Empresa',
      display_fr: 'Entreprise',
      display_pt: 'Empresa',
    },
    {
      path: 'environment',
      display_en: 'Environment',
      display_es: 'Medio ambiente',
      display_fr: 'Environnement',
      display_pt: 'Ambiente',
    },
    {
      path: 'social-capital',
      display_en: 'Social Capital',
      display_es: 'Capital social',
      display_fr: 'Le capital social',
      display_pt: 'Capital Social',
    },
    {
      path: 'education-health',
      display_en: 'Education & Health',
      display_es: 'Educación y Salud',
      display_fr: 'Éducation et santé',
      display_pt: 'Educação & Saúde',
    },
  ];

  selectedUrlProjectId: number | undefined;

  selectedUrlYear: number | undefined;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private dialog: MatDialog,
    private localeService: LocaleService
  ) {
    this.localeService.locale.subscribe(loc => this.locale = loc)
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkUserStatus();
        this.setNavigationOptions(event.url);
      }
    });
    this.dataService.data.subscribe((data) => {
      this.companies = data.companies;
      this.projects = data.projects;
      if (this.selectedUrlProjectId) {
        const project = this.projects.find(
          (project) => project.id === this.selectedUrlProjectId
        );
        this.selectProject(project);
        if (this.selectedUrlYear) {
          this.selectYear(
            project.data_years.find(
              (dy: any) => dy.year === this.selectedUrlYear
            )
          );
        }
      }
    });
    this.dataService.selectedProject.subscribe((project) => {
      this.selectedProject = project;
    });
    this.dataService.selectedYear.subscribe((year) => {
      this.selectedYear = year;
    });
  }

  changeLocale(locale: Locale) {
    switch (locale) {
      case Locale.ES:
        window.location.assign('/spanish')
        break
      case Locale.FR:
        window.location.assign('/french')
        break
      case Locale.PT:
        window.location.assign('/portuguese')
        break
      default:
        window.location.assign('/english')
    }
    this.localeService.changeLocale(locale);
  }

  private checkUserStatus() {
    if (this.user) return;
    if (this.authService.isLoggedIn()) {
      this.authService.getUser().subscribe((user) => {
        this.user = user;
        this.dataService.loadDataForUser(user);
      });
    } else {
      this.user = undefined;
    }
  }

  private setNavigationOptions(url: string) {
    if (url.includes('project') && !this.selectedProject) {
      this.setProject(url);
      if (url.includes('year') && !this.selectedYear) {
        this.setYear(url);
      }
    }
    if (url.includes('project')) {
      this.projectSelected = true;
    } else {
      this.projectSelected = false;
    }
  }
  private setProject(url: string) {
    const parts = url.split('/');
    const projectId = parts[parts.indexOf('project') + 1];
    this.selectedUrlProjectId = Number.parseInt(projectId);
  }

  private setYear(url: string) {
    const parts = url.split('/');
    const year = parts[parts.indexOf('year') + 1];
    this.selectedUrlYear = Number.parseInt(year);
  }

  public logout() {
    let msg: string = 'do you really want to log out?'
    this.locale == Locale.PT ? msg = 'quer realmente terminar a sessão?': ''
    this.locale == Locale.ES ? msg = '¿realmente quieres cerrar la sesión?': ''
    this.locale == Locale.FR ? msg = 'voulez-vous vraiment vous déconnecter ?': ''
    if (confirm(msg)) {
      this.authService.logout();
      this.user = undefined;
      this.checkUserStatus();
      this.router.navigateByUrl('logout');
    }
  }

  public selectProject(project: any) {
    this.dataService.setProject(project);
  }

  public selectYear(year: any) {
    this.dataService.setYear(year);
  }

  public getSortedYears() {
    if (this.selectedProject) {
      return this.selectedProject.data_years.sort(
        (a: any, b: any) => a.year - b.year
      );
    }
  }

  createNewProject() {
    if (!this.user) return;
    this.dataService.createNewProject(this.user).subscribe((project) => {
      this.router.navigate(['project', project.id]);
    });
  }

  createNewCompany() {
    this.dataService.createNewCompany().subscribe((company) => {
      this.router.navigate(['company', company.id]);
      this.localeService.updateLocale()
    });
  }

  openNewYearDialog(project: any) {
    const dialogRef = this.dialog.open(AddNewYearComponent, {
      width: '20vw',
      data: project.data_years.map((dY: any) => dY.year),
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataService.createNewYear(project, result).subscribe((dataYear) => {
        this.router.navigate([
          'project',
          project.id,
          'year',
          result,
          'company',
        ]);
      });
    });
  }

  canAccessKobo() {
    if (!this.user) return false;
    return (
      this.user.role.name === UserRole.KOBO ||
      this.user.role.name === UserRole.ADMIN
    );
  }

  canAccessCompaniesAndProjects() {
    if (!this.user) return false;
    return (
      this.user.role.name === UserRole.COMPANY ||
      this.user.role.name === UserRole.NGO ||
      this.user.role.name === UserRole.ADMIN
    );
  }

  canCreateNewCompaniesAndProjects() {
    if (!this.user) return false;
    return (
      this.user.role.name === UserRole.NGO ||
      this.user.role.name === UserRole.ADMIN
    );
  }
}
