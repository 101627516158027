<h3 i18n="demo results@@demo_results">Demo Results for {{ results.collectionName.display }} on Survey</h3>
<div *ngIf="displayData">
  <div>Field name - number passing the condition</div>
  <div *ngFor="let dData of displayData">
    <div *ngIf="dData.type === 'decimal'">
      {{ dData.field }}: {{ dData.value }}
    </div>
    <div *ngIf="dData.type === 'list'">
      {{ dData.field }}
      <div style="padding-left: 1rem" *ngFor="let entry of dData.values">
        {{ entry.field }}: {{ entry.value }}
      </div>
    </div>
  </div>
</div>
