import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { KoboFieldService } from 'src/app/kobo/kobo-field.service';
import { KoboStatement } from '../../kobo-statement';
import {LocaleService} from "../../../../../locale.service";

@Component({
  selector: 'app-kobo-statement-dialog',
  templateUrl: './kobo-statement-dialog.component.html',
  styleUrls: ['./kobo-statement-dialog.component.css'],
})
export class KoboStatementDialogComponent implements OnInit {
  statement: KoboStatement | undefined;
  formGroup: FormGroup | undefined;

  surveyFieldOptions!: Observable<string[]>;

  sharedFields: any[] = [];

  allFields: any[] = [];

  constructor(
    private fb: FormBuilder,
    private fieldService: KoboFieldService,
    public dialogRef: MatDialogRef<KoboStatementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KoboStatement,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.statement = this.data;
    const key = Object.keys(this.statement.statement)[0];
    const value = Object.values(this.statement.statement)[0];
    this.formGroup = this.fb.group({
      type: this.statement.type,
      field: this.statement.surveyFieldName,
      relation: key,
      value: value,
    });
    this.formGroup.valueChanges.subscribe((values) => {
      this.statement = {
        type: values.type,
        surveyFieldName: values.field,
        statement: {
          [values.relation]: values.value,
        },
      };
    });
    this.surveyFieldOptions = this.formGroup.valueChanges.pipe(
      startWith(''),
      map((value: any) => this._filter(value))
    );
    this.fieldService.fieldObs.subscribe((fields) => {
      this.allFields = fields.allFields;
      this.sharedFields = fields.sharedFields;
    });
  }

  private _filter(value: any): string[] {
    if (value) {
      const filterValue = value.field.toLowerCase();
      return this.sharedFields.filter((field: string) =>
        field.toLowerCase().includes(filterValue)
      );
    }
    return this.sharedFields;
  }

  isStatementValid() {
    if (!this.statement) return false;
    if (!this.statement.surveyFieldName) return false;
    const key = Object.keys(this.statement.statement);
    if (!['eq', 'gt', 'gte', 'lt', 'lte', 'ne', 'in', 'notIn'].includes(key[0]))
      return false;
    const values = Object.values(this.statement.statement);
    if (values.length === 0 || values[0] === undefined) return false;
    return true;
  }

  onCancel() {
    this.dialogRef.close();
  }
}
