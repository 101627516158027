import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { KoboFieldService } from 'src/app/kobo/kobo-field.service';
import {LocaleService} from "../../../../locale.service";

@Component({
  selector: 'app-dialog-assign-repeat-field',
  templateUrl: './dialog-assign-repeat-field.component.html',
  styleUrls: ['./dialog-assign-repeat-field.component.css'],
})
export class DialogAssignRepeatFieldComponent implements OnInit {
  field: string | undefined;

  formGroup: FormGroup | undefined;

  surveyFieldOptions!: Observable<string[]>;

  sharedFields: any[] = [];

  allFields: any[] = [];

  constructor(
    private fb: FormBuilder,
    private fieldService: KoboFieldService,
    private dialogRef: MatDialogRef<DialogAssignRepeatFieldComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.field = this.data ? this.data : '';
    this.formGroup = this.fb.group({
      field: this.field,
    });
    this.formGroup.valueChanges.subscribe((values) => {
      this.field = values.field;
    });
    this.surveyFieldOptions = this.formGroup.valueChanges.pipe(
      startWith(''),
      map((value: any) => this._filter(value))
    );
    this.fieldService.fieldObs.subscribe((fields) => {
      this.allFields = fields.allFields;
      this.sharedFields = fields.sharedFields;
    });
  }

  private _filter(value: any): string[] {
    if (value) {
      const filterValue = value.field.toLowerCase();
      return this.sharedFields.filter((field: string) =>
        field.toLowerCase().includes(filterValue)
      );
    }
    return this.sharedFields;
  }

  onCancel() {
    this.dialogRef.close();
  }
}
