<div *ngIf="combination">
  <div>
    {{ combination.bool }}
    <button mat-button (click)="openChangeCombinationDialog()" i18n="change combination@@change_combination">
      Change combination
    </button>
    <button mat-raised-button (click)="openAddConditionDialog()" i18n="add condition@@add_condition">
      Add condition
    </button>
  </div>
  <div *ngFor="let condition of combination.conditions">
    <div style="margin-left: 2vw; margin-top: 0.5vh">
      <app-kobo-combination
        *ngIf="condition.type === 'combination'"
        [input]="condition"
      ></app-kobo-combination>
      <app-kobo-statement
        *ngIf="condition.type === 'statement'"
        [input]="condition"
      ></app-kobo-statement>
    </div>
  </div>
</div>
