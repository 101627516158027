
<div>
  <app-form
    [data]="data"
    [informations]="informations"
    (dataSubmitted)="onData($event)"
    [showCancelButton]="true"
    (cancelled)="close()"
  ></app-form>
</div>
