import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {switchMap} from "rxjs/operators";
import {DataService} from "../shared/data.service";
import * as qs from 'qs';
import {combineLatest, of} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class EducationalDataService {

  endPoint = environment.api + '/edu-regional-completion-rates'

  dyName = 'edu_regional_completion_rate'

  constructor(private http: HttpClient, private dataService: DataService) { }

  getEducationalData() {
    return this.dataService.selectedYear.pipe(
      switchMap((dy) => {
          let query = this.buildDataYearQuery(dy.year, this.dataService.getProjectId())
          return this.http.get<any[]>(`${this.endPoint}?${query}`);
      })
    );
  }


  updateData(data: any) {
    return this.http.put(this.endPoint + '/' + data.id, data);
  }


  createNewEduData() {
    return this.dataService.selectedYear.pipe(
      switchMap((dy) => {
        return this.http.post<any[]>(`${this.endPoint}`, {data_year: dy});
      })
    );
  }

  private buildDataYearQuery(years: any, projectId: any, category?: any) {
    let where = {
      'data_year.year_in': years,
      'data_year.project_eq': projectId,
    };
    if (category) {
      where = { ...where, ...category };
    }
    const q = {
      _where: [where],
    };
    const query = qs.stringify({
      _where: [where],
    });
    return query;
  }

  delete(id: number) {
    return this.http.delete(`${this.endPoint}/${id}`)
  }

  post(formData: any) {
    return this.http.post(`${this.endPoint}/`, formData)
  }
}
