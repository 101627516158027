import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concat, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DataService } from 'src/app/shared/data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LandForestService {
  private endPoint = 'land-forests';

  private dyName = 'land_forest';

  constructor(private http: HttpClient, private dataService: DataService) {}

  fetchData(): Observable<any> {
    return this.dataService.selectedYear.pipe(
      switchMap((dy) => {
        if (dy[this.dyName]) {
          return this.http.get(
            `${environment.api}/${this.endPoint}/${dy[this.dyName]}`
          );
        } else {
          return this.http.post(`${environment.api}/${this.endPoint}`, {
            data_year: dy.id,
          });
        }
      })
    );
  }

  fetchProject(projectId: number) {
    return this.http.get(this.buildIdUrl(this.endPoint, projectId));
  }

  updateData(data: { [key: string]: any }) {
    return this.http.put(this.buildIdUrl(this.endPoint, data.id), data);
  }

  fetchAll() {
    return this.http.get(`${environment.api}/${this.endPoint}`);
  }

  private buildIdUrl(endpoint: string, id: number) {
    return `${environment.api}/${endpoint}/${id}`;
  }
}
