import { Component, OnInit } from '@angular/core';
import {LocaleService} from "../locale.service";

@Component({
  selector: 'app-company-forms',
  templateUrl: './company-forms.component.html',
  styleUrls: ['./company-forms.component.css']
})
export class CompanyFormsComponent implements OnInit {

  constructor(
    private localeService: LocaleService) { }

  ngOnInit(): void {
    this.localeService.updateLocale()
  }

}
