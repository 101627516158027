import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KoboService } from '../kobo.service';
import { KoboFormula } from '../kobo-forms/form-editor/kobo-formula';
//import { NewFormulaDialogComponent } from './new-formula-dialog/new-formula-dialog.component';
import { LocaleService } from "../../locale.service";

@Component({
  selector: 'app-label-editor',
  templateUrl: './label-editor.component.html',
  styleUrls: ['./label-editor.component.css'],
})
export class LabelEditorComponent implements OnInit {
  formulas: any[] | undefined;
  collections: any[] | undefined;

  selectedFormula: any | undefined;
  constructor(private koboService: KoboService, private dialog: MatDialog,
              private localeService: LocaleService) {}

  ngOnInit(): void {
    this.localeService.updateLocale()
    this.loadData();
  }

  private loadData() {
    this.koboService.loadFormulas().subscribe((formulas) => {
      this.formulas = formulas;
    });
    this.koboService.loadHHCollection().subscribe((collections) => {
      this.collections = collections;
    });
  }

  openNewFormulaDialog() {
    /*const dialogRef = this.dialog.open(NewFormulaDialogComponent, {
      width: '35vw',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.createNewFormula(result);
    });*/
  }

  createNewFormula(result: any) {
    if (result) {
      const newKoboFormula = {
        collectionName: result.collection,
        formulaName: result.name,
        formulaDescription: result.description,
        formula: this.buildFormulafromTemplate(result),
      };
      console.log(newKoboFormula)
      this.koboService.createNewFormula(newKoboFormula).subscribe(() => {
        this.loadData();
      });
    }
  }

  editFormula(formula: any) {
    this.selectedFormula = undefined;
    setTimeout(() => {
      this.selectedFormula = formula;
    }, 100);
  }

  private buildFormulafromTemplate(result: any) {
    const formula: KoboFormula = {
      collectionName: {
        collection: result.collection,
        display: result.name,
      },
      options: {},
      fields: [],
    };
    for (const field of result.fields) {
      formula.fields.push({
        name: field.name,
        type: field.type.type,
        value: 1,
        conditions: [],
        divisor: []
      });
    }
    return formula;
  }

  reloadDataset() {
    this.selectedFormula = undefined;
    this.loadData();
  }
}
