import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {Locale} from "./locale";

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private _locale = new Subject<Locale>()

  locale: Observable<Locale> = this._locale.asObservable()

  currentLocale: Locale = Locale.EN;

  constructor() {
    this._locale.next(this.initLocale())
  }

  initLocale() {
    const path = window.location.pathname
    if (path.startsWith('/fr')){
      return Locale.FR
    }
    if (path.startsWith('/pt')){
      return Locale.PT
    }
    if (path.startsWith('/en')){
      return Locale.EN
    }
    if (path.startsWith('/es')){
      return Locale.ES
    }
    return Locale.EN
  }

  updateLocale() {
    const path = window.location.pathname
    if (path.startsWith('/fr')){
      this.currentLocale = Locale.FR
      this._locale.next(Locale.FR)
    }
    if (path.startsWith('/pt')){
      this.currentLocale = Locale.PT
      this._locale.next(Locale.PT)
    }
    if (path.startsWith('/en')){
      this.currentLocale = Locale.EN
      this._locale.next(Locale.EN)
    }
    if (path.startsWith('/es')){
      this.currentLocale = Locale.ES
      this._locale.next(Locale.ES)
    }
  }

  changeLocale(newLocale: Locale) {
    this._locale.next(newLocale);
    this.updateLocale()
  }
}
