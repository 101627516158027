import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  constructor(private http: HttpClient) {}

  getUsersByCompanyId(companyId: number) {
    return this.http.get<any[]>(
      `${environment.api}/users?company=${companyId}`
    );
  }

  updatePassword(currentUser: any, $event: any) {
    currentUser.password = $event.new_password;
    return this.http.put(
      `${environment.api}/users/${currentUser.id}`,
      currentUser
    );
  }

  updateEmail(currentUser: any, $event: any) {
    currentUser.email = $event.new_email;
    return this.http.put(
      `${environment.api}/users/${currentUser.id}`,
      currentUser
    );
  }

  addCompanyUser(user: any) {
    return this.http.post(`${environment.api}/users`, user).pipe(
      mergeMap((user) => {
        return this.http.patch(`${environment.api}/users/users/company`, user);
      })
    );
  }

  updateCompanyUser(user: any) {
    if (user.password === '' || user.password === null) delete user.password;
    return this.http.put(`${environment.api}/users/${user.id}`, user);
  }

  deleteUser(id: number) {
    return this.http.delete(`${environment.api}/users/${id}`);
  }
}
