<h2 i18n="add new condition@@add_new_condition">Add new condition</h2>
<div style="margin-bottom: 3vh">
  <mat-button-toggle-group (change)="toggleSelection($event)">
    <mat-button-toggle value="combination" i18n-value="@@combination" i18n="@@combination">Combination</mat-button-toggle>
    <mat-button-toggle value="statement" i18n-value="statement@@statement" i18n="statement@@statement">Statement</mat-button-toggle>
  </mat-button-toggle-group>
</div>
<div *ngIf="condition && condition.type">
  <div *ngIf="condition.type === 'combination'">
    <p i18n="@@combination">Combination</p>
    <mat-button-toggle-group (change)="selectCombination($event)">
      <mat-button-toggle value="and">And</mat-button-toggle>
      <mat-button-toggle value="or">Or</mat-button-toggle>
      <mat-button-toggle value="average">Average</mat-button-toggle>
      <mat-button-toggle value="head+children">Head+children</mat-button-toggle>
      <mat-button-toggle value="one-of-children">One-of-children</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div *ngIf="condition.type === 'statement'">
    <p  i18n="@@statement">Statement</p>
    <form *ngIf="formGroup" [formGroup]="formGroup">
      <mat-form-field style="width: 45%">
        <input
          matInput
          placeholder="Field"
          [matAutocomplete]="auto"
          formControlName="field"
          i18n-placeholder="@@field"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let field of surveyFieldOptions | async"
            [value]="field"
          >
            {{ field }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        appearance="fill"
        style="width: 15%; margin-left: 2%; margin-right: 2%"
      >
        <mat-label i18n="relation@@relation">Relation</mat-label>
        <mat-select formControlName="relation">
          <mat-option value="eq">=</mat-option>
          <mat-option value="gt">></mat-option>
          <mat-option value="gte">>=</mat-option>
          <mat-option value="lt"><</mat-option>
          <mat-option value="lte"><=</mat-option>
          <mat-option value="ne">≠</mat-option>
          <mat-option value="in" i18n="includes@@includes">includes</mat-option>
          <mat-option value="notIn" i18n="not includes@@not_includes">not includes</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field style="width: 35%">
        <input matInput placeholder="Field"  i18n-placeholder="field@@field" formControlName="value" />
      </mat-form-field>
    </form>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    [disabled]="!isConditionValid()"
    [mat-dialog-close]="condition"
    i18n="submit@@submit"
  >
    Submit
  </button>
  <button mat-button color="accent" (click)="onCancel()" i18n="cancel@@cancel">Cancel</button>
</div>
